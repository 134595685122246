import * as React from 'react';
import {observer} from 'mobx-react';
import {action, computed, observable, runInAction} from "mobx";
import {store} from "Models/Store";
import {Button, Colors, Display} from "Views/Components/Button/Button";
import {TextField} from "Views/Components/TextBox/TextBox";
import classNames from "classnames";
import UserEntity from "Models/Entities/UserEntity";
import { gql } from '@apollo/client';
import {Combobox} from "Views/Components/Combobox/Combobox";
import {role} from "Models/Enums";
import {confirmModal} from "Views/Components/Modal/ModalUtils";
import alert from "Util/ToastifyUtils";
import Modal from '../Modal/Modal';
import axios from "axios";

export interface IUserListProps {
}

export interface UserListObject {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	role: role;
	phone: string;
	emailConfirmed: boolean;
}

@observer
export default class UserList extends React.Component<IUserListProps> {

	@observable searchTerm: string = "";
	@observable selectedUser?: UserListObject;

	@observable private currentUser?: UserEntity;
	
	@computed
	private get emailRoot() {
		if(!this.currentUser) {
			return ""
		}
		return this.currentUser.email.slice(this.currentUser.email.indexOf('@'));
	}
	
	@observable public userList: UserListObject[] = [];

	@observable
	private requestState: 'loading' | 'error' | 'done' = 'loading';
	
	private roleDropdownOptions = [
		{ display: "Organisation Admin", value: "ADMIN" },
		{ display: "Humble User", value: "MEMBER" }
	];

	@observable
	private modalState = {
		visible: false,
		emailName: "",
		role: "MEMBER",
	};

	@action private closeModal = () => {
		this.modalState = {
			visible: false,
			emailName: "",
			role: "MEMBER",
		};
	};

	public componentDidMount(): void {
		UserList.getCurrentUser().then(currentUser => {
			if(!currentUser || !currentUser.organisationId) {
				this.changeRequestState('error');
				return;
			}
			
			UserList.getUserList(currentUser.organisationId).then((userList: UserListObject[] | undefined) => {
				this.changeRequestState('done', currentUser, userList);
			}).catch(e => {
				this.changeRequestState('error');
			});
		}).catch(e => {
			this.changeRequestState('error');
		});
	}

	@action
	private changeRequestState = (state: 'loading' | 'error' | 'done', currentUser?: UserEntity, userList?: UserListObject[]) => {
		this.requestState = state;
		
		if(currentUser) {
			this.currentUser = currentUser;
		}
		if(userList) {
			this.userList = userList;
		}
	};

	public render() {
		return (
			<>
				<div className={classNames("user-list", this.selectedUser !== undefined ? 'details-visible' : null)}>
					<div className="user-selection-section">
						<div className="user-list-header">
							<h1 className="user-list-title">Team list</h1>
							<div className="user-list-action-bar">
								<TextField
									inputProps={{
										className: "search-user-list"
									}}
									model={this}
									modelProperty="searchTerm"
									placeholder="Search" />
								{this.currentUser && this.currentUser.role === "ADMIN"
									? <Button onClick={() => runInAction(() => this.modalState.visible = true)} className="new-user-btn" display={Display.Solid} colors={Colors.Primary} icon={{ icon: "plus", iconPos: 'icon-left' }}>Invite team member</Button>
									: null}
							</div>
						</div>
						<div className="user-list-table">
							<div className="user-list-item headers">
								<div className="list-item-wrap">
									<div className="user">User</div>
									<div className="user-type">User type</div>
									<div className="status">Status</div>
								</div>
							</div>
							{this.userList
								.filter(user => {
									return !this.searchTerm
										|| (user.firstName + " " + user.lastName).toLowerCase().includes(this.searchTerm.toLowerCase())
										|| user.email.toLowerCase().includes(this.searchTerm.toLowerCase());
								})
								.sort((one, two) => {
									return ("" + one.firstName + one.lastName).localeCompare("" + two.firstName + two.lastName);
								})
								.map(user => {
									return (
										<div className={classNames("user-list-item", {'selected': user === this.selectedUser})} onClick={() => this.toggleSelectUser(user)} key={user.id}>
											<div className="list-item-wrap">
												<div className="user">
													<span className="user-name">{(user.firstName || "") + " " + (user.lastName || "")}</span>
													<span className="user-email">{user.email}</span>
												</div>
												<div className="user-type">{user.role === "ADMIN" ? "Admin" : "Humble User"}</div>
												<div className="status">{user.emailConfirmed ? "Activated" : "-"}</div>
											</div>
										</div>
									)
								})}
						</div>
					</div>
					<div className="user-details-section">
						{this.selectedUser === undefined
							? <div className="no-selected-user-note">
								<div className="no-selected-user-icon"/>
								<p>Select a user to view</p>
							</div>
							: <>
								<div className="top-separator"/>
								<Button onClick={() => runInAction(() => this.selectedUser = undefined)} className="hide-details-mobile-btn" display={Display.Text} colors={Colors.Secondary} icon={{icon: 'arrow-left', iconPos: 'icon-left'}}>View team list</Button>
								<p className="selected-user-name">{(this.selectedUser.firstName || "") + " " + (this.selectedUser.lastName || "")}</p>
								<p className="selected-user-email icon-mail icon-left">{this.selectedUser.email}</p>
								<p className="selected-user-phone icon-phone icon-left">{this.selectedUser.phone}</p>

								<Button disabled={this.selectedUser.email === store.email} onClick={() => this.removeUserFromOrganisation(this.selectedUser)} className="remove-user-btn" display={Display.Solid} colors={Colors.Secondary}>Remove from organisation</Button>

								<Combobox
									className="user-role"
									model={this.selectedUser}
									modelProperty="role"
									label="User type"
									isClearable={false}
									options={this.roleDropdownOptions}
									onAfterChange={this.updateSelectedUser}
									isDisabled={this.selectedUser.email === store.email}/>
								{this.selectedUser.role === "ADMIN"
									? <p className="role-desc">Can invite other users to your organisation and access the Aptus design tool</p>
									: <p className="role-desc">Can access the Aptus design tool</p>}
								<div className="bottom-separator"/>
							</>}
					</div>
				</div>
				<Modal className="user-list-modal" isOpen={this.modalState.visible} label="Invite Team Member" onRequestClose={this.closeModal}>
					<h4 key="header" className="modal__header">Invite Team Member</h4>
					<p>You can only invite team members to your organisation that share the same email domain name.</p>
					<div className="email-wrap">
						<TextField
							model={this.modalState}
							modelProperty="emailName"
							label="Email address" />
						<span className="email-root">{this.emailRoot}</span>
					</div>

					<Combobox
						className="user-role"
						model={this.modalState}
						modelProperty="role"
						label="User type"
						isClearable={false}
						options={this.roleDropdownOptions} />
					{this.modalState.role === "ADMIN"
						? <p className="role-desc">Can invite other users to your organisation and access the Aptus design tool</p>
						: <p className="role-desc">Can access the Aptus design tool</p>}
						
					<div key="actions" className="modal__actions">
						<Button onClick={this.closeModal} display={Display.Solid} colors={Colors.Secondary}>Cancel</Button>
						<Button onClick={this.inviteUser} display={Display.Solid} colors={Colors.Primary}>Send Invite</Button>
					</div>
				</Modal>
			</>
		)
	};
	
	@action private inviteUser = async () => {
		if(this.currentUser) {
			const result = await axios.post("/api/entity/InvitedUserEntity/invite", {
				Email: this.modalState.emailName + this.emailRoot,
				OrganisationId: this.currentUser.organisationId,
				Role: this.modalState.role,
			});
			alert(result.data.message, result.data.success === true ? "success" : "error");
		}
		
		this.closeModal();
	};
	
	@action private removeUserFromOrganisation = (user?: UserListObject) => {
		if(user !== undefined) {
			confirmModal('Please confirm', "Are you sure you want to delete this user? They will lose their account, and will need to register an account if you add them again later.").then(()=>{
				const userEntity = new UserEntity(user);
				userEntity.delete().then(() => {
					runInAction(() => this.userList.splice(this.userList.indexOf(user), 1));
					alert("User deleted", "success");
				}).catch(() => {
					alert("There was a problem deleting this user. Please try again.", "error");
				});
			});
		}
	};

	@action private updateSelectedUser = () => {
		if(this.selectedUser !== undefined) {
			const userEntity = new UserEntity(this.selectedUser);
			userEntity.save();
		}
	};

	@action private toggleSelectUser = (user: UserListObject) => {
		if(this.selectedUser === user) {
			this.selectedUser = undefined;
		} else {
			this.selectedUser = user;
		}
	};

	private static async getCurrentUser() {
		const userList: UserEntity[] = await UserEntity.fetch<UserEntity>({args: [[{path: 'id', comparison: "equal", value: store.userId}]]});
		
		if(userList.length >= 1) {
			return userList[0];
		} else {
			console.error("Unable to find current user");
			return undefined;
		}
	}

	private static async getUserList(organisationId: string) {
		const results = await store.apolloClient
			.query({
				query: gql`
					{
						userEntitys(where: {path: "organisationId", comparison: equal, value: "${organisationId}"}) {
							id
							firstName
							lastName
							email
							role
							phone
							emailConfirmed
							organisationId
						}
					}`,
				fetchPolicy: 'network-only',
			});
		return results.data['userEntitys'];
	}
}
