import React from 'react';
import { contextMenu } from 'react-contexify';
import { ContextMenu } from "Views/Components/ContextMenu/ContextMenu";

const menuId = 'column-context-menu';

interface ColumnContextMenuProps {
    onMoveRight: () => any;
    onMoveLeft: () => any; 
    disabled: boolean;
}

export const showColumnContextMenu = (e: React.MouseEvent) => {
    contextMenu.show({ id: menuId, event: e });
}

export const ColumnContextMenu = (props: ColumnContextMenuProps) => {
    return (
        <ContextMenu
            menuId={menuId}
            actions={[
                {
                    label: "Move Column Right",
                    onClick: props.onMoveRight,
                    disabled: props.disabled,
                },
                {
                    label: "Move Column Left",
                    onClick: props.onMoveLeft,
                    disabled: props.disabled,
                }
            ]}
        />
    )
}