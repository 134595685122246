import * as React from 'react';
import { observer } from 'mobx-react';
import { ProjectTableState } from "../../Tiles/ProjectTile"
import {observable, action, computed, runInAction} from 'mobx';
import {ProjectEntity, ProjectRevisionEntity} from 'Models/Entities';
import moment from "moment";
import {DataExportUtils} from "Util/DataExportUtils";
import {AxialLoadsData, ElementStructureUtils} from "Util/ElementStructureUtils";
import classNames from "classnames";
import alert from "../../../Util/ToastifyUtils";
import Modal from "Views/Components/Modal/Modal";
import {Button, Colors, Display} from "Views/Components/Button/Button";
import {FormEvent} from "react";
import {DataImportUtils} from "Util/DataImportUtils";
import {TextArea} from "Views/Components/TextArea/TextArea";
import { LoadView, ProgressBarState } from 'Views/Components/LoadView/LoadView';
import { Link } from 'react-router-dom';
import LottieIcon from "Views/Components/LottieIcon/LottieIcon";
import LiveAsset from "Assets/96223-live.json"

export interface SummaryProps {
	state: ProjectTableState;
	project?: ProjectEntity;
	projectCanBeEdited?: boolean;
	currentlyEditedBy?: string;
	revision?: ProjectRevisionEntity;
	lastChangedName?: string;
	importAxialLoads?: (axialLoads: AxialLoadsData[]) => void;
	afterChange?: () => void;
	revisionList?: {
		name: string;
		created: string | undefined;
	}[];
}

interface SummaryInformation {
	[key: string]: {
		name: string;
	};
}

@observer
export default class SummaryView extends React.Component<SummaryProps> {
	@observable private initialOpen: boolean = true;

	@observable
	private modalState: {
		visible: "none"|"axialLoads"|"notes"|"export-revision"|"axialLoadsErrors",
		fileName: string,
		fileContents: string,
		fileAxialLoads: AxialLoadsData[],
		cellChangedCount: number,
		notes: string;
	} = {
		visible: "none",
		fileName: "",
		fileContents: "",
		fileAxialLoads: [],
		cellChangedCount: 0,
		notes: "",
	};

	// Put them here
	@observable
	private loadViewText: string;
	
	@observable
	private progressBar = ProgressBarState.Hidden;
	
	private loadView: LoadView | null = null;
	
	@computed
	get currentPage() {
		return this.props.state;
	}
	
	@computed
	get projectName() {
		if (this.props.project) {
			return this.props.project.name;
		} else if (this.props.revision) {
			return this.props.revision.project.name;
		} else {
			return "";
		}
	}
	@computed
	get projectJobNumber() {
		if (this.props.project) {
			return this.props.project.jobNumber;
		} else if (this.props.revision) {
			return this.props.revision.project.jobNumber;
		} else {
			return "";
		}
	}
	
	@computed 
	get projectID() {
		if (this.props.project) {
			return this.props.project.id;
		} else if (this.props.revision) {
			return this.props.revision.projectId;
		} else {
			return null;
		}
	}
	
	@computed
	public get elementStructureTotals() {
		const { project, revision } = this.props;
		if (project && project.parsedElementStructure) {
			return ElementStructureUtils.elementStructureTotals(project.parsedElementStructure);
		} else if (revision && revision.parsedElementStructure) {
			return ElementStructureUtils.elementStructureTotals(revision.parsedElementStructure);
		} else {
			return null;
		}
	};

	@computed
	public get editedSinceLastBuild() {
		const { project, revision } = this.props;
		if (project && project.parsedElementStructure && project.parsedElementStructure.info) {
			return project.parsedElementStructure.info.editedSinceLastBuild;
		} else if (revision && revision.parsedElementStructure && revision.parsedElementStructure.info) {
			return revision.parsedElementStructure.info.editedSinceLastBuild;
		} else {
			return null;
		}
	};

	@computed
	public get projectChangedDate(): string {
		const { project, revision } = this.props;
		if (project) {			
			return moment.utc(project.modified).local().format('HH:mm DD/MM/YYYY');

		} else if (revision) {
			return moment.utc(revision.created).local().format('HH:mm DD/MM/YYYY');
		}
		return "";
	};

	@computed
	private get priceVersion(): string {
		const { project, revision } = this.props;
		if (project) {			
			return project.priceVersion?.version?.toString();

		} else if (revision && revision.priceVersionId) {
			return revision.priceVersion?.version?.toString();
		}
		return "";
	};

	public render() {
		const { project, revision, lastChangedName, importAxialLoads, projectCanBeEdited, currentlyEditedBy } = this.props;
		if(this.props.project?.notes && this.initialOpen) {
			this.openNotesModal();
			runInAction(()=> this.initialOpen = false)
		}
		return (
			<>
				<div className="summary-wrap">
					<div className="back-btn-wrap">
						{project || revision ?
							<div>
								<Link className="back-btn setup-return icon-arrow-left icon-left btn" 
								   to={this.props.projectCanBeEdited ? `/projectwizard/${this.projectID}` : "/"}>
									Back to {this.props.projectCanBeEdited ? "project setup": "dashboard"}
								</Link>
							</div> 
							: null}
					</div>
					<div className="share-list">
						{project ? project.getShareList().map(orgId =>
							<div className="organisation-image" key={orgId}>
								<img src={`/api/entity/OrganisationEntity/logo/${orgId}`} alt="organisation logo" />
							</div>
						) : null}
						{revision ? revision.getShareList().map(orgId =>
							<div className="organisation-image" key={orgId}>
								<img src={`/api/entity/OrganisationEntity/logo/${orgId}`} alt="organisation logo" />
							</div>
						) : null}
					</div>
					<h2 className="summary-job-number">{this.projectJobNumber}</h2>
					<h1 className="summary-title">{this.projectName}</h1>
					{ projectCanBeEdited === false
						? <p className="project-edit-status"><LottieIcon autoplay={true} loop={true} animationData={LiveAsset} width={45} height={45}/>{currentlyEditedBy} is editing this project</p>
						: null
					}
					{projectCanBeEdited && lastChangedName && project ?
						<p className="project-edit-status">Last edited by {lastChangedName} <br/> {this.projectChangedDate}</p>
					: null}
					{lastChangedName && revision ? 
						<p className="project-edit-status">Revision: {revision.name} <br/> Created by {lastChangedName} <br/> {this.projectChangedDate}</p>
					: null}
					{ project?.priceVersionId || revision?.priceVersionId ?
						<p className="project-edit-status">Price List Version: {this.priceVersion}</p>
					: null}
					

					<Button 
						className="project-notes-btn"
						onClick={this.openNotesModal} 
						display={Display.Solid} 
						colors={Colors.Secondary} 
						icon={{ icon: "note", iconPos: 'icon-left' }}>
							Project notes
					</Button>
					
					<div className="summary-btn-wrap">
						{project || revision ? 
						<div>
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							<a className="summary-btn csv-download icon-download icon-left btn" onClick={() => this.csvDownload(this.currentPage)}>Download page as CSV</a>
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							{project ? <a className="summary-btn excel-download icon-download icon-left btn" onClick={() => runInAction(() => this.modalState.visible = "export-revision")}>Download Excel & Save Revision</a> : null}
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							{revision ? <a className="summary-btn excel-download icon-download icon-left btn" onClick={() => this.downloadExcel()}>Download project as Excel</a> : null}
							{project && importAxialLoads 
								// eslint-disable-next-line jsx-a11y/anchor-is-valid
								? <a className="summary-btn axial-import icon-import icon-left btn" onClick={() => runInAction(this.openAxialLoadsModal)}>Import axial loads</a>
								: null}
							
						</div>
						: null}
					</div>
					{this.progressBar !== ProgressBarState.Hidden
							? <LoadView text={this.loadViewText} estimate={ElementStructureUtils.getBuildTimeEstimate(this.props.project)} completed={this.progressBar} ref={ref => this.loadView = ref} />
							: null}
					
					{this.elementStructureTotals ?
						<>
							<div className="price-wrap">
								<h1 className="quote-title">Summary</h1>
								{this.editedSinceLastBuild
									? <h5 className="rebuild-required-note icon-information icon-left">Rebuild required</h5>
									: null}
								<div className="quote-quantities">
									<div className={classNames("quote-wrap", "starter-bars", this.editedSinceLastBuild ? "edited" : null)}>
										<span className="quote-text">{this.elementStructureTotals.quantities.starters}/Starter bars</span>
										<span className="quote-price">{this.elementStructureTotals.prices.starters.toLocaleString("en-AU", {style: "currency", currency: "AUD"}) + " AUD"}</span>
									</div>
									<div className={classNames("quote-wrap", "bars", this.editedSinceLastBuild ? "edited" : null)}>
										<span className="quote-text">{this.elementStructureTotals.quantities.bars}/Bars</span>
										<span className="quote-price">{this.elementStructureTotals.prices.bars.toLocaleString("en-AU", {style: "currency", currency: "AUD"}) + " AUD"}</span>
									</div>
									<div className={classNames("quote-wrap", "couplers", this.editedSinceLastBuild ? "edited" : null)}>
										<span className="quote-text">{this.elementStructureTotals.quantities.couplers}/Couplers</span>
										<span className="quote-price">{this.elementStructureTotals.prices.couplers.toLocaleString("en-AU", {style: "currency", currency: "AUD"}) + " AUD"}</span>
									</div>
									{this.elementStructureTotals.quantities.extensions > 0
										? <div className={classNames("quote-wrap", "couplers", this.editedSinceLastBuild ? "edited" : null)}>
											<span className="quote-text">{this.elementStructureTotals.quantities.extensions}/Extensions</span>
											<span className="quote-price">{this.elementStructureTotals.prices.extensions.toLocaleString("en-AU", {style: "currency", currency: "AUD"}) + " AUD"}</span>
										</div>
										: null}
									<div className={classNames("quote-wrap", "anchors", this.editedSinceLastBuild ? "edited" : null)}>
										<span className="quote-text">{this.elementStructureTotals.quantities.anchors}/Anchors</span>
										<span className="quote-price">{this.elementStructureTotals.prices.anchors.toLocaleString("en-AU", {style: "currency", currency: "AUD"}) + " AUD"}</span>
									</div>
									<div className={classNames("quote-wrap", "shutters", this.editedSinceLastBuild ? "edited" : null)}>
										<span className="quote-text">{this.elementStructureTotals.shutters.quantity}/Shutters</span>
										<span className="quote-price">{this.elementStructureTotals.shutters.price.toLocaleString("en-AU", {style: "currency", currency: "AUD"}) + " AUD"}</span>
									</div>
									{this.elementStructureTotals.siteTemplates.price > 0
										? <div className={classNames("quote-wrap", "siteTemplates", this.editedSinceLastBuild ? "edited" : null)}>
											<span className="quote-text">{this.elementStructureTotals.siteTemplates.quantity}/Site Templates</span>
											<span className="quote-price">{this.elementStructureTotals.siteTemplates.price.toLocaleString("en-AU", {style: "currency", currency: "AUD"}) + " AUD"}</span>
										</div>
										: null}
									{this.elementStructureTotals.extras.price > 0
										? <div className={classNames("quote-wrap", "extras")}>
											<span className="quote-text">{this.elementStructureTotals.extras.quantity}/Extra parts</span>
											<span className="quote-price">{this.elementStructureTotals.extras.price.toLocaleString("en-AU", {style: "currency", currency: "AUD"}) + " AUD"}</span>
										</div>
										: null}
								</div>
							</div>
							<div className={classNames("quote-wrap", "total", this.editedSinceLastBuild ? "edited" : null)}>
								<span className="quote-text total">Total</span>
								<span className="quote-price total">{(this.elementStructureTotals.prices.total + this.elementStructureTotals.shutters.price + this.elementStructureTotals.siteTemplates.price + this.elementStructureTotals.extras.price).toLocaleString("en-AU", {style: "currency", currency: "AUD"}) + " AUD"}</span>
							</div>
						</>
						: null}
				</div>

				<Modal isOpen={this.modalState.visible === "axialLoads"} label="Import axial loads and moments" onRequestClose={this.closeModal}>
					<h4 key="header" className="modal__header">Import axial loads and moments</h4>
					<h5>Upload a CSV of axial loads & moments to apply to the entire project.</h5>
					
					<input
						type="file"
						id="axial-loads-input"
						className="axial-loads-input"
						name="axial-loads-input"
						onChange={this.changeAxialLoadInput}
						accept=".csv" />
					<label htmlFor="axial-loads-input" className="axial-loads-label">
						<span className="axial-loads-label-btn btn btn--solid btn--primary">Browse...</span>
						{this.modalState.fileName ? this.modalState.fileName : "No file selected."}
						{this.modalState.cellChangedCount > 0
							?	<span className="cells-changed-note">&nbsp;({this.modalState.cellChangedCount} element{this.modalState.cellChangedCount > 1 ? "s" : ""} to change)</span>
							: null}
					</label>
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<a className="axial-load-modal-import icon-download icon-left btn" onClick={() => this.csvDownload("axialloads")}>Download axial loads template</a>
					
					<div  key="actions" className="modal__actions">
						<Button onClick={this.closeModal} display={Display.Solid} colors={Colors.Secondary}>Cancel</Button>
						<Button onClick={this.submitAxialLoads} display={Display.Solid} colors={Colors.Primary} 
								disabled={!this.modalState.fileName || !this.props.projectCanBeEdited}>Submit</Button>
					</div>
				</Modal>

				<Modal isOpen={this.modalState.visible === "axialLoadsErrors"} label="Upload unsuccessful" onRequestClose={this.closeModal}>
					<h4 key="header" className="modal__header">Upload unsuccessful</h4>
					<h5>There are errors in your spreadsheet. Please re-upload template with axial loading info on all elements.</h5>
					
					<div  key="actions" className="modal__actions">
						<Button onClick={this.closeModal} display={Display.Solid} colors={Colors.Secondary}>Cancel</Button>
					</div>
				</Modal>

				<Modal isOpen={this.modalState.visible === "notes"} label="Project Notes" onRequestClose={this.closeModal}>
					<h4 key="header" className="modal__header">Project Notes</h4>

					<TextArea
						model={this.modalState}
						modelProperty="notes"
						isDisabled={!project} />

					<div  key="actions" className="modal__actions">
						<Button onClick={this.closeModal} display={Display.Solid} colors={Colors.Secondary}>{project ? "Cancel" : "Close"}</Button>
						{project
							? <Button onClick={this.submitNote} display={Display.Solid} colors={Colors.Primary} disabled={!this.props.projectCanBeEdited}>Save</Button>
							: null}
					</div>
				</Modal>

				<Modal isOpen={this.modalState.visible === "export-revision"} label="An example modal!" onRequestClose={this.closeModal}>
					<h4 key="header" className="modal__header">Download Project as Excel & Save as New Revision</h4><br/>
					{this.props.revisionList && this.props.revisionList.length !== 0 ?
						<div className="revision-list-table">
							<div className="revision-list-item">
								<div className="list-item-wrap header">
									<div className="previous-revisions">Previous Revisions</div>
									<div className="created">Created</div>
								</div>
							</div>
							{this.props.revisionList
								.map(revision => {
									return (
										<div className={classNames("revision-list-item")} key={revision.name}>
											<div className="list-item-wrap">
												<div className="previous-revisions">{revision.name}</div>
												<div className="created">{revision.created}</div>
											</div>
										</div>
									)
								})}
						</div> : null}
					<div key="actions" className="modal__actions">
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<a className="summary-btn excel-download icon-download icon-left btn" onClick={() => this.downloadExcel()}>Download Excel only</a>
						<Button onClick={this.closeModal} display={Display.Solid} colors={Colors.Secondary}>Cancel</Button>
						<Button className="export-save" onClick={this.downloadExcelAndSaveAsNewRevision} display={Display.Solid} colors={Colors.Primary}
								disabled={!this.props.projectCanBeEdited}>Download Excel & Save Revision</Button>
					</div>
				</Modal>
			</>
		);
	}

	@action private downloadExcelAndSaveAsNewRevision = async () => {
		// download Excel
		this.downloadExcel();

		// Save as New Revision
		// build before saving if necessary
		if (this.props.project){ // this only available on project page. To get to here, this.props.project always returns true
			if (this.props.project && this.props.project.parsedElementStructure.info.editedSinceLastBuild) {
				this.loadViewText = "Saving as new revision";
				this.modalState.visible = "none";
				runInAction(() => {
					this.progressBar = ProgressBarState.Loading;
				});
				await this.props.project.buildProject();
				runInAction(() => {
					this.progressBar = ProgressBarState.Complete;
					if(this.loadView) {
						this.loadView.updateStyle(100);
					}
					setTimeout(() => runInAction(() => {
						this.progressBar = ProgressBarState.Hidden
					}), 500);
				});
			}
			// Create revision name with current time stamp.
			let revisionName : string = this.props.project.name + " - " + SummaryView.getDateFormat();
			// Create a new revision from the current element structure
			const newRevision = new ProjectRevisionEntity({
				name: revisionName,
				elementStructure: JSON.stringify(this.props.project.parsedElementStructure),
				notes: this.props.project.notes,
				projectId: this.props.project.id,
				project: this.props.project,
			});
			newRevision.save()
				.then(() => {
					if (this.props.revisionList){
						this.props.revisionList.push({
							name: newRevision.name,
							created: moment(newRevision.created).format('hh:mmA DD/MM/YYYY'),
						});
						alert(`Successfully saved revision.`, 'success');
					}
				});
		} else { // This ideally never happens. 
			alert(`Internal error. Please contact support.`, `error`);
		}
		

		// We've finished saving the new revision, so we close it and clear the revision name
		this.closeModal();
	};


	@action private openAxialLoadsModal = () => {
		// Get the element structure, since we'll need it below
		const { project } = this.props;
		if (!project) {
			return;
		}
		const elementStructure = project.parsedElementStructure;

		// Check if there are no duplicate names in the element structure, since it can cause problems
		if(!ElementStructureUtils.elementStructureHasNoDuplicateNames(elementStructure)) {
			alert(`The project contains duplicate level or column names. Please fix before importing axial loads.`, 'error');
			return;
		}
		
		this.modalState.visible = "axialLoads";
	};

	@action private openNotesModal = () => {
		const { project, revision } = this.props;
		if(project) {
			this.modalState.notes = project.notes
		}
		if(revision) {
			this.modalState.notes = revision.notes
		}
		
		this.modalState.visible = "notes";
	};

	@action private closeModal = () => {
		this.modalState = {
			visible: "none",
			fileName: "",
			fileContents: "",
			fileAxialLoads: [],
			cellChangedCount: 0,
			notes: "",
		};
	};

	private changeAxialLoadInput = async (e: FormEvent) => {
		// Get the file the user has just submitted
		const input = e.target as HTMLInputElement;
		if(!input.files || !input.files[0]) {
			// If there's no files, we exit early
			return;
		}
		const file = input.files[0];
		
		// Get the file contents
		const reader = new FileReader();
		reader.onload = () => {
			// this is called when we've finished reading the file
			runInAction(() => {
				this.modalState.fileName = file.name;
				this.modalState.fileContents = reader.result as string;
				this.modalState.fileAxialLoads = DataImportUtils.parseCsvStringAsAxialLoads(this.modalState.fileContents);
				
				// Get the count of cells this is going to change
				if(this.props.project) {
					if (ElementStructureUtils.invalidAxialLoadCSV(this.props.project.parsedElementStructure, this.modalState.fileAxialLoads)) {
						this.modalState.visible = 'axialLoadsErrors';
					} else {
						this.modalState.cellChangedCount = ElementStructureUtils.updateAxialLoads(
							this.props.project.parsedElementStructure,
							this.modalState.fileAxialLoads,
							false)
					}
				}
			});
		};
		reader.readAsText(file);
	};

	@action
	private async csvDownload(exportType: string) {
		let a = document.createElement("a");
		a.href = this.getCsvUri(exportType);
		a.download = await this.getCsvFileName(exportType);

		const clickEvent = new MouseEvent("click");
		a.dispatchEvent(clickEvent);
	}
	
	@action
	private submitAxialLoads = () => {
		this.props.importAxialLoads && this.props.importAxialLoads(this.modalState.fileAxialLoads);
		this.closeModal();
	};


	@action
	private submitNote = () => {
		const { project } = this.props;
		if(!project) {
			return;
		}
		
		project.notes = this.modalState.notes;
		
		this.props.afterChange && this.props.afterChange();
		
		this.closeModal();
	};

	protected summaryInformation: SummaryInformation = {
		[ProjectTableState.Elements]: {
			name: "Elements Table",
		},
		[ProjectTableState.TemporaryWorks]: {
			name: "Temporary Works",
		},
		[ProjectTableState.DetailedSchedule]: {
			name: "Detailed Schedule",
		},
		[ProjectTableState.Shutters]: {
			name: "Aptus Shutters",
		},
		[ProjectTableState.CostSummary]: {
			name: "Cost Summary",
		}
	};

	@action
	private downloadExcel = async () => {
		const { project, revision } = this.props;
		const filename = await this.getWorkbookFileName();
		if (project) {
			DataExportUtils.elementStructureWorkbook(project.parsedElementStructure, filename, project);
		} else if (revision) {
			DataExportUtils.elementStructureWorkbook(revision.parsedElementStructure, filename, revision.project);
		}
	};

	getCsvContents(exportType: string): string {
		const { project, revision } = this.props;
		let fileContents: string;

		if (project) {
			switch(exportType) {
				case ProjectTableState.Elements: {
					fileContents = DataExportUtils.elementsListCSV(project.parsedElementStructure);
					break;
				}
				case ProjectTableState.DetailedSchedule: {
					fileContents = DataExportUtils.scheduleOfRatesCSV(project.parsedElementStructure);
					break;
				}
				case ProjectTableState.TemporaryWorks: {
					fileContents = DataExportUtils.temporaryWorksCSV(project.parsedElementStructure);
					break;
				}
				case ProjectTableState.Shutters: {
					fileContents = DataExportUtils.shutterCSV(project.parsedElementStructure);
					break;
				}
				case ProjectTableState.CostSummary: {
					fileContents = DataExportUtils.costSummaryCSV(project.parsedElementStructure);
					break;
				}
				case "axialloads": {
					fileContents = DataExportUtils.axialLoadsCSV(project.parsedElementStructure);
					break;
				}
				default: fileContents = ""
			}
			return fileContents;
			
		} else if (revision) {
			switch(exportType){
				case ProjectTableState.Elements: {
					fileContents = DataExportUtils.elementsListCSV(revision.parsedElementStructure);
					break;
				}
				case ProjectTableState.DetailedSchedule: {
					fileContents = DataExportUtils.scheduleOfRatesCSV(revision.parsedElementStructure);
					break;
				}
				case ProjectTableState.TemporaryWorks: {
					fileContents = DataExportUtils.temporaryWorksCSV(revision.parsedElementStructure);
					break;
				}
				case ProjectTableState.Shutters: {
					fileContents = DataExportUtils.shutterCSV(revision.parsedElementStructure);
					break;
				}
				case ProjectTableState.CostSummary: {
					fileContents = DataExportUtils.costSummaryCSV(revision.parsedElementStructure);
					break;
				}
				case "axialloads": {
					fileContents = DataExportUtils.axialLoadsCSV(revision.parsedElementStructure);
					break;
				}
				default: fileContents = ""
			}
			return fileContents;
		}
		return "";
	}

	private static getDateFormat(): string {
		return moment(new Date()).format('DD-MM-YYYY_hh-mm-ss');
	}

	async getCsvFileName(exportType: string): Promise<string> {
		const { project, revision } = this.props;
		let name: string = SummaryView.getDateFormat();
		
		if (project) {
			name = project.name + "_" + exportType + "_" + SummaryView.getDateFormat() + ".csv";
		} else {
			if (revision) {
				name = revision.name + "_" + exportType + "_"+ SummaryView.getDateFormat() + ".csv";
				
				const promise = await ProjectEntity.fetch<ProjectEntity>({args: [[{path: 'id', comparison: "equal", value: revision.projectId}]]});
				name = promise[0].name + "_" + name;
			} 
		}
		return name;
	}

	async getWorkbookFileName(): Promise<string> {
		const { project, revision } = this.props;
		let name: string = SummaryView.getDateFormat();

		if (project) {
			name = project.name + "_" + SummaryView.getDateFormat() + ".xlsx";
		} else {
			if (revision) {
				name = revision.name + "_" + SummaryView.getDateFormat() + ".xlsx";

				const promise = await ProjectEntity.fetch<ProjectEntity>({args: [[{path: 'id', comparison: "equal", value: revision.projectId}]]});
				name = promise[0].name + "_" + name;
			}
		}
		return name;
	}

	private getCsvUri(exportType: string): string {
		return "data:text/csv;base64," + btoa(this.getCsvContents(exportType));
	}
}
