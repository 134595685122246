import * as React from "react";
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import InputWrapper from '../Inputs/InputWrapper';
import * as uuid from 'uuid';
import { DisplayType } from '../Models/Enums';
import InputsHelper from '../Helpers/InputsHelper';

export interface ChipsProps<T> {
	model: T;
	modelProperty: string;
	id?: string;
	name?:string;
	className?: string;
	displayType?: DisplayType;
	label?: string;
	labelVisible?: boolean;
	isRequired?: boolean;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	staticInput?: boolean;
	tooltip?: string;
	subDescription?: string;
	inputProps?: React.InputHTMLAttributes<Element>;
	placeholder?: string;
	clickToClear?: boolean;
	autoFocus?: boolean;
	errors?: string | string[];
	onChangeChips?: () => void;
	pattern?: RegExp;
}

@observer
export class Chips<T> extends React.Component<ChipsProps<T>, any> {
	public static defaultProps = {
		clickToClear: false,
		inputProps: {},
		className: '',
	};
	private  _input?: HTMLInputElement;
	private uuid = uuid.v4();
	private valueWhenFocused: string = '';
	
	@observable private partialInput: string = '';

	componentDidMount() {
		if(this.props.autoFocus && this._input){
			this._input.focus();
		}
	}

	public render() {
		const { model, modelProperty, name, className, displayType, label, isRequired, isDisabled, isReadOnly, staticInput, tooltip, subDescription, clickToClear, placeholder, errors } = this.props;
		const id = this.props.id || this.uuid.toString();
		const fieldId = `${id}-field`;

		const labelVisible = (this.props.labelVisible === undefined) ? true : this.props.labelVisible;
		const ariaLabel = !labelVisible ? label : undefined;
		const ariaDescribedby = InputsHelper.getAriaDescribedBy(id, tooltip, subDescription);

		return (
			<InputWrapper id={id} inputId={fieldId}  className={className} displayType={displayType} isRequired={isRequired} staticInput={staticInput} tooltip={tooltip} subDescription={subDescription} label={label} labelVisible={labelVisible} errors={errors}>
				<div className="chips-input-wrap" onClick={this.clickInputWrap}>
					{model[modelProperty] instanceof Array
						? model[modelProperty].map((chip: string) => 
							<div className="chip" key={chip}>
								<span className="chip-text">{chip}</span>
								<button className="click-to-clear icon-cross icon-right" onClick={() => this.clearChip(chip)} type="button" aria-label={"Clear "+chip} />
							</div>
						)
						: null}
					<input
						type="text"
						name={name}
						id={fieldId}
						value={this.partialInput}
						onChange={this.onChange}
						onKeyDown={this.addChipOnKeyDown}
						onBlur={this.addChip}
						placeholder={placeholder ? placeholder : (label ? label : undefined)}
						disabled={isDisabled}
						readOnly={staticInput || isReadOnly}
						aria-label={ariaLabel}
						aria-describedby = {ariaDescribedby}
						ref={i => (this._input = i || undefined)}
						{...this.props.inputProps}
					/>
				</div>
			</InputWrapper>
		);
	}

	@action
	private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.partialInput = event.target.value;
	};
	
	@action private addChipOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		// We only trigger if the user pressed enter, or entered a comma
		if(event.key === "Enter" || event.key === ",") {
			this.addChip();
			event.preventDefault();
		}
	};
	
	@action private addChip = () => {
		const { model, modelProperty, pattern } = this.props;
		if(!(model[modelProperty] instanceof Array)) {
			model[modelProperty] = [];
		}

		// We can't add anything if the input is empty
		if(this.partialInput === "") {
			return;
		}

		// If the chips need to match a pattern, we check it here
		if(pattern && !this.partialInput.match(pattern)) {
			return;
		}

		// Make sure this chips entry isn't already in the list
		if(model[modelProperty].includes(this.partialInput)) {
			return;
		}

		model[modelProperty].push(this.partialInput);
		this.partialInput = "";

		if (this.props.onChangeChips) {
			this.props.onChangeChips();
		}
	};
	
	@action private clearChip = (chip: string) => {
		const { model, modelProperty } = this.props;
		model[modelProperty] = model[modelProperty].filter((x: string) => x !== chip);
		if (this.props.onChangeChips) {
			this.props.onChangeChips();
		}
	};
	
	private clickInputWrap = () => {
		// If we click inside the input wrap, we want to focus on the input
		this._input && this._input.focus();
	};
}