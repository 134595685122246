import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { action, computed, observable, runInAction } from "mobx";
import { Icon } from "semantic-ui-react";
import { store } from "Models/Store";
import ProjectEntity from "Models/Entities/ProjectEntity";
import { ElementStructureUtils } from "Util/ElementStructureUtils";
import classNames from "classnames";
import { Button, Colors, Display } from "Views/Components/Button/Button";
import WizardSetupStep from "Views/Components/ProjectWizard/WizardSetupStep";
import WizardStep from "Views/Components/ProjectWizard/WizardStep";
import WizardTemporaryWorksStep from "Views/Components/ProjectWizard/WizardTemporaryWorksStep";
import WizardLevelsStep from "Views/Components/ProjectWizard/WizardLevelsStep";
import alert from "Util/ToastifyUtils";
import WizardElementsStep from "Views/Components/ProjectWizard/WizardElementsStep";
import WizardCustomiseStep from "Views/Components/ProjectWizard/WizardCustomiseStep";
import { LoadView, ProgressBarState } from "Views/Components/LoadView/LoadView"
import ProjectLockoutHelper from "Util/ProjectLockoutHelper";
import { confirmModal } from "Views/Components/Modal/ModalUtils";

export enum ProjectWizardStep {
	Setup = "setup",
	TemporaryWorks = "tempworks",
	LevelsSetup = "levels",
	ElementsSetup = "elements",
	CustomiseElements = "cells",
}

interface StepInformation {
	[key: string]: {
		name: string;
		description: string;
		multiselectNote?: string;
		component: typeof WizardStep;
		pageTitleText?: string;
		nextBtnText?: string;
		prevBtnText?: string;
		validate?: () => boolean;
	};
};

export interface ProjectWizardProps extends RouteComponentProps {
	project: ProjectEntity;
	lockoutHelper: ProjectLockoutHelper.Single;
}

@observer
export default class ProjectWizard extends React.Component<ProjectWizardProps> {

	get currentStep() {
		if(this.props.project.parsedElementStructure.info.wizardProgress.currentWizardStep
			&& this.stepListOrder.includes(this.props.project.parsedElementStructure.info.wizardProgress.currentWizardStep)) {
			
			return this.props.project.parsedElementStructure.info.wizardProgress.currentWizardStep;
		}
		return ProjectWizardStep.Setup;
	}	
	get maxStep() {
		if(this.props.project.parsedElementStructure.info.wizardProgress.maxWizardStep
			&& this.stepListOrder.includes(this.props.project.parsedElementStructure.info.wizardProgress.maxWizardStep)) {
			
			return this.props.project.parsedElementStructure.info.wizardProgress.maxWizardStep;
		}
		return ProjectWizardStep.Setup;
	}
	

	protected stepListOrder = [
		ProjectWizardStep.Setup,
		ProjectWizardStep.TemporaryWorks,
		ProjectWizardStep.LevelsSetup,
		ProjectWizardStep.ElementsSetup,
		ProjectWizardStep.CustomiseElements,
	];
	
	@observable
	public project: ProjectEntity;

	@observable
	private requestState: 'loading' | 'error' | 'done' = 'loading';

	@observable
	private progressBar = ProgressBarState.Hidden;

	@computed get barStatus(): ProgressBarState {
		return this.progressBar
	}

	private loadView: LoadView | null = null;
	
	public componentDidMount(): void {
		// If the project was left on another state, we go there instead
		if(this.props.project.parsedElementStructure.info.wizardProgress.currentWizardStep) {
			this.changeStep(this.props.project.parsedElementStructure.info.wizardProgress.currentWizardStep);
		}
	}
	
	public render() {
		const { lockoutHelper } = this.props;
		const currentStep = this.stepInformation[this.currentStep];
		const CurrentStepComponent = currentStep.component;
		return (
			<>
				<div className="project-wizard-content">
					<div className="project-sidebar">
						{lockoutHelper.canEdit
							? null
							: <div className="back-btn-wrap">
								<a className="back-btn setup-return icon-arrow-left icon-left btn" 
									href={"/"}>
									Back to dashboard
								</a>
							</div>
						}
						<h1 className="project-wizard-title">{currentStep.name}</h1>
						<p className="project-wizard-description">{currentStep.description}</p>
						{currentStep.multiselectNote 
							? <p className="project-wizard-multiselect-note">{currentStep.multiselectNote}</p>
							: null}
						{this.stepListOrder.map((step, index) => {
							let stepDisabled = false;
							if(this.stepListOrder.indexOf(step) > this.stepListOrder.indexOf(this.maxStep)) {
								stepDisabled = true;
							}
							return (
								<div 
									key={step} 
									onClick={!stepDisabled ? () => this.changeStep(step) : undefined}
									className={classNames(
										'wizard-step', {
											'active': step === this.currentStep, 
											'disabled': stepDisabled})}>
									<div className="step-number">{index + 1}</div>
									<p className="step-name">{this.stepInformation[step].name}</p>
								</div>
							);
						})}
					</div>
					<div id="main-content" className="main-content">
						{!lockoutHelper.canEdit && lockoutHelper.shouldShowEditingUser
							? (
								<div className={"project-editing-not-allowed-warning"}>
									<h6>
										{lockoutHelper.editingUserName} is editing this project. Your changes will not be saved!
									</h6>
								</div>
							) : null}
						{lockoutHelper.canEdit && lockoutHelper.shouldShowCanEditBanner
							? (
								<div className="project-editing-not-allowed-warning green">
									<h6>
										{lockoutHelper.editingUserName} has left the project. You can now edit the project
									</h6>
									<Icon className="dismiss-btn" onClick={() => lockoutHelper.dismissCanEditBanner()} name="x"/>
								</div>
							) : null}
						<div className="top-bar">
							{currentStep.pageTitleText
								? <h2 className="current-step-title">
										{currentStep.pageTitleText}
									</h2>
								: null}
							{this.stepListOrder.indexOf(this.currentStep) === 0 // if we're on the first step
								? <Button
									onClick={this.cancelAndDeleteProject}
									className="cancel-btn"
									display={Display.Solid}
									colors={Colors.Secondary}
								>
									Cancel
								</Button>
								: null
							}
							<Button
								onClick={this.closeWizard}
								className="save-close-btn"
								display={Display.Solid}
								colors={Colors.Secondary}
								disabled={!lockoutHelper.canEdit}
							>
								Close
							</Button>
						</div>
						<div className="step-area">
							<CurrentStepComponent project={this.props.project} projectCanBeEdited={lockoutHelper.canEdit}/>
						</div>
						<div className="bottom-bar">
							<Button
								onClick={this.previousStep}
								disabled={this.stepListOrder.indexOf(this.currentStep) <= 0 && !lockoutHelper.canEdit}
								className="previous-btn"
								display={Display.Solid}
								colors={Colors.Secondary}
								icon={{ icon: "arrow-left", iconPos: 'icon-left' }}
							>
								{currentStep.prevBtnText ? currentStep.prevBtnText : "Previous"}
							</Button>
							<Button
								onClick={this.nextStep}
								disabled={(this.stepListOrder.indexOf(this.currentStep) === (this.stepListOrder.length - 1) && !lockoutHelper.canEdit) || !CurrentStepComponent.validateStep(this.props.project)}
								className="next-btn"
								display={Display.Solid}
								colors={Colors.Primary}
								icon={{ icon: "arrow-right", iconPos: 'icon-right' }}
							>
								{currentStep.nextBtnText ? currentStep.nextBtnText : "Next"}
							</Button>
						</div>
						{this.progressBar !== ProgressBarState.Hidden 
							? <LoadView text={"Building Aptus Design"} estimate={ElementStructureUtils.getBuildTimeEstimate(this.props.project)} completed={this.progressBar} ref={ref => this.loadView = ref} />
							: null 
						}
					</div>
				</div>
			</>
		);
	}

	private cancelAndDeleteProject = () => {
		confirmModal('Please confirm', "Are you sure you want to cancel project setup and delete this project?")
			.then(action(async () => {
				if (this.props.project.id) {
					// Delete project and go to dashboard page
					this.props.project.deleted = true;
					await this.props.project.save();
					store.routerHistory.push(`/dashboard`);
				}
			}));
	};

	private closeWizard = () => {
		// save the project, then return to the dashboard
		if (this.props.project.name && this.props.project.folder) {
			this.props.project.save().then(r => store.routerHistory.push(`/dashboard`));
		} else {
			alert(`A project folder and name are required to save the project`, 'error');
		}
	};

	protected stepInformation: StepInformation = {
		[ProjectWizardStep.Setup]: {
			name: "Project Setup",
			description: "Set up the basic information for your Aptus project.",
			component: WizardSetupStep,
		},
		[ProjectWizardStep.TemporaryWorks]: {
			name: "Design Inputs",
			description: "Minimum information that is needed for a temporary works certification.",
			component: WizardTemporaryWorksStep,
		},
		[ProjectWizardStep.LevelsSetup]: {
			name: "Levels Setup",
			description: "Set up the levels in your project. Once they have been generated, you can add more, edit, or delete levels.",
			component: WizardLevelsStep,
		},
		[ProjectWizardStep.ElementsSetup]: {
			name: "Elements Setup",
			description: "Set up the different types of elements and columns you will need in the project.",
			multiselectNote: "You can use 'Shift' and 'Ctrl' to select and edit multiple element types at once.",
			component: WizardElementsStep,
		},
		[ProjectWizardStep.CustomiseElements]: {
			name: "Customise Elements",
			description: "Click individual or multiple cells to edit details and customise your project.",
			multiselectNote: "You can use 'Shift' and 'Ctrl' to select and edit multiple elements at once.",
			component: WizardCustomiseStep,
			pageTitleText: "Setup element dimensions",
			nextBtnText: "Build Aptus Design",
		},
	};

	@action protected nextStep = async () => {
		const currentStepIndex = this.stepListOrder.indexOf(this.currentStep);
		
		// Do anything we need to do once a step is complete
		await this.stepInformation[this.currentStep].component.afterStep(this.props.project);
		
		if (currentStepIndex + 1 < this.stepListOrder.length) {

			// go to the next step
			this.changeStep(this.stepListOrder[currentStepIndex + 1])
		} else {
			this.completeWizard();
		}
	};

	protected previousStep = () => {
		const currentStepIndex = this.stepListOrder.indexOf(this.currentStep);
		if (currentStepIndex > 0) {
			this.changeStep(this.stepListOrder[currentStepIndex - 1])
		}
	};

	@action changeStep = (newStep: ProjectWizardStep) => {
		// We can only change to this step if it's a legitimate step.
		if(this.stepListOrder.includes(newStep)) {			
			this.props.project.parsedElementStructure.info.wizardProgress.currentWizardStep = newStep;
			
			// If this step is greater than any step we've already reached, then we update that value too
			if(this.stepListOrder.indexOf(this.maxStep) < this.stepListOrder.indexOf(newStep)) {
				this.props.project.parsedElementStructure.info.wizardProgress.maxWizardStep = newStep;
			}

			// Whenever we move between steps, we save the project
			if (this.props.lockoutHelper.canEdit) {
				this.props.project.saveProject();
			}
		}
	};

	protected completeWizard = () => {
		// Mark the project as having completed the wizard, so the user can jump right to the elements grid in the future
		this.props.project.completedWizard = true;
		
		runInAction(() => {
		this.progressBar = ProgressBarState.Loading
		});
		
		// Build the project, then redirect to the main project page.
		this.props.project.buildProject()
			.then(() => 
			{
			// Set the build percentage metre to full, and start fading it out
			runInAction(() => {
				this.progressBar = ProgressBarState.Hidden;
				if(this.loadView) {
					this.loadView.updateStyle(100);
				}
			});
			
			// Once it's faded out, remove it
			setTimeout(() => runInAction(() => {
				this.progressBar = ProgressBarState.Hidden
			}), 300);
			store.routerHistory.push(`/project/${this.props.project.id}`);
			});
	};
}