import * as React from 'react';
import { observer } from 'mobx-react';
import { action, computed, observable, runInAction } from "mobx";

import {TextField } from "Views/Components/TextBox/TextBox";
import { NumberTextField } from "Views/Components/NumberTextBox/NumberTextBox";
import { Button, Colors, Display } from "Views/Components/Button/Button";

import type { LevelSSLErrorKey } from "Util/ElementStructureUtils";
import type { SelectedLevel } from "Util/SelectionUtils";
import { ElementStructureUtils } from "Util/ElementStructureUtils";

import { prebuildValConsts } from "Models/Entities/ProjectEntity";

export interface ILevelEditViewProps {
	editLevel: EditLevel;
	editLevelErrors: EditLevelErrors;
	multipleSelected: boolean;
	saveChanges: () => void;
	deleteLevels: () => void;
	cancelSelection: () => void;
	readonly?: boolean;
	simplified?: boolean;
	getLevelHeight: (editLevel: EditLevel) => number;
	getLevelHeightBelow: (editLevel: EditLevel) => number | "No Level Below";
	alwaysAllowSave?: boolean;
	selectedLevels: SelectedLevel[]
}

export interface EditLevel {
	model: {
		code?: string;
		name?: string;
		slabThicknessAtBase?: number;
		groundLevel?: boolean;
		ssl?: number;
		topSsl?: number;
		calculatedFloorHeight?: number;
	}
	info: {
		topLevelSelected?: boolean;
	}
}

export interface EditLevelErrors {
	code?: string;
	name?: string;
	ssl?: string;
	topSsl?: string;
}

@observer
export default class LevelEditView extends React.Component<ILevelEditViewProps> {
	@observable
	private calculatedLevelHeight: number | undefined;
	
	@observable
	private levelErrorCode: string | undefined;

	@computed
	private get prebuildErrorConsts(): Record<LevelSSLErrorKey, string> {
		return {
			minHeightError: `Height must at least ${prebuildValConsts.minHeight} mm`,
			maxHeightError: `Height must be less than ${prebuildValConsts.maxHeight} mm`,
			minHeightErrorBelow: `Height of level below must be at least ${prebuildValConsts.minHeight} mm`,
			maxHeightErrorBelow: `Height of level below must be less than ${prebuildValConsts.maxHeight} mm`,
		}
	}

	public componentDidMount() {
		this.validLevelHeight();
	}

	public componentDidUpdate(prevProps: Readonly<ILevelEditViewProps>) {
		const { selectedLevels } = this.props;
		
		// If you change cell make sure to turn off onCustomEdit
		let differentLevels =  prevProps.selectedLevels.length !== this.props.selectedLevels.length;
		
		selectedLevels.forEach(({ model: lvl }) => {
			differentLevels = differentLevels || !prevProps.selectedLevels.find(oldLevel => oldLevel.model.id === lvl.id);
		});
		
		if (differentLevels) {
			runInAction(() => {
				this.calculatedLevelHeight = undefined;
				this.levelErrorCode = undefined;
				this.validLevelHeight();
			});
		}
	}
	
	public render() {
		const { editLevel, editLevelErrors, multipleSelected, readonly, cancelSelection } = this.props;
		return (
			<>
				<div className="edit-view-header">
					<h4 className="edit-view-title">Edit level</h4>
					<button
						onClick={() => {
							cancelSelection();
							runInAction(()=>{
								this.levelErrorCode = undefined;
								this.calculatedLevelHeight = undefined;
							});
						}}
						className="close-edit-view-btn"
						aria-label="Close"
					/>
				</div>
				<div className="edit-view-tab-content panel-input-group">
					<TextField
						className="code"
						model={editLevel.model}
						modelProperty="code"
						label="Level code"
						isDisabled={multipleSelected || readonly}
						tooltip="The code used in the individual element marks"
						onAfterChange={() => runInAction(() => editLevelErrors.code = undefined)}
						onChangeAndBlur={this.saveLevels}
						errors={editLevelErrors.code ? editLevelErrors.code : undefined} />
					<TextField
						className="name"
						model={editLevel.model}
						modelProperty="name"
						label="Level name"
						tooltip="The name of the level"
						isDisabled={multipleSelected || readonly}
						onAfterChange={() => runInAction(() => editLevelErrors.name = undefined)}
						onChangeAndBlur={this.saveLevels}
						errors={editLevelErrors.name ? editLevelErrors.name : undefined} />
					<NumberTextField
						className="slabThicknessAtBase"
						model={editLevel.model}
						modelProperty="slabThicknessAtBase"
						label="Slab thickness at base of level (mm)"
						isDisabled={readonly}
						onAfterChange={() => runInAction(() => ElementStructureUtils.cleanInt(editLevel.model, "slabThicknessAtBase"))}
						onChangeAndBlur={this.saveLevels}
						tooltip="The typical thickness of the concrete slab at the base of any columns in this level"/>
						
					<NumberTextField
						className="ssl"
						model={editLevel.model}
						modelProperty="ssl"
						label="SSL (m)"
						tooltip="The SSL at the base of this level. of the ground floor."
						onAfterChange={() => runInAction(() => {
							editLevelErrors.ssl = undefined;
							this.validLevelHeight();
						})}
						onChangeAndBlur={this.saveLevels}
						isDisabled={readonly || multipleSelected}
						errors={editLevelErrors.ssl ? editLevelErrors.ssl : undefined} />
						
					{editLevel.info.topLevelSelected 
						? <NumberTextField
							className="topSsl"
							model={editLevel.model}
							modelProperty="topSsl"
							label="SSL at Top (m)"
							tooltip="The SSL at the top of this element, above the concrete slab."
							onAfterChange={() => runInAction(() => {
								editLevelErrors.topSsl = undefined;
								this.validLevelHeight();
							})}
							onChangeAndBlur={this.saveLevels}
							isDisabled={readonly || multipleSelected}
							errors={editLevelErrors.topSsl ? editLevelErrors.topSsl : undefined} />
						: null}
					{this.levelErrorCode !== undefined && this.calculatedLevelHeight !== undefined?
						<>
							<p className="dimensions-error-text">{this.prebuildErrorConsts[this.levelErrorCode]}</p>
							<p className="dimensions-error-text">Resultant Level Height: {this.calculatedLevelHeight} mm</p>
						</>
						: null
					}
						
					<NumberTextField
						className="calculatedFloorHeight"
						model={editLevel.model}
						modelProperty="calculatedFloorHeight"
						label="Floor to Floor Height (mm)"
						tooltip="The distance between the SSL at base of this element, and the SSL at base of the element above. This value is automatically calculated."
						isDisabled={true} />
						
				</div>
				{!readonly ? this.renderActions() : null}
			</>
		);
	}
	
	private renderActions = () => {
		const { deleteLevels } = this.props;
		return (
			<div className="edit-view-actions">
				<Button onClick={deleteLevels} className="delete-level-btn" display={Display.Text} colors={Colors.Primary} icon={{ icon: "bin-delete", iconPos: 'icon-left' }}>Remove</Button>
			</div>
		);
	};
	
	@action
	private validLevelHeight() {
		const { editLevel, getLevelHeight, getLevelHeightBelow } = this.props;
		
		if((editLevel.model.ssl || editLevel.model.ssl === 0) || editLevel.model.topSsl) {
			if (!getLevelHeight) return "invalid";

			this.calculatedLevelHeight = getLevelHeight(editLevel);

			// Check the current level height is valid
			if (this.calculatedLevelHeight < prebuildValConsts.minHeight) {
				this.levelErrorCode = "minHeightError";
				return this.levelErrorCode;
			}
			if (this.calculatedLevelHeight > prebuildValConsts.maxHeight) {
				this.levelErrorCode = "maxHeightError";
				return this.levelErrorCode;
			}
			// Check the Level below height is valid
			const levelBelowHeight = getLevelHeightBelow(editLevel);

			if (levelBelowHeight !== "No Level Below") {
				this.calculatedLevelHeight = levelBelowHeight;

				if (levelBelowHeight < prebuildValConsts.minHeight) {
					this.levelErrorCode = "minHeightErrorBelow";
					return this.levelErrorCode;
				}

				if (levelBelowHeight > prebuildValConsts.maxHeight) {
					this.levelErrorCode = "maxHeightErrorBelow";
					return this.levelErrorCode;
				}
			}
		}

		this.calculatedLevelHeight = undefined;
		this.levelErrorCode = undefined;

		return "valid"
	}

	private canSaveLevels = () => {
		const { multipleSelected, editLevel, alwaysAllowSave } = this.props;

		if (alwaysAllowSave) {
			// validate levels but allow saving
			this.validLevelHeight();
			return true;
		} else if (multipleSelected) {
			return this.validLevelHeight() === "valid";
		} else if (!editLevel.model.code || !editLevel.model.name || this.validLevelHeight() !== "valid") {
			return false;
		} else {
			return true;
		}
	};

	private saveLevels = () => {
		if (this.canSaveLevels()) {
			this.props.saveChanges();
		}
	};
}