import * as React from 'react';
import {observer} from "mobx-react";
import WizardStep from "Views/Components/ProjectWizard/WizardStep";
import ElementGrid from "Views/Components/ElementGrid/ElementGrid";
import ProjectEntity from "Models/Entities/ProjectEntity";

@observer
export default class WizardCustomiseStep extends WizardStep {

	public render() {
		return <ElementGrid
			sideBarWidth={ 300 }
			setSideBarWidth={() => undefined}
			elementStructure={this.props.project.parsedElementStructure}
			simplified={true}
			afterChange={() => this.checkProjectCanBeEditedThenSave()}
			readonly={!this.props.projectCanBeEdited}
		/>
	}

	public static afterStep = async (project: ProjectEntity) => {
		await project.saveProject();
	};

	private checkProjectCanBeEditedThenSave = () => {
		if (this.props.projectCanBeEdited) {
			this.props.project.saveProject();
		}
	}
}