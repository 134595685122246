import * as React from 'react';
import {observer} from 'mobx-react';
import {Button, Colors, Display} from "Views/Components/Button/Button";
import classNames from "classnames";
import Modal from '../Modal/Modal';
import {Chips} from "Views/Components/Chips/Chips";
import {TextField} from "Views/Components/TextBox/TextBox";
import { ProjectShare, ProjectShareStatus, DuplicateModalState } from "./Dashboard";

export interface IShareProjectModalProps {
	shareModalState: { visible: boolean },
	closeModal: () => void,
	selectedProjectShares?: ProjectShare[],
	sendInvites: () => void,
	removeAccess: (share: ProjectShare) => void,
}

@observer
export class ShareProjectModal extends React.Component<IShareProjectModalProps> {	
	render() {
		return (
			<Modal className="share-project-modal" isOpen={this.props.shareModalState.visible} label="Share Project Modal" onRequestClose={this.props.closeModal}>
				<h4 key="header" className="modal__header">Share project with an organisation</h4>
				<p className="share-permissions icon-check icon-left">Invited organisations can edit and duplicate the project.</p>
				<p className="share-permissions icon-cross icon-left">Invited organisations cannot delete the project or invite other organisations to the project.</p>

				<Chips
					model={this.props.shareModalState}
					modelProperty="inviteEmails"
					label="Enter one contact per organisation"
					placeholder="Enter email addresses…"
					pattern={new RegExp(
						// eslint-disable-next-line no-useless-escape
						/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)} />

				<div className="share-list">
					{this.props.selectedProjectShares && this.props.selectedProjectShares
						.filter(x => x.status !== ProjectShareStatus.OwnsProject)
						.sort()
						.map(share => 
							<div className="share-item" key={share.organisationName ? share.organisationName : share.email}>
								<div className="organisation-image">
									{share.organisationId
										? <img src={`/api/entity/OrganisationEntity/logo/${share.organisationId}`} alt="organisation logo"/>
										: "?"}
								</div>
								<div className="share-details">
									<div className="organisation-name">{share.organisationName ? share.organisationName : "Inviting new user..."}</div>
									<div className="user-email">{share.email ? share.email : null}</div>
								</div>
								{share.status !== ProjectShareStatus.Sending && share.status !== ProjectShareStatus.Failed
									? <Button 
										onClick={() => this.props.removeAccess(share)} 
										display={Display.Solid} 
										colors={Colors.Secondary}
										disabled={share.status === ProjectShareStatus.Removing}>Remove access</Button>
									: null}
								<div className={classNames("share-status", share.status === ProjectShareStatus.Failed ? "failed" : null)}>{share.status}</div>
							</div>
					)}
				</div>

				<div key="actions" className="modal__actions">
					<Button onClick={this.props.closeModal} display={Display.Solid} colors={Colors.Secondary}>Cancel</Button>
					<Button onClick={this.props.sendInvites} display={Display.Solid} colors={Colors.Primary}>Send Invites</Button>
				</div>
			</Modal>
		)
	};
};

export interface IDuplicateModalProps {
	closeModal: () => void,
	duplicateModalState: DuplicateModalState,
	duplicateModal: () => {}
}

@observer
export class DuplicateProjectModal extends React.Component<IDuplicateModalProps> {	
	render() {
		return (
			<Modal isOpen={this.props.duplicateModalState.visible} label="Project Duplication Modal" onRequestClose={this.props.closeModal}>
					<h4 key="header" className="modal__header">Duplicate Project</h4>
					<h5>Fill in the details below to save your duplicated project.</h5>
						<TextField
							model={this.props.duplicateModalState}
							modelProperty="duplicateName"
							label="Project name"
							errors={this.props.duplicateModalState.nameError ? this.props.duplicateModalState.nameError : undefined} />
						<TextField
							model={this.props.duplicateModalState}
							modelProperty="jobNumber"
							label="Job number"
							errors={this.props.duplicateModalState.jobError ? this.props.duplicateModalState.jobError : undefined} />
					<div key="actions" className="modal__actions">
						<Button onClick={this.props.closeModal} display={Display.Solid} colors={Colors.Secondary}>Cancel</Button>
						<Button onClick={this.props.duplicateModal} display={Display.Solid} colors={Colors.Primary}>Save</Button>
					</div>
				</Modal>
		)
	};
};

export default ShareProjectModal;