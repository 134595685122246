import * as React from 'react';
import { action, runInAction, computed } from "mobx";
import { observer } from "mobx-react";
import classNames from "classnames";

import { ProjectEntity } from "Models/Entities";

import { ElementStructure, ElementStructureUtils, Level } from "Util/ElementStructureUtils";
import { SelectionUtils } from "Util/SelectionUtils";

import { Button, Display } from "Views/Components/Button/Button";
import { confirmModal } from "Views/Components/Modal/ModalUtils";
import LevelEditView from "Views/Components/ElementGrid/LevelEditView";
import { ProjectWizardStep } from "Views/Components/ProjectWizard/ProjectWizard";

export interface EditLevelGridProps {
	project: ProjectEntity;
	projectCanBeEdited?: boolean;
}

@observer
export default class EditLevelsGrid extends React.Component<EditLevelGridProps> {
	
	get elementStructure(): ElementStructure {
		return this.props.project.parsedElementStructure;
	}

	@computed
	private get levelValidationErrors() {
		return this.props.project.parsedElementStructure.levels
			.reduce((acc, level) => {
				const [, , errorCode] = ElementStructureUtils
					.validateLevelSSL(this.props.project.parsedElementStructure, level.id, level.slabThicknessAtBase, level.ssl, level.topSsl);
				
				return errorCode ? {
					...acc,
					[level.id]: errorCode,
				} : acc;
			}, {});
	}

	private afterChange = () => {
		if (this.props.projectCanBeEdited) {
			const { project } = this.props;

			const levelsFailedValidation = project.parsedElementStructure.levels
				.reduce((hasFailed: boolean, level) => {
					const [, , errorCode] = ElementStructureUtils
						.validateLevelSSL(project.parsedElementStructure, level.id, level.slabThicknessAtBase, level.ssl, level.topSsl);
					
					return hasFailed || !!errorCode;
				}, false);
			
			if (levelsFailedValidation) {
				runInAction(() => {
					// if levels are invalid, bump user back to wizard to force them to fix levels first
					this.props.project.completedWizard = false;
					this.props.project.parsedElementStructure.info.wizardProgress.maxWizardStep = ProjectWizardStep.LevelsSetup;
				});
			}
			this.props.project.saveProject();
		}
	};

	private selectionUtils: SelectionUtils = new SelectionUtils(this.elementStructure, false, this.afterChange);

	@action
	private saveLevels = () => {
		this.selectionUtils.saveItemChanges(true);
	}

	public render() {
		return (
			<div className="edit-levels-grid">
				<div className="edit-headers">
					<div className="header">Level</div>
					<div className="header">Floor to floor level (mm)</div>
					<div className="header">Slab thickness (mm)</div>
					<div className="header">SSL (m)</div>
				</div>
				<div className="edit-rows">
					{/* Building Top level */}
					<div className="edit-row rooftop">
						<div className="level-name-code info-cell">Building Top</div>
						<div className="no-elements-message info-cell">No elements exist on this level</div>
						<div className="ssl info-cell">
							{this.elementStructure.levels.length > 0 && this.elementStructure.levels[0].topSsl !== undefined
								? (this.elementStructure.levels[0].topSsl).toFixed(3) 
								: null}
						</div>
						<div className="add-btn-wrap">
							<Button onClick={() => this.selectionUtils.addLevel(0)} disabled={!this.props.projectCanBeEdited} className="add-btn" display={Display.Text} icon={{ icon: "plus-circle", iconPos: 'icon-left' }}>Add level below</Button>
						</div>
					</div>
					{/* Loop levels in building, including ground and below ground */}
					{this.elementStructure.levels
						.map((level, index) => {
						return (
							<div className={classNames("edit-row", { 'ground': level.groundLevel, 'selected': this.selectionUtils.isLevelHighlighted(level) })} key={level.id}>
								<div className={classNames("info-cell-wrap", { 'level-has-error': this.levelValidationErrors[level.id] })} onClick={(event: React.MouseEvent) => this.selectionUtils.clickLevel(event, level)}>
									<div className="level-name-code info-cell">
										<span className="code">{level.code}</span>
										<span className="name">{level.name}</span>
									</div>
									<div className="floor-height info-cell">{level.calculatedFloorHeight !== undefined ? level.calculatedFloorHeight : null}</div>
									<div className="slab-thickness info-cell">{level.slabThicknessAtBase}</div>
									<div className="ssl info-cell">{level.ssl.toFixed(3)}</div>
								</div>
								<div className="btn-wrap">
									<div className="add-btn-wrap">
										<Button
											onClick={() => this.selectionUtils.addLevel(index + 1)}
											disabled={!this.props.projectCanBeEdited}
											className="add-btn" 
											display={Display.Text}
											icon={{ icon: "plus-circle", iconPos: 'icon-left' }}
										>
											Add level below
										</Button>
									</div>
									{!level.groundLevel
										? <div className="remove-btn-wrap">
											<Button
												onClick={() => this.removeLevel(level)}
												disabled={!this.props.projectCanBeEdited}
												className="remove-btn"
												display={Display.Text}
												icon={{ icon: "bin-delete", iconPos: 'icon-left' }}
											>
												Remove
											</Button>
										</div>
										: null}
								</div>
							</div>
						);
					})}
				</div>
				<div className={classNames("edit-view", {'visible': this.selectionUtils.currentlyEditing})}>
					<div className="edit-view-inner">
						<LevelEditView
							alwaysAllowSave
							editLevel={this.selectionUtils.editLevel}
							editLevelErrors={this.selectionUtils.editLevelErrors}
							multipleSelected={this.selectionUtils.selectedLevels.length > 1}
							saveChanges={this.saveLevels}
							deleteLevels={this.selectionUtils.deleteSelectedLevels}
							cancelSelection={this.selectionUtils.cancelSelection}
							readonly={!this.props.projectCanBeEdited}
							getLevelHeight={(editLevel) => this.selectionUtils.getValidLevelHeight(editLevel, this.selectionUtils.selectedLevels)}
							getLevelHeightBelow={(editLevel) => this.selectionUtils.getValidLevelBelowHeight(editLevel, this.selectionUtils.selectedLevels)}
							selectedLevels={[...this.selectionUtils.selectedLevels]}
						/>
					</div>
				</div>
			</div>
		);
	}
	
	private removeLevel = (level: Level) => {
		// If we're deleting a level that has some associated cells, then we confirm with the user first
		// However, we assume that any element structure that has cells will automatically have some on this level, so we just check that
		if(Object.keys(this.elementStructure.cells).length > 0) {
			confirmModal('Please confirm', "Removing this level would also remove any elements on this level. Are you sure you want to continue?")
				.then(() => {
					ElementStructureUtils.deleteLevelFromElementStructure(this.elementStructure, level);
					this.afterChange();
				});
		} else {
			ElementStructureUtils.deleteLevelFromElementStructure(this.elementStructure, level);
			this.afterChange();
		}
	};
}