import * as React from 'react';
import {observer} from 'mobx-react';
import {RouteComponentProps} from 'react-router';
import {action, observable, runInAction, autorun} from "mobx";
import classNames from "classnames";
import moment from 'moment';
import { Icon } from "semantic-ui-react";
import axios from "axios";

import { SERVER_URL } from "Constants";

import {store} from "Models/Store";
import ProjectEntity from "Models/Entities/ProjectEntity";
import ProjectRevisionEntity from "Models/Entities/ProjectRevisionEntity";

import alert from "Util/ToastifyUtils";
import {AxialLoadsData, Cell, ElementStructure, ElementStructureUtils} from "Util/ElementStructureUtils";
import ProjectLockoutHelper from "Util/ProjectLockoutHelper";

import {Button, Colors, Display} from "Views/Components/Button/Button";
import {TextField} from "Views/Components/TextBox/TextBox";
import Modal from "Views/Components/Modal/Modal";
import ElementGrid, {ElementGridFilter} from "Views/Components/ElementGrid/ElementGrid";
import {LoadView, ProgressBarState} from 'Views/Components/LoadView/LoadView';
import SummaryView from "Views/Components/ElementGrid/SummaryView";
import ScheduleOfRates from "Views/Components/PricingComponents/ScheduleOfRates";
import ShuttersTable from "Views/Components/PricingComponents/ShuttersTable";
import CostSummary from "Views/Components/PricingComponents/CostSummary";
import {Combobox} from "Views/Components/Combobox/Combobox";

import {projectEntityFetch} from "Views/Tiles/ProjectTileUtils";
import ProjectTileTempWorksOverlay from "Views/Tiles/ProjectTileTempWorksOverlay";

export enum ProjectTableState {
	Elements = "elements",
	DetailedSchedule = "schedule",
	TemporaryWorks = "tempworks",
	Shutters = "shutters",
	CostSummary = "costsummary",
}

export interface ProjectTileProps extends RouteComponentProps {
	projectId: string;
	showTable?: ProjectTableState;
}

@observer
export default class ProjectTile extends React.Component<ProjectTileProps> {

	@observable
	public project: ProjectEntity;

	@observable
	private requestState: 'loading' | 'error' | 'done' = 'loading';

	private elementGrid: ElementGrid | null = null;

	@observable
	private progressBar = ProgressBarState.Hidden;

	@observable private elementGridFilter: ElementGridFilter = ElementGridFilter.Default;

	@observable public lastChangedName = "";

	private revisionList: { name: string; created: string | undefined }[] = [];

	@observable
	private lockoutHelper?: ProjectLockoutHelper.Single;
	
	@observable
	private cellCount: {
		cellTotal: number;
		cellAptus?: number;
		cellNonAptus?: number;
		cellCustom?: number;
		cellInsitu?: number;
	} = { 
		cellTotal: 0,
		cellAptus: 0,
		cellNonAptus: 0,
		cellCustom: 0,
		cellInsitu: 0,
	}

	@observable
	private countSelected: boolean = false;

	constructor(props: ProjectTileProps) {
		super(props);
		runInAction(() => {
			this.lockoutHelper = new ProjectLockoutHelper.Single(this.props.projectId)
		});

		autorun(() => {
			if (this.lockoutHelper?.canEdit) {
				this.fetchLastChangedName(this.props.projectId);
			}
		});
	}

	public componentDidMount(): void {
		projectEntityFetch(this.props.projectId)
			.then(d => {
				this.changeRequestState('done', d[0]);
				if (this.props.showTable) {
					this.changeTableState(this.props.showTable, false);
				}
			})
			.catch(e => {
				this.changeRequestState('error');
			});
	}

	componentWillUnmount() {
		runInAction(() => {
			if (this.lockoutHelper) {
				this.lockoutHelper!.cancel();
			}
		});
	}

	@action
	public changeRequestState = (state: 'loading' | 'error' | 'done', project?: ProjectEntity) => {
		if (state === 'error') {
			this.returnToDashboard();
			return;
		}

		if (project) {
			this.project = project;

			// Check if there are any warnings in the table and set the value of WarningsExist
			this.checkIfWarningsExist();
			// Count the cells
			this.countCells();

			// Get the name of the person who created this project, so we can pass it down to the summary view
			this.fetchLastChangedName(project.id)
			this.revisionList = this.getRevisionList(this.project);
		}
		this.requestState = state;
	};

	@action
	public fetchLastChangedName = (projectId: string) => {
		axios.get(`${SERVER_URL}/api/entity/ProjectEntity/last-changed/${projectId}`)
			.then(({data}) => runInAction(() => {
				this.lastChangedName = data;
			}));
	};

	public countCells () {
		const cells: {
			totalCells: Cell[],
			aptusCells: number,
			nonAptusCells: number,
			insituCells: number,
		} = {
			totalCells: [],
			aptusCells: 0,
			nonAptusCells: 0,
			insituCells: 0,
		};

		if(this.project) {
			runInAction(() => {
				this.countSelected = this.elementGrid
					? this.elementGrid.selectionUtils.selectedCells.length > 1 // only show if 2 or more selected
					: false;
			});
			
			const parsedElementStructure = this.project.parsedElementStructure;

			ElementStructureUtils.cellIterator(parsedElementStructure, (columnType, column, level) => {
				const currentCell = parsedElementStructure.cells[column.id][level.id];
				if (!this.countSelected) {
					// Count merged cells as 1 cell, and specifically don't count deleted cells
					if (currentCell.merged && !currentCell.deleted) {
						const mergedCell = ElementStructureUtils.findCellGivenCellIsMergedInto(parsedElementStructure, currentCell);

						if (mergedCell && cells.totalCells.find(x => x.id === mergedCell.id)) {
							cells.totalCells.push(mergedCell);

							if(currentCell.aptusDesignConfiguration === "aptus" || currentCell.aptusDesignConfiguration === "custom" ) cells.aptusCells++;
							else if( currentCell.aptusDesignConfiguration === "nonaptus" && !currentCell.insituElement) cells.nonAptusCells++;
							else if( currentCell.aptusDesignConfiguration === "nonaptus" && currentCell.insituElement ) cells.insituCells++;
						}
					} else if (!currentCell.deleted) {
						// Counting All Cells
						cells.totalCells.push(currentCell);

						if(currentCell.aptusDesignConfiguration === "aptus" || currentCell.aptusDesignConfiguration === "custom" ) cells.aptusCells++;
						else if( currentCell.aptusDesignConfiguration === "nonaptus" && !currentCell.insituElement) cells.nonAptusCells++;
						else if( currentCell.aptusDesignConfiguration === "nonaptus" && currentCell.insituElement ) cells.insituCells++;
					}
				} else if (this.elementGrid?.selectionUtils.selectedCellsIncludesCell(currentCell)) {
					// Counting Selected Cells
					runInAction(() => {
						this.totalCellCountVisible = false;
					});

					if(currentCell.aptusDesignConfiguration === "aptus" || currentCell.aptusDesignConfiguration === "custom" ) cells.aptusCells++;
					else if( currentCell.aptusDesignConfiguration === "nonaptus" && !currentCell.insituElement ) cells.nonAptusCells++;
					else if( currentCell.aptusDesignConfiguration === "nonaptus" && currentCell.insituElement ) cells.insituCells++;

					cells.totalCells.push(currentCell);
				}
			});

			runInAction(() => {
				this.cellCount.cellTotal = cells.totalCells.length;
				this.cellCount.cellAptus = cells.aptusCells;
				this.cellCount.cellNonAptus = cells.nonAptusCells;
				this.cellCount.cellInsitu = cells.insituCells;
			});
		} else {
			runInAction(() => {
				this.cellCount.cellTotal = -1;
			})
		}
	}
	@observable
	public totalCellCountVisible = false;
	@action
	public toggleTotalCells(){
		this.totalCellCountVisible = !this.totalCellCountVisible;
	}

	private returnToDashboard = () => {
		store.routerHistory.push('/dashboard');
		alert('Unable to find project. Please try again, or contact us for help.', 'error');
	};

	@observable
	protected tableState: ProjectTableState = ProjectTableState.Elements;

	@observable
	private modalState = {
		visible: false,
		revisionName: "",
		error: "",
	};

	private loadView: LoadView | null = null;

	@observable
	private ignoreWarnings: boolean = false;

	@observable
	private warningsExist: boolean = false;

	@observable
	private loadViewText: string;

	@observable
	public sidebarWidth = 300;

	@action
	public setSidebarWidth = (newWidth: number) => {
		this.sidebarWidth = newWidth;
	}

	public render() {
		return (
			<>
				<div className="project-page-content">
					<div className="project-sidebar">
						<SummaryView
							state={this.tableState}
							projectCanBeEdited={this.lockoutHelper?.canEdit}
							currentlyEditedBy={this.lockoutHelper?.editingUserName}
							project={this.project}
							lastChangedName={this.lastChangedName}
							importAxialLoads={this.importAxialLoads}
							afterChange={this.project && this.checkProjectCanBeEditedThenSave}
							revisionList={this.revisionList}
						/>
					</div>
					<div id="main-content" className="main-content" style={{marginLeft: `${this.sidebarWidth-300}px`}}>
						<div className="project-page-buttons">
							<span className="build-wrap">
								<Button
									onClick={this.rebuild}
									className={classNames("rebuild-project-btn", this.project && this.project.parsedElementStructure.info.editedSinceLastBuild ? 'edited' : null)}
									display={Display.Solid}
									colors={Colors.Secondary}
									disabled={!this.lockoutHelper?.canEdit}>
										Rebuild Project
								</Button>
								{this.project && this.project.parsedElementStructure.info.editedSinceLastBuild
									? <p className="project-edited-note">Update Aptus components & pricing</p>
									: null}
							</span>
							{this.warningsExist ?
								<Button onClick={this.toggleIgnoreWarning} className="disable-warning-btn"
										display={Display.Solid}
										colors={Colors.Secondary}>Warnings: {this.ignoreWarnings ? "Off" : "On"}</Button> : null}
							{this.tableState === ProjectTableState.Elements ?
								(
									<>
									<div className="cell-count-dropdown">
										<Button 
											className="btn--outline btn--secondary cell-count-button" 
											onClick={()=>this.toggleTotalCells()}>
											{this.cellCount.cellTotal >= 0
												? `${this.countSelected ? 'Selected Cells: ' : 'Job Total '} ${this.cellCount.cellTotal}`
												: `Not Available`}
										</Button>
										{this.totalCellCountVisible? 
											
											<div className="cell-count-dropdown-content">
												<span className="count-text">{this.countSelected ? 'Selected Cells:' : 'Job Total'}
													<b className="count-number">{this.cellCount.cellTotal}</b>
												</span>
												<span className="count-text">Aptus
													<b className="count-number">{this.cellCount.cellAptus}</b>
												</span>
												<span className="count-text">Non-Aptus
													<b className="count-number">{this.cellCount.cellNonAptus}</b>
												</span>
												<span className="count-text">In-situ
													<b className="count-number">{this.cellCount.cellInsitu}</b>
												</span>
											</div>
										: null}
									</div>
									</>
								) 
								: null
							}
							<Button onClick={() => runInAction(() => this.modalState.visible = true)}
									className="save-revision-btn" display={Display.Solid} colors={Colors.Secondary} disabled={!this.lockoutHelper?.canEdit}>Save
								as New Revision</Button>
							<Button onClick={this.closeProject} className="save-close-btn" display={Display.Solid}
									colors={Colors.Secondary} disabled={!this.lockoutHelper?.canEdit}>Close</Button>
						</div>
						{/* Doing tabs manually, because the provided tabs were sluggish */}
						<div className="outer-tab-wrap">
							<div className="tab-wrap">
								<button onClick={() => this.changeTableState(ProjectTableState.Elements)}
										className={`tab ${this.tableState === ProjectTableState.Elements ? 'active' : ''}`}>Elements
									Table
								</button>
								<button onClick={() => this.changeTableState(ProjectTableState.TemporaryWorks)}
										className={`tab ${this.tableState === ProjectTableState.TemporaryWorks ? 'active' : ''}`}>Temporary
									Works
								</button>
								<button onClick={() => this.changeTableState(ProjectTableState.DetailedSchedule)}
										className={`tab ${this.tableState === ProjectTableState.DetailedSchedule ? 'active' : ''}`}>Detailed
									Schedule
								</button>
								<button onClick={() => this.changeTableState(ProjectTableState.Shutters)}
										className={`tab ${this.tableState === ProjectTableState.Shutters ? 'active' : ''}`}>Shutters
								</button>
								<button onClick={() => this.changeTableState(ProjectTableState.CostSummary)}
										className={`tab ${this.tableState === ProjectTableState.CostSummary ? 'active' : ''}`}>Cost
									Summary
								</button>

								{this.tableState === ProjectTableState.Elements
									? <Combobox
										className="element-grid-filter"
										model={this}
										modelProperty="elementGridFilter"
										label=""
										searchable={false}
										options={[
											{ display: 'Details View', value: ElementGridFilter.Default },
											{ display: 'Concrete strength', value: ElementGridFilter.ConcreteStrength },
											{ display: 'Bar configuration', value: ElementGridFilter.BarConfiguration },
											{ display: 'Load Transfers', value: ElementGridFilter.LoadTransfer },
										]}/>
									: null}
							</div>
						{!this.lockoutHelper?.canEdit && this.lockoutHelper?.shouldShowEditingUser
							? <div className={"project-editing-not-allowed-warning"}>
								<h6>
									{this.lockoutHelper?.editingUserName} is editing this project. Your changes will not be saved!
								</h6>
							</div>
							: null}
						{this.lockoutHelper?.canEdit && this.lockoutHelper?.shouldShowCanEditBanner
						? (
							<div className="project-editing-not-allowed-warning green">
								<h6>
									{this.lockoutHelper.editingUserName} has left the project. You can now edit the project
								</h6>
								<Icon className="dismiss-btn" onClick={() => this.lockoutHelper?.dismissCanEditBanner()} name="x"/>
							</div>
						) : null}
						</div>
						<div className="tab-content">
							{this.tableState === ProjectTableState.Elements ? this.renderElementsTable() : null}
							{this.tableState === ProjectTableState.TemporaryWorks ? this.renderTemporaryWorksTable() : null}
							{this.tableState === ProjectTableState.DetailedSchedule ? this.renderScheduleTable() : null}
							{this.tableState === ProjectTableState.Shutters ? this.renderShuttersTable() : null}
							{this.tableState === ProjectTableState.CostSummary ? this.renderCostSummaryTable() : null}
						</div>
						{this.progressBar !== ProgressBarState.Hidden
							? <LoadView text={this.loadViewText}
										estimate={ElementStructureUtils.getBuildTimeEstimate(this.project)}
										completed={this.progressBar} ref={ref => this.loadView = ref}/>
							: null}
					</div>
					{
						this.project && this.tableState === ProjectTableState.TemporaryWorks ? 
						( <ProjectTileTempWorksOverlay project={this.project} tableState={this.tableState}/>
						) : null
					}
				</div>

				<Modal isOpen={this.modalState.visible} label="An example modal!" onRequestClose={this.closeModal}>
					<h4 key="header" className="modal__header">Save as New Revision</h4>
					<h5>Please choose a name for your new revision.</h5>
					<TextField
						className={"modal__textarea"}
						model={this.modalState}
						modelProperty="revisionName"
						label="Revision name"
						errors={this.modalState.error ? this.modalState.error : undefined}/>
					{this.revisionList.length !== 0 ?
						<div className="revision-list-table">
							<div className="revision-list-item">
								<div className="list-item-wrap header">
									<div className="previous-revisions">Previous Revisions</div>
									<div className="created">Created</div>
								</div>
							</div>
							{this.revisionList
								.map(revision => {
									return (
										<div className={classNames("revision-list-item")} key={revision.name}>
											<div className="list-item-wrap">
												<div className="previous-revisions">{revision.name}</div>
												<div className="created">{revision.created}</div>
											</div>
										</div>
									)
								})}
						</div> : null}
					<div key="actions" className="modal__actions">
						<Button onClick={this.closeModal} display={Display.Solid}
								colors={Colors.Secondary}>Cancel</Button>
						<Button onClick={this.saveAsNewRevision} display={Display.Solid}
								colors={Colors.Primary}>Save</Button>
					</div>
				</Modal>
			</>
		);
	}

	@action
	private toggleIgnoreWarning = () => {
		if(!this.ignoreWarnings) {
			this.project.parsedElementStructure.columnTypes.forEach(x =>
				x.columns.forEach(column =>
					this.project.parsedElementStructure.levels.forEach(level => {
						const currentCell = this.project.parsedElementStructure.cells[column.id][level.id];
						currentCell.hideWarning = false;
					})
				)
			)
		}
		this.ignoreWarnings = !this.ignoreWarnings;
	}

	private checkProjectCanBeEditedThenSave = () => {
		if (this.lockoutHelper?.canEdit) {
			this.project.saveProject();
		} else {
			alert(`${this.lockoutHelper?.editingUserName} is editing this project. Your changes can not be saved!`, 'error')
		}
	}

	@action
	public checkIfWarningsExist = () => {
		this.warningsExist = false;
		for (let type of this.project.parsedElementStructure.columnTypes) {
			for (let column of type.columns) {
				for (let level of this.project.parsedElementStructure.levels) {
					const errors = this.project.parsedElementStructure.cells[column.id][level.id].errors;
					const warnings = this.project.parsedElementStructure.cells[column.id][level.id].warnings;
					if ((errors && errors.length) || (warnings && warnings.length)) {
						this.warningsExist = true;
						return;
					}
				}
			}
		}
	}

	@action
	private rebuild = async () => {
		// deep copy element structure before any later changes
		ElementStructureUtils.cleanCells(this.project.parsedElementStructure);
		let previousElementStructure: ElementStructure = JSON.parse(JSON.stringify(this.project.parsedElementStructure));

		this.setSidebarWidth(300);

		// Reset the Cell count to Total Cells
		this.elementGrid?.selectionUtils.resetSelection();
		this.countCells();

		
		runInAction(() => {
			this.loadViewText = "Rebuilding Aptus Design";
			this.progressBar = ProgressBarState.Loading;
		});
		
		if (this.project) {
			// If the rebuild deletes any shutters with custom quantities, we want to give the user an alert
			// We build a list of such shutters, and check if after
			const shuttersWithUpdatedQuantities: { [id: string]: number } = {};
			if (this.project.parsedElementStructure.shutters) {
				this.project.parsedElementStructure.shutters.forEach(shutter => {
					if (shutter.quantity > 1 || (shutter.siteTemplateQuantity && shutter.siteTemplateQuantity > 0)) {
						shuttersWithUpdatedQuantities[ElementStructureUtils.shutterTypeString(shutter)] = shutter.quantity;
					}
				})
			}
			try {
				// build project without cleaning structure - we did this before the deep copy we made for diffing changed cells later
				await this.project.buildProject(false);
			} catch (e) {
				console.error(e);
				alert("There was an error with your build or you are disconnected from the server. Please contact us if the problem continues", "error");
				this.hideProgressBar(false);
			}

			// If any shutters with updated quantities were deleted, we send a notification
			if (this.project.parsedElementStructure.shutters) {
				this.project.parsedElementStructure.shutters.forEach(shutter => {
					const shutterName = ElementStructureUtils.shutterTypeString(shutter);
					if (shutterName in shuttersWithUpdatedQuantities) {
						delete shuttersWithUpdatedQuantities[shutterName];
					}
				})
			}

			if (Object.keys(shuttersWithUpdatedQuantities).length > 0) {
				alert(`Some shutters are no longer needed, and were removed from your project. Please check your shutter configuration to make sure it is still correct.`, 'warning');
			}

			// After we finish building a project, we update the edit views
			if (this.elementGrid) {
				this.elementGrid.selectionUtils.resetSelection();
			}

			// We've finished the build. We check if there were any errors. If there were, we show a notification to the user
			let errorCount = 0;
			let warningCount = 0;

			const elementStructure = this.project.parsedElementStructure;

			const changedCellIds: string[] = [];
			ElementStructureUtils.cellIterator(elementStructure, (columnType, column, level) => {
				const cell = elementStructure.cells[column.id][level.id];
				if (
					(cell.warnings || cell.errors) 
					&& !cell.merged
					&& !cell.deleted
				) {
					runInAction(() => cell.hideWarning = false);
					if (cell.errors && cell.errors.length > 0) errorCount += 1;
					if (cell.warnings && cell.warnings.length > 0) warningCount += 1;
				}

				const previousCell = previousElementStructure.cells[column.id][level.id];

				if (JSON.stringify(previousCell) !== JSON.stringify(cell)) {
					changedCellIds.push(cell.id);
				}
			});
			
			// Check whether there are any warnings after each build -> whether to show the "toggle warnings" button or not.
			this.checkIfWarningsExist();
			
			runInAction(() => {
				// update list of changed cell ids
				ElementStructureUtils.cellChangedIdList = changedCellIds;

				// Turn on warning after a rebuild by default
				this.ignoreWarnings = false
			});
			
			// We've finished building project, so we want to fade out and then hide the progress bar
			this.hideProgressBar();

			if (errorCount > 0) {
				alert(`${errorCount} elements contained errors`, 'error');
			}
			if (warningCount > 0) {
				alert(`${warningCount} elements contained warnings`, 'warning');
			}
		}
	};

	private importAxialLoads = async (axialLoads: AxialLoadsData[]) => {
		if (this.project) {
			const elementStructure = this.project.parsedElementStructure;

			const cellChangedCount = ElementStructureUtils.updateAxialLoads(elementStructure, axialLoads);

			// Alert the user
			alert(`${cellChangedCount} element${cellChangedCount > 1 ? "s have" : " has"} been updated.`, "success");

			// After we finish building a project, we update the edit views
			if (this.elementGrid) {
				this.elementGrid.selectionUtils.resetSelection();
			}

			this.project.saveProject(true);
		}
	};

	@action hideProgressBar = (complete = true) => {
		if (!complete) {
			this.progressBar = ProgressBarState.Hidden
		} else {
			this.progressBar = ProgressBarState.Complete;
			if (this.loadView) {
				this.loadView.updateStyle(100);
			}

			setTimeout(() => runInAction(() => {
				this.progressBar = ProgressBarState.Hidden
			}), 500);
		}
	};

	private closeProject = () => {
		// Set editing user to undefined when project is closed
		this.project.save().catch(error => alert(`You’re disconnected from the server – Please check your connection and log in again.`, "error"));
		store.routerHistory.push(`/dashboard`);
	};

	@action private saveAsNewRevision = async () => {
		// build before saving any revision
		if (this.project && this.project.parsedElementStructure.info.editedSinceLastBuild) {
			this.loadViewText = "Saving as new revision";
			this.modalState.visible = false;
			runInAction(() => {
				this.progressBar = ProgressBarState.Loading;
			});
			await this.project.buildProject();
			runInAction(() => {
				this.progressBar = ProgressBarState.Complete;
				if (this.loadView) {
					this.loadView.updateStyle(100);
				}
				setTimeout(() => runInAction(() => {
					this.progressBar = ProgressBarState.Hidden
				}), 500);
			});
		}

		// We can only save if the revision name is set
		if (!this.modalState.revisionName) {
			runInAction(() => this.modalState.error = "Please choose a name for your new revision.");
			return;
		}

		if (this.revisionList.some(revision => revision.name === this.modalState.revisionName)) {
			this.modalState.error = "Revision name already taken.";
			return;
		}

		// Create a new revision from the current element structure
		const newRevision = new ProjectRevisionEntity({
			name: this.modalState.revisionName,
			elementStructure: JSON.stringify(this.project.parsedElementStructure),
			notes: this.project.notes,
			projectId: this.project.id,
			project: this.project,
			priceVersionId: this.project.priceVersionId,
		});
		newRevision.save()
			.then(() => {
				this.revisionList.push({
					name: newRevision.name,
					created: moment(newRevision.created).format('hh:mmA DD/MM/YYYY'),
				});
				alert(`Successfully saved revision.`, 'success');
			});

		// We've finished saving the new revision, so we close it and clear the revision name
		this.closeModal();
	};

	@action private closeModal = () => {
		this.modalState = {
			visible: false,
			revisionName: "",
			error: "",
		};
	};

	@action public changeTableState = (newTableState: ProjectTableState, changeUrl: boolean = true) => {
		this.countCells();
		this.tableState = newTableState;
		if (changeUrl) {
			store.routerHistory.push(`/project/${this.project.id}/${this.tableState}`);
		}
	};

	private renderElementsTable = () => {
		// Show element grid if project is loaded
		if (this.requestState === 'loading') {
			return <></>;
		} else if (!this.project) {
			this.returnToDashboard();
			return <></>;
		} else {
			return <ElementGrid
				elementStructure={this.project.parsedElementStructure}
				readonly={!this.lockoutHelper?.canEdit}
				afterChange={this.checkProjectCanBeEditedThenSave}
				ref={ref => this.elementGrid = ref}
				filter={this.elementGridFilter}
				changedCellIds={ElementStructureUtils.cellChangedIdList}
				ignoreWarnings={this.ignoreWarnings}
				countCells={()=>this.countCells()}
				sideBarWidth={this.sidebarWidth}
				setSideBarWidth={this.setSidebarWidth}
			/>
		}
	};

	private renderScheduleTable = () => {
		// Show schedule of rates if project is loaded
		if (this.requestState === 'loading') {
			return <></>;
		} else if (!this.project) {
			this.returnToDashboard();
			return <></>;
		} else {
			return <ScheduleOfRates
				elementStructure={this.project.parsedElementStructure}
				afterChange={this.project.saveProject}
				tile={"ProjectTile"}
				readonly={!this.lockoutHelper?.canEdit}/>
		}
	};

	private renderTemporaryWorksTable = () => {
		//Show Element grid if Temporary Works is loaded
		if (!this.project) {
			return <></>;
		} else {
			return <ElementGrid
				elementStructure={this.project.parsedElementStructure}
				temporaryWorks={true}
				ignoreWarnings={this.ignoreWarnings}
				readonly={!this.lockoutHelper?.canEdit}
				setSideBarWidth={this.setSidebarWidth}
				sideBarWidth={this.sidebarWidth}
			/>
		}
	};

	private renderShuttersTable = () => {
		//Show Shutters if project is loaded
		if (!this.project) {
			return <></>;
		} else {
			return <ShuttersTable
				elementStructure={this.project.parsedElementStructure}
				afterChange={() => this.project.saveProject(false)}
				readonly={!this.lockoutHelper?.canEdit}/>
		}
	};

	private renderCostSummaryTable = () => {
		//Show Cost Summary if project is loaded
		if (!this.project) {
			return <></>;
		} else {
			return <CostSummary
				elementStructure={this.project.parsedElementStructure}
				onChangeExtraParts={() => this.project.saveProject(false)}
				quoteRevision={this.saveQuoteRevision}
				readonly={!this.lockoutHelper?.canEdit}
			/>
		}
	};

	private saveQuoteRevision = async () => {
		this.loadViewText = "Requesting a Confirmed Quote";
		runInAction(() => {
			this.progressBar = ProgressBarState.Loading;
		});

		try {
			await this.project.buildProject();
		} catch (e) {
			// Probably timed out.
			runInAction(() => {
				this.progressBar = ProgressBarState.Hidden;
			});
			return undefined;
		}

		// Create a new revision from the current element structure
		const newRevision = new ProjectRevisionEntity({
			name: `Quote_${this.getDateFormat()}`,
			elementStructure: JSON.stringify(this.project.parsedElementStructure),
			projectId: this.project.id,
			project: this.project,
		});


		try {
			await newRevision.save();
		} catch (e) {
			// Probably network connectivity issues, since a save usually doesn't fail
			runInAction(() => {
				this.progressBar = ProgressBarState.Hidden;
			});
			return undefined;
		}

		// We've finished building project, so we want to fade out and then hide the progress bar
		runInAction(() => {
			this.progressBar = ProgressBarState.Complete;
			if (this.loadView) {
				this.loadView.updateStyle(100);
			}
			setTimeout(() => runInAction(() => {
				this.progressBar = ProgressBarState.Hidden
			}), 500);
		});
		return newRevision;
	};

	private getDateFormat(): string {
		return moment(new Date()).format('DD-MM-YYYY_hh-mm-ss');
	}

	private getRevisionList = (project: ProjectEntity) => {
		const revisionList = project.projectRevisions.sort((a: ProjectRevisionEntity, b: ProjectRevisionEntity) => {
			return b.created.getTime() - a.created.getTime()
		}).map(revision => {
			const revisionOption: { name: string, created: string } = {
				name: revision.name,
				created: moment(revision.created).format('hh:mma DD/MM/YYYY')
			};
			return revisionOption;
		});
		return revisionList;
	};
}