/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';

// % protected region % [Add any extra imports here] on begin
import { action, observable } from 'mobx';
import { UserEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import { getAttributeComponent } from 'Views/Components/CRUD/Attributes/AttributeFactory';
import { isRequired } from 'Util/EntityUtils';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import alertToast from "Util/ToastifyUtils";
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { Button, Colors, Display, Sizes } from 'Views/Components/Button/Button';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
// % protected region % [Add any extra imports here] end

export interface ProfilePageProps extends RouteComponentProps {
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

@observer
// % protected region % [Add any customisations to default class definition here] off begin
class ProfilePage extends React.Component<ProfilePageProps> {
// % protected region % [Add any customisations to default class definition here] end

	// % protected region % [Add class properties here] on begin
	private userEntity: UserEntity;

	@observable
	private requestState: 'loading' | 'error' | 'done' = 'loading';

	public componentDidMount(): void {
		UserEntity.fetch<UserEntity>({args: [[{path: 'id', comparison: "equal", value: store.userId}]]})
			.then(d => {
				console.warn(d);
				this.changeRequestState('done', d[0]);
			})
			.catch(e => {
				this.changeRequestState('error');
			})
	}

	@action
	private changeRequestState = (state: 'loading' | 'error' | 'done', userEntity?: UserEntity) => {
		if (userEntity) {
			this.userEntity = userEntity;
		}
		this.requestState = state;
	};

	private renderProfilePage = () => {
		const entityAttrs = this.getUpdateFields();
		return (
			<div className="update-profile">
				<form onSubmit={this.onSubmitUpdateClicked}>
					<h1>Update Profile</h1>
					<div className="entity-attrs">
						{entityAttrs}
					</div>
					<ButtonGroup alignment={Alignment.HORIZONTAL} className="register-buttons">
						<Button type='submit' className="submit-update-profile" display={Display.Solid} colors={Colors.Primary} sizes={Sizes.Medium}>Update</Button>
						<Button className="btn-reset-password" onClick={this.resetPassword} display={Display.Solid} colors={Colors.Secondary} sizes={Sizes.Medium}>Reset Password</Button>
					</ButtonGroup>
				</form>
			</div>
		);
	};

	private getUpdateFields = () => {
		// Used to show a custom list of fields, and to set the order they display in
		const registrationFieldList: string[] = [
			'firstName',
			'lastName',
			'phone',
		];

		let attributeOptions = this.userEntity.getAttributeCRUDOptions();
		const model = this.userEntity;
		return attributeOptions
			.filter(attributeOption =>
				registrationFieldList.indexOf(attributeOption.attributeName) >= 0)
			.sort((a, b) =>
				registrationFieldList.indexOf(a.attributeName) - registrationFieldList.indexOf(b.attributeName))
			.map(attributeOption =>
				getAttributeComponent(
					attributeOption,
					model,
					model.getErrorsForAttribute(attributeOption.attributeName),
					EntityFormMode.EDIT,
					isRequired(model, attributeOption.attributeName),
					undefined,
					model ? model.validate.bind(model) : undefined
				));
	};

	@action
	private onSubmitUpdateClicked = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (this.userEntity) {
			await this.userEntity.validate();
			if (this.userEntity.hasValidationError) {
				return;
			}
			this.userEntity.save().then(() => {
				alertToast(`Your profile has been saved`, 'success');
			});
		}
	};

	private resetPassword = async () => {
		try {
			const result = await axios.post(`${SERVER_URL}/api/account/reset-password-logged-in`);
			store.routerHistory.push(`/reset-password?token=${encodeURIComponent(result.data)}&username=${store.email}`);
			// alert(`Result is: ${result.data}`, 'success');
		} catch (e) {
			alertToast(`Unable to change your password. Please refresh the page and try again.`, 'error');
		}
	};
	// % protected region % [Add class properties here] end

	render() {
		// % protected region % [Add logic before rendering contents here] off begin
		// % protected region % [Add logic before rendering contents here] end

		let contents = (
			<SecuredPage groups={['Super Administrators', 'User', 'Admin']}>
				{
				// % protected region % [Alter navigation here] off begin
				}
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
				/>
				{
				// % protected region % [Alter navigation here] end
				}
				<div className="body-content">
				</div>
			</SecuredPage>
		);

		// % protected region % [Override contents here] on begin
		let innerContents = <></>;
		if (this.requestState === 'loading') {
			innerContents = <div></div>;
		} else if (this.requestState === 'error' || !this.userEntity) {
			innerContents = (
				<div className="update-profile">
					<h3>There has been an error</h3>
					<p>Unable to load the profile page at this time. Please contact us if the problem persists.</p>
				</div>
			);
		} else {
			innerContents = this.renderProfilePage();
		}

		contents = (
			<SecuredPage groups={['Super Administrators', 'Admin', 'User']}>
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
				/>
				<div className="body-content">
					{innerContents}
				</div>
			</SecuredPage>
		);
		// % protected region % [Override contents here] end

		return contents;
	}
}

// % protected region % [Override export here] off begin
export default ProfilePage;
// % protected region % [Override export here] end
