import { observable, runInAction, action } from 'mobx';
import axios from 'axios';
import alert from 'Util/ToastifyUtils';

// milliseconds
const pollRate = 10000;

/**
	This class is to manage long polling for whether the user has project edit access or if someone else
	is editing the projecta
 */

namespace ProjectLockoutHelper {
	export class Single {
		// Project Id of project currently being polled
		@observable
		readonly projectId: string;
		
		// Whether the current project be edited by the logged in user
		@observable
		public canEdit: boolean = false;
	
		// toggle this for first load so canEdit can be false by default,
		// but we don't flash a big blue banner until we've checked with the server
		@observable
		public shouldShowEditingUser: boolean = false;

		// some state for whether the green banner is visible
		// this only appears when a project transitions from uneditable to editable
		@observable
		public shouldShowCanEditBanner: boolean = false;
		
		// First and last name of user currently editing the project
		@observable
		public editingUserName: string = 'Someone';

		@observable
		public timeoutId: NodeJS.Timeout;

		@observable
		private cancelled: boolean = false;

		@action
		public cancel = () => {
			clearTimeout(this.timeoutId);
			this.cancelled = true;
		}

		@action
		public dismissCanEditBanner = () => {
			this.shouldShowCanEditBanner = false;
		}
	
		constructor(projectId: string) {
			this.projectId = projectId;
			this.pollSingleProjectLock();
		}
	
		public pollSingleProjectLock = () => {
			axios.get(`/api/entity/ProjectEntity/project-lock/${this.projectId}`)
				.then(({data}) => {
					runInAction(() => {
						// see if there's a timeout set - if so, ignore for first render
						if (this.canEdit === false && data.canEdit === true && this.timeoutId) {
							this.shouldShowCanEditBanner = true;
						}

						this.canEdit = data.canEdit ? true : false;
						this.editingUserName = data.editingUserName || this.editingUserName;
						if(!this.shouldShowEditingUser) this.shouldShowEditingUser = true;
						if (!this.cancelled) this.timeoutId = setTimeout(this.pollSingleProjectLock, pollRate);
					});
					if (data.error) alert(data.error, "error");
				})
				.catch((e) => {
					console.error(e);
					runInAction(() => {
						this.canEdit = false;
						this.editingUserName = 'Someone';
						this.shouldShowEditingUser = false;
						// but try again anyway in case network comes back online
						if (!this.cancelled) this.timeoutId = setTimeout(this.pollSingleProjectLock, pollRate);
					});
	
					alert('An unexpected error has occurred while trying to check edit access', 'error');
				});
		}
	}
	
	export class Multi {
		// Project Id of project currently being polled
		@observable
		readonly projectIds: string[];
	
		@observable
		public activeProjects: string[] = [];

		@observable
		public timeoutId: NodeJS.Timeout;

		@observable
		private cancelled: boolean = false;

		@action
		public cancel = () => {
			clearTimeout(this.timeoutId);
			this.cancelled = true;
		}
	
		constructor(projectIds: string[]) {
			this.projectIds = projectIds;
			this.pollAllProjectLock();
		}
	
		public pollAllProjectLock = () => {
			if (this.projectIds.length) {
				axios.post(
					`/api/entity/ProjectEntity/project-lock`,
					this.projectIds
				)
					.then(({data}) => {
						runInAction(() => {
							this.activeProjects = data.activeProjects;
							if (!this.cancelled) this.timeoutId = setTimeout(this.pollAllProjectLock, pollRate);
						});
						if (data.error) alert(data.error, "error");
					})
					.catch((e) => {
						console.error(e);
						alert('An unexpected error has occurred while trying to check project activity', 'error');
						// but keep trying
						runInAction(() => {
							if (!this.cancelled) this.timeoutId = setTimeout(this.pollAllProjectLock, pollRate)
						});
					});
			}
		}
	}
}

export default ProjectLockoutHelper;