import {Column} from "Util/ElementStructureUtils";
import * as React from 'react';
import classNames from "classnames";
import { observer } from 'mobx-react';

export interface IColumnViewProps {
	column: Column;
	columnTypeName: string;
	columnHighlighted: boolean;
	onClick: (event: React.MouseEvent) => void;
	clickAddColumn: (index: number) => void;
	columnIndex: number;
	readonly?: boolean;
	temporaryWorks?: boolean;
	onRightClick: (event: React.MouseEvent) => void;
}

@observer
export default class ColumnView extends React.Component<IColumnViewProps> {
	public render() {
		const { onRightClick, temporaryWorks } = this.props;
		let className: string[] = ["column",  !!temporaryWorks? "temp-works" : ""];
		if(this.props.columnHighlighted) {
			className.push("selected");
		}
		
		return (
			<>
				<div className={classNames(...className)} onContextMenu={onRightClick}>
					<div className="content" onClick={this.props.onClick}>{this.props.columnTypeName + this.props.column.name}</div>
				</div>
				{!this.props.readonly && !this.props.temporaryWorks ? this.renderAddColumnButtons() : null}
			</>
		);
	}
	
	private renderAddColumnButtons = () => {
		return (
			<>
				<div className="add-column before">
					<div className="add-btn" onClick={() => this.props.clickAddColumn(this.props.columnIndex)}>+</div>
				</div>
				<div className="add-column after">
					<div className="add-btn" onClick={() => this.props.clickAddColumn(this.props.columnIndex + 1)}>+</div>
				</div>
			</>
		);
	}
}