import * as React from 'react';
import { observer } from 'mobx-react';
import Chart from "chart.js";

export interface IInteractionCurveGraphProps {
	curve: number[][];
	load?: number;
	mult?: number;
}

@observer
export default class InteractionCurveGraph extends React.Component<IInteractionCurveGraphProps> {
	
	private readonly chartRef: any;
	private chart: Chart;
	
	constructor(props: IInteractionCurveGraphProps) {
		super(props);
		this.chartRef = React.createRef();
	}
	
	componentDidMount() {
		this.chart = new Chart(this.chartRef.current, {
			type: 'scatter',
			data: this.getData(),
			options: {
				legend: {
					display: false,
				},
				scales: {
					xAxes: [{
						type: 'linear',
						position: 'bottom',
					}]
				},
				animation: {
					duration: 0,
				}
			}
		});
	}
	
	public render() {
		
		if(this.chart) {
			this.updateChart();
		}
		
		return (
			<canvas className="interaction-curve" ref={this.chartRef} />
		);
	}
	
	private updateChart() {
		this.chart.data = this.getData();
		this.chart.update();
	}
	
	// Chart.js wants a specific format for its data, so we do that here
	private getData() {
		return {
			datasets: [{
				showLine: true,
				data: this.props.curve
					.sort((a, b) => {return a[0] - b[0]})
					.map(point => {
						return {
							x: point[1],
							y: point[0]
						};
					})
			},{
				data: [{
					x: this.props.mult ? this.props.mult : 0,
					y: this.props.load ? this.props.load : 0
				}],
				backgroundColor: "rgba(227, 58, 87, 0.8)" // Aptus red, slightly transparent
			}]
		};
	}
}