import * as React from 'react';

export default class EngineerApprovalCheck extends React.Component {
	public render() {
		// We're using a component, rather than an external SVG file, because it's easiest to change the colours of inline SVG
		return (
			<svg className="engineer-approval-check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
				<title>lightning-icons-6-export</title>
				<g id="a3b7355d-00a0-45c3-914b-3aa2c28f01c9" data-name="icons">
					<path d="M6.71,7.29A1,1,0,1,0,5.29,8.71l2,2A1,1,0,0,0,8,11h.07a1,1,0,0,0,.73-.4l6-8a1,1,0,1,0-1.6-1.2L7.89,8.48Z"/>
					<path d="M8,15a7,7,0,0,0,7-7,1,1,0,0,0-2,0A5,5,0,1,1,8,3,1,1,0,0,0,8,1,7,7,0,0,0,8,15Z"/>
				</g>
			</svg>
		);
	}
}