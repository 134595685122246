import {observer} from "mobx-react";
import WizardStep from "Views/Components/ProjectWizard/WizardStep";
import {action, observable, runInAction} from "mobx";
import {NumberTextField} from "Views/Components/NumberTextBox/NumberTextBox";
import {Button, Colors, Display} from "Views/Components/Button/Button";
import {ElementStructureUtils} from "Util/ElementStructureUtils";
import alert from "Util/ToastifyUtils";
import {confirmModal} from "Views/Components/Modal/ModalUtils";
import EditLevelsGrid from "Views/Components/ProjectWizard/EditLevelsGrid";
import { ProjectEntity } from "Models/Entities";

@observer
export default class WizardLevelsStep extends WizardStep {
	
	@observable
	private levelsAboveGround: number;

	@observable
	private levelsBelowGround: number;

	@observable
	private viewState: 'generateLevelsForm' | 'showLevelsForm' = 'generateLevelsForm';

	public componentDidMount(): void {
		if(this.props.project.parsedElementStructure.levels.length > 0) {
			this.changeState('showLevelsForm');

			// if our project already has some levels, we use those to automatically set the levels above and below variables
			this.setLevelsAboveBelow();
		}
	}
	
	@action
	changeState = (newState: 'generateLevelsForm' | 'showLevelsForm') => {
		this.viewState = newState;
	};
	
	@action
	private setLevelsAboveBelow = () => {
		const levelCount = this.props.project.parsedElementStructure.levels.length;
		for(let i = 0; i < levelCount; i++) {
			const currentLevel = this.props.project.parsedElementStructure.levels[i];
			if(currentLevel.groundLevel) {
				// We've found the ground level. We can use our current index to calculat the number of floors above and below
				this.levelsAboveGround = i;
				this.levelsBelowGround = levelCount - (i + 1);
				return;
			}
		}
	};
	
	public render() {
		return this.viewState === 'generateLevelsForm'
			? this.generateLevelsView()
			: this.showLevelsView();
	}
	
	private generateLevelsView = () => {
		const elementStructure = this.props.project.parsedElementStructure;
		return (
			<>
				<div className="form-section">
					<h3 className="section-title">Levels</h3>
					<NumberTextField
						className="levels-above-ground"
						model={this}
						modelProperty="levelsAboveGround"
						label="Number of levels above ground"
						placeholder="Levels above ground"
						onAfterChange={() => runInAction(() => ElementStructureUtils.cleanInt(this, "levelsAboveGround"))}
						tooltip="We will add this many levels above ground to your initial building design."
						isRequired={true} />
					<NumberTextField
						className="levels-below-ground"
						model={this}
						modelProperty="levelsBelowGround"
						label="Levels below ground"
						onAfterChange={() => runInAction(() => ElementStructureUtils.cleanInt(this, "levelsBelowGround"))}
						tooltip="We will add this many levels below ground to your initial building design."
						isRequired={true} />
					<NumberTextField
						className="typical-floor-height"
						model={elementStructure.info.levelDefaults}
						modelProperty="typicalFloorHeight"
						label="Typical floor to floor level (mm)"
						onAfterChange={() => runInAction(() => ElementStructureUtils.cleanInt(elementStructure.info.levelDefaults, "typicalFloorHeight"))}
						tooltip="When you generate the levels for your initial building design, all levels will be given this height. You can change individual levels later."
						isRequired={true} />
					<NumberTextField
						className="typical-slab-thickness"
						model={elementStructure.info.levelDefaults}
						modelProperty="typicalSlabThickness"
						label="Typical slab thickness (mm)"
						onAfterChange={() => runInAction(() => ElementStructureUtils.cleanInt(elementStructure.info.levelDefaults, "typicalSlabThickness"))}
						tooltip="When you generate the levels for your initial building design, all levels will be given this slab thickness. You can change individual levels later."
						isRequired={true} />
					<div className="generate-levels-bar">
						<Button onClick={this.generateLevels} className="generate-levels-btn" display={Display.Solid} colors={Colors.Primary}>Generate levels</Button>
					</div>
				</div>
			</>
		);
	};

	private showLevelsView = () => {
		return (
			<>
				<div className="form-section header-section">
					<div className="header-text">
						<h3 className="section-title">Generated Levels</h3>
						<p className="header-description">Now the levels have been generated, click on a row to edit it. You can also remove or add new levels.</p>
					</div>
					<div className="header-actions">
						<Button onClick={this.resetLevels} disabled={!this.props.projectCanBeEdited} className="reset-levels-btn" display={Display.Solid} colors={Colors.Primary} icon={{ icon: "reset", iconPos: 'icon-left' }}>Reset levels</Button>
					</div>
				</div>
				<div className="form-section">
					<EditLevelsGrid project={this.props.project} projectCanBeEdited={this.props.projectCanBeEdited}/>
				</div>
			</>
		);
	};
	
	@action private generateLevels = () => {
		if(!ElementStructureUtils.generateLevels(this.props.project.parsedElementStructure, this.levelsAboveGround, this.levelsBelowGround)) {
			alert('Please fill out the fields above.', 'error');
			return;
		}
		
		// Save the project with the new levels
		if (this.props.projectCanBeEdited){
			this.props.project.saveProject();
		}
		
		// Change our state
		this.changeState('showLevelsForm');
	};
	
	private resetLevels = () => {
		confirmModal('Please confirm', "Are you sure you want to reset levels? This will delete all existing levels, and any cells on those levels.").then(()=>{
			// We just direct them page to the form. We don't actually delete the existing levels until the user generates new ones.
			this.changeState('generateLevelsForm');
		});
	};

	public static validateStep = (project: ProjectEntity): boolean => {
		const levelsFailedValidation = project.parsedElementStructure.levels
			.reduce((hasFailed: boolean, level) => {
				const [, , errorCode] = ElementStructureUtils
					.validateLevelSSL(project.parsedElementStructure, level.id, level.slabThicknessAtBase, level.ssl, level.topSsl);
				
				return hasFailed || !!errorCode;
			}, false);

		// Check that all the fields this step cares about are set
		// If any aren't, we return false.
		// Double exclamation marks are there to satisfy typescript
		return !!(project.parsedElementStructure.info.levelDefaults.typicalFloorHeight && project.parsedElementStructure.info.levelDefaults.typicalSlabThickness)
			&& project.parsedElementStructure.levels.length > 0
			&& !levelsFailedValidation;
	};
}