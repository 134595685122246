import * as React from 'react';
import {observer} from 'mobx-react';
import {action, computed, observable, reaction, runInAction} from "mobx";
import ProjectEntity from "Models/Entities/ProjectEntity";
import ProjectRevisionEntity from "Models/Entities/ProjectRevisionEntity";
import {store} from "Models/Store";
import {Button, Colors, Display} from "Views/Components/Button/Button";
import classNames from "classnames";
import {Combobox} from "Views/Components/Combobox/Combobox";
import {ProjectTableState} from "Views/Tiles/ProjectTile";
import {confirmModal} from "Views/Components/Modal/ModalUtils";
import axios from 'axios';
import UserEntity from "Models/Entities/UserEntity";
import {gql} from '@apollo/client';
import moment from "moment";
import smartlook from 'smartlook-client';
import DashboardProjectTable from "./DashboardProjectTable";
import { ShareProjectModal, DuplicateProjectModal } from "./DashboardModals";
import ProjectLockoutHelper from "Util/ProjectLockoutHelper";
import alertToast from "../../../Util/ToastifyUtils";

export interface ProjectShare {
	email?: string,
	organisationName?: string,
	pendingConnectionId?: string,
	sharedOrganisationId?: string,
	organisationId?: string,
	status: ProjectShareStatus,
	created: string,
}
export enum ProjectShareStatus {
	OwnsProject = "Owns project",
	Granted = "Access granted",
	Pending = "Pending",
	Sending = "Sending...",
	Removing = "Removing...",
	Failed = "Failed",
}

export interface DuplicateModalState {
	visible: boolean,
	duplicateName: string,
	jobNumber: string,
	nameError: string,
	jobError: string,
}

const query = gql`
	query getUser($path: String, $comparison: Comparison, $value: [String]) {
		userEntitys(where: {path: $path, comparison: $comparison, value: $value}) {
			id
			organisationId
			role
		}
	}
`;

@observer
export default class Dashboard extends React.Component<{ folderName?: string }> {
	@observable public folderCount?: number = undefined;
	@observable public projectFolderList: string[] = [];

	public get selectedFolder() {
		return this.props.folderName;
	}

	@observable public pageListTotal = 0;
	
	@observable public projectList: ProjectEntity[] = [];
	@observable public selectedProject?: ProjectEntity;
	@observable public selectedProjectShares?: ProjectShare[];
	@observable public selectedRevision?: ProjectRevisionEntity = undefined;
	
	@observable public revisionOption : {display: string, value: ProjectRevisionEntity|undefined}[] = [];
	@observable searchTerm: string = "";

	@observable
	public projectRequestState: 'loading' | 'error' | 'done' = 'loading';

	@action
	public changeRequestState(state: 'loading' | 'error' | 'done') {
		this.projectRequestState = state;
	};
	
	@computed get urlRoot() {
		if(this.selectedRevision) {
			return `/projectrevision/${this.selectedRevision.id}`;
		} else if(this.selectedProject) {
			return `/project/${this.selectedProject.id}`;
		} else {
			return ``;
		}
	}
	
	@observable projectDetailsVisibleMobile: boolean = false;

	@observable
	private duplicateModalState: DuplicateModalState = {
		visible: false,
		duplicateName: "",
		jobNumber: "",
		nameError: "",
		jobError: ""
	};

	@observable
	private shareModalState = {
		visible: false,
		inviteEmails: [],
	};

	@observable
	private lockoutHelper: ProjectLockoutHelper.Multi;
	
	private currentUser: UserEntity;

	public componentDidMount(): void {
		ProjectEntity.fetchFolderCount()
			.then(results =>{
				runInAction(() => this.folderCount = results);
			});
		this.loadCurrentUser();

		reaction(() => this.projectList, () => {
			if (this.lockoutHelper) this.lockoutHelper.cancel();
			this.lockoutHelper = new ProjectLockoutHelper.Multi(this.projectList.map(x => x.id))
		});
	}

	private async loadCurrentUser() {
		store.apolloClient.query({
			query: query,
			variables: {
				path: 'id',
				comparison: 'equal',
				value: store.userId,
			}
		})
			.then((res) => {
				if (res.data.userEntitys.length >= 1){
					this.currentUser = res.data.userEntitys[0];
				} else {
					console.error("Unable to find current user");
				}
			})
			.catch(e => {
				console.error("Unable to find current user");
			})
	}

	public componentWillUnmount() {
		if (this.lockoutHelper) {
			this.lockoutHelper.cancel();
		}
	}

	public render() {
		return this.folderCount
			? this.renderProjectList()
			: this.renderEmptyDashboard();
	};

	private renderEmptyDashboard = () => {
		return (
			<div className="empty-dashboard">
				<div className="empty-dashboard-content">
					<h1>Welcome to the Aptus design tool.</h1>
					<p>Use this tool to create and calculate a preliminary Aptus design system.</p>
					<p>This tool is a starting point for design development, and an engineer will always need to be involved in the approval of a final design.</p>
					<p>Get started by creating your first project:</p>
					<Button onClick={this.newProject} className="new-project-btn" display={Display.Solid} colors={Colors.Primary} icon={{ icon: "plus", iconPos: 'icon-left' }}>New project</Button>
				</div>
				<div className="spacing-div"/>
				<div className="empty-dashboard-background"/>
			</div>
		)
	};
	
	@observable public isActive: boolean = true;
	
	private renderProjectList() {
		return (
			<>
				<div className={classNames("dashboard", this.projectDetailsVisibleMobile ? 'details-visible-mobile' : null)}>
					<div className="project-selection-section">
					<DashboardProjectTable
						cleanRedirect={this.cleanRedirect}
						projectList={this.projectList}
						selectProject={this.selectProject}
						truncateString={this.truncateString}
						urlRoot={this.urlRoot} 
						newProject={this.newProject}
						resetSelectedProject={this.resetSelectedProject}
						selectedProject={this.selectedProject}
						setProjectList={this.setProjectList}
						pageListTotal={this.pageListTotal}
						projectFolderList={this.projectFolderList}
						getPaginatedFolders={this.getPaginatedFolders}
						getPaginatedProjects={this.getPaginatedProjects}
						selectedFolder={this.selectedFolder}
						setSelectedFolder={this.setSelectedFolder}
						searchTerm={this.searchTerm}
						setSearchTerm={this.setSearchTerm}
						activeProjectsList={this.lockoutHelper?.activeProjects || []}
					/>
					</div>
					<div className="project-details-section">
						{!this.selectedProject ? (
							<div className="no-project-selected">
								<div className="select-icon"/>
								<p>Select a project to view</p>
							</div>
							) : (
							<>
								<Button onClick={() => runInAction(() => this.projectDetailsVisibleMobile = false)} className="hide-details-mobile-btn" display={Display.Text} colors={Colors.Secondary} icon={{icon: 'arrow-left', iconPos: 'icon-left'}}>View Project List</Button>
								<div className="share-list">
									{this.selectedProject.getShareList().map(orgId =>
										<div className="organisation-image" key={orgId}>
											<img src={`/api/entity/OrganisationEntity/logo/${orgId}`} alt="organisation-logo" />
										</div>
									)}
								</div>
								<div className="project-top-bar">
									<p className="project-job-number">{this.selectedProject.jobNumber}</p>
								</div>
								<p className="project-name">{this.selectedProject.name}</p>
								{this.selectedProject.suburb ? <p className="project-suburb icon-location-pin icon-left">{this.selectedProject.suburb}</p> : null}
								<div className="dashboard__actions">
									{this.currentUser && this.selectedProject.organisationId === this.currentUser.organisationId
										? <Button onClick={this.activateShareModal} className="share-project-btn" display={Display.Solid} colors={Colors.Secondary}>Share</Button>
										: null}
									<Button onClick={this.activateDuplicateModal} className="duplicate-project-btn" display={Display.Solid} colors={Colors.Secondary}>Duplicate</Button>
									<Button onClick={()=>this.deleteProject()} className="delete-project-btn" display={Display.Solid} colors={Colors.Secondary} disabled={!(this.currentUser && (this.currentUser.role === "ADMIN" || this.currentUser.id === this.selectedProject?.projectOwnerId))}>Delete</Button>
								</div>
								{this.selectedProject.completedWizard
									? (
										<>
											<Combobox
												model={this}
												modelProperty="selectedRevision"
												label="View revision"
												getOptionValue={revision => revision ? revision.id : "empty"}
												isClearable={true}
												placeholder="No Revision Selected"
												options={this.revisionOption} />
											<a className="elements-table-btn btn btn--secondary btn--solid btn--icon icon-grid icon-left" href={`${this.urlRoot}/${ProjectTableState.Elements}`} onClick={e => this.cleanRedirect(e, `${this.urlRoot}/${ProjectTableState.Elements}`)}>Elements Table</a>
											{this.selectedRevision === undefined ? <a className="project-setup-btn btn btn--secondary btn--solid btn--icon icon-settings icon-left" href={`/projectwizard/${this.selectedProject.id}`} onClick={e => this.cleanRedirect(e, `/projectwizard/${this.selectedProject ? this.selectedProject.id : ""}`)}>Project Setup</a> : null}
										</>
									) : (
										<>
											<a className="continue-wizard-btn btn btn--secondary btn--solid btn--icon icon-grid icon-left" href={`/projectwizard/${this.selectedProject.id}`} onClick={e => this.cleanRedirect(e, `/projectwizard/${this.selectedProject ? this.selectedProject.id : ""}`)}>Continue Setup</a>
										</>
									)}		
							</>
						)}
					</div>
				</div>
				<DuplicateProjectModal 
					closeModal={this.closeModal}
					duplicateModalState={this.duplicateModalState}
					duplicateModal={this.duplicateModal}
				/>
				<ShareProjectModal
					shareModalState={this.shareModalState}
					closeModal={this.closeModal}
					selectedProjectShares={this.selectedProjectShares}
					sendInvites={this.sendInvites}
					removeAccess={this.removeAccess}				
				/>
			</>
		)
	};

	private cleanRedirect = (event: React.MouseEvent, path: string) => {
		if (!event.ctrlKey) {
			event.preventDefault();
			store.routerHistory.push(path)
		}
	};

	@action private selectProject = (project: ProjectEntity) => {
		this.projectDetailsVisibleMobile = true;
		this.selectedProject = project;
		this.selectedRevision = undefined;
		this.fetchRevisionList(this.selectedProject);
	};

	@action private newProject = async (e?: React.MouseEvent, currentFolder?: string) => {
		if(!this.currentUser) {
			await this.loadCurrentUser();
		}
		if(!this.currentUser.organisationId) {
			console.warn("Current user is not assigned to an organisation");
			return;
		}
		
		smartlook.track("Click new project", {});
		
		var folderParam = currentFolder ? encodeURIComponent(currentFolder) : undefined;
		store.routerHistory.push(`/projectwizard/organisation/${this.currentUser.organisationId}${folderParam ? `/${folderParam}` : ''}`);
	};


	private fetchRevisionList = (project: ProjectEntity) => {
		let q = gql`
			query getRevisions {
				projectRevisionEntitys(where: {path: "ProjectId", comparison: equal, value: "${project.id}"}) {
					id
					created
					modified
					name
				}
			}
		`;

		store.apolloClient.query({ query: q })
			.then((result) => {
				runInAction(() => {
					let revisions : ProjectRevisionEntity[] = result.data.projectRevisionEntitys; // cast result to ProjectRevisionEntity
					this.revisionOption = revisions.slice().sort((a: ProjectRevisionEntity, b: ProjectRevisionEntity) => {
						return new Date(b.created).getTime() - new Date(a.created).getTime()
					}).map(revision => {
						const revisionOption: {display: string, value: ProjectRevisionEntity|undefined} = { display: revision.name, value: revision };
						return revisionOption;
					});
				})
			})
			.catch(e => {
				console.error("Unable to find project revision");
			})
	}

	@computed
	public get projects() {
		const revisionList: {display: string, value: ProjectRevisionEntity|undefined}[] = [
			{ display: "Current Working Revision", value: undefined }
		];
		if(this.selectedProject) {
			this.selectedProject.projectRevisions.forEach(revision => {
				revisionList.push({ display: revision.name, value: revision });
			});
		}
		return revisionList;
	}

	public deleteProject = (currentUser: UserEntity = this.currentUser, selectedProject: ProjectEntity | undefined = this.selectedProject) => {
		if( currentUser.role === "ADMIN" || currentUser.id === selectedProject?.projectOwnerId) {
			// Confirm before we delete a project
			confirmModal('Please confirm', "Are you sure you want to delete this project?")
				.then(action(async () => {
					if (this.selectedProject) {
						// Delete project and refresh page
						await this.selectedProject.deleteProject();
						
						this.getPaginatedProjects(0, this.selectedFolder);

						this.resetSelectedProject();
					}
				}));
		} else {
			alertToast("You do not have permissions to delete this project", "error");
		}
	};

	@action private activateDuplicateModal = () => {
		this.duplicateModalState.visible = true;
		if(this.selectedProject) {
			this.duplicateModalState.duplicateName = `${this.selectedProject?.name} copy` || "";
			this.duplicateModalState.jobNumber = this.selectedProject?.jobNumber || "";
		}
	};
	
	@action private duplicateModal = async () => {
		
		if(!this.selectedProject) {
			return;
		}
	
		// We can only save if the duplicate name is set
		if(!this.duplicateModalState.duplicateName || !this.duplicateModalState.jobNumber) {
			if(!this.duplicateModalState.duplicateName) {
				this.duplicateModalState.nameError = "Please choose a name for the duplicate of this project.";
			}
			if(!this.duplicateModalState.jobNumber) {
				this.duplicateModalState.jobError = "Please choose a job number for the duplicate of this project.";
			}
			return;
		}
		const result = await axios.post("/api/entity/ProjectEntity/duplicate", {
			Name: this.duplicateModalState.duplicateName, 
			Id: this.selectedProject.id,
			JobNumber: this.duplicateModalState.jobNumber,
		});

		const newProject = new ProjectEntity(result.data.project);
		newProject.projectRevisions = result.data.revisions;
		
		runInAction(() => {
			this.getPaginatedProjects(0, this.selectedFolder);
		});
		this.closeModal();
	};

	@action private activateShareModal = () => {
		this.shareModalState.visible = true;
		
		// Setup the list of users/orgs who this project was shared with
		if(this.selectedProject) {
			this.selectedProjectShares = [];
			axios.get(`/api/entity/ProjectEntity/share-list/${this.selectedProject.id}`)
				.then(result => {
					runInAction(() => this.selectedProjectShares = result.data);
				});
		}
	};
	
	@action private sendInvites = () => {
		this.shareModalState.inviteEmails.forEach(invitee => {
			// We can't send invites if we don't have a selected project (not that this should ever happen)
			if(!this.selectedProject) {
				return;
			}
			
			const newProjectShare = {
				email: invitee,
				organisationName: undefined,
				organisationImage: undefined,
				pendingConnectionId: undefined,
				sharedOrganisationId: undefined,
				organisationId: undefined,
				status: ProjectShareStatus.Sending,
				created: moment().format(),
			};
			this.selectedProjectShares && this.selectedProjectShares.push(newProjectShare);

			axios.post("/api/entity/ProjectEntity/share", {
				ProjectId: this.selectedProject.id,
				Email: invitee,
			}).then(response => {
				runInAction(() => {
					if(this.selectedProjectShares) {
						this.selectedProjectShares.splice(this.selectedProjectShares.indexOf(newProjectShare), 1);
						this.selectedProjectShares.push(response.data);
					}
				});
			}).catch(exception => {
				console.error(exception);
				runInAction(() => {
					if(this.selectedProjectShares) {
						newProjectShare.status = ProjectShareStatus.Failed;
						
						// Mobx isn't picking up on this change unless we remove and re-add it
						this.selectedProjectShares.splice(this.selectedProjectShares.indexOf(newProjectShare), 1);
						this.selectedProjectShares.push(newProjectShare);
					}
				});
			});
		});
		
		this.shareModalState.inviteEmails = [];
	};
	
	@action private removeAccess = (share: ProjectShare) => {
		if(share.sharedOrganisationId) {
			share.status = ProjectShareStatus.Removing;
			store.apolloClient
				.mutate({
					mutation: gql`
						mutation {
						  deleteOrganisationsSharedProjects(organisationsSharedProjectsIds: ["${share.sharedOrganisationId}"]) {
							id
						  }
						}`
				})
				.then(() => {
					runInAction(() => {
						if(this.selectedProjectShares) {
							this.selectedProjectShares.splice(this.selectedProjectShares.indexOf(share), 1)
						}
					});
				})
		}
		else if(share.pendingConnectionId) {
			share.status = ProjectShareStatus.Removing;
			store.apolloClient
				.mutate({
					mutation: gql`
						mutation {
						  deletePendingConnectionsEntity(pendingConnectionsEntityIds: ["${share.pendingConnectionId}"]) {
							id
						  }
						}`
				})
				.then(() => {
					runInAction(() => {
						if(this.selectedProjectShares) {
							this.selectedProjectShares.splice(this.selectedProjectShares.indexOf(share), 1)
						}
					});
				})
		}
	};

	@action private closeModal = () => {
		this.duplicateModalState = {
			visible: false,
			duplicateName: "",
			jobNumber: "",
			nameError: "",
			jobError: ""
		};
		this.shareModalState = {
			visible: false,
			inviteEmails: [],
		};
		this.selectedProjectShares = [];
	};
	
	// Used to shorten the project name length and add ellipses if required, in an attempt to make it look better
	private truncateString = (string: string, maxLength: number): string => {
		if(string && string.length > maxLength) {
			// ellipsis is wider than most characters, so we want to cut two off the end, not just one
			return string.slice(0, maxLength - 2) + "…";
		}
		return string
	}

	@action public resetSelectedProject = () => {
		this.selectedProject = undefined;
	}

	@action public setSelectedFolder = (name?: string) => {
		if (!name) {
			store.routerHistory.push(`/dashboard`);
		} else {
			store.routerHistory.push(`/dashboard/${encodeURIComponent(name)}`);
		}

		store.selectedFolder = this.selectedFolder;
	}

	@action public setProjectList = (projects: ProjectEntity[]) => {
		this.projectList = projects;
	}

	@action public setSearchTerm = (term: string) => {
		this.searchTerm = term;
	}

	@action
	public getPaginatedFolders = (pageNumber: number = 0, searchTerm: string = '', pageSize: number = 10) => {
		ProjectEntity.fetchFoldersPagination(pageNumber * pageSize, searchTerm, pageSize).then(results => {
			runInAction(()=>{
				this.projectFolderList = results.folders;
				this.pageListTotal = results.count;
			})
			this.changeRequestState('done');
		}).catch(e => {
			this.changeRequestState('error');
		});
	}
	
	@action
	public getPaginatedProjects = (pageNumber: number = 0, selectedFolder: string = '', searchTerm: string = '', pageSize: number = 10) => {
		ProjectEntity.fetchWithRevisionsPagination(pageNumber * pageSize, selectedFolder, searchTerm, pageSize).then(results => {
			runInAction(()=>{
				this.projectList = results.data;
				this.pageListTotal = results.count;
			})
			this.changeRequestState('done');
		}).catch(e => {
			this.changeRequestState('error');
		});
	}
}