import * as React from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import { store } from "Models/Store";
import {IModalProps} from "./Modal";
import {Button} from "../Button/Button";

const rootId = 'root';
const modalElement = document.getElementById(rootId);

export interface IBarArrangementModalProps extends IModalProps {
	elementWidth: number;
	elementHeight: number;
	width: number | undefined;
	depth: number | undefined;
	closeModal: () => void;
	aptusBarsAlongDepth: number | undefined;
	aptusBarsAlongWidth: number | undefined;
	nonAptusBarsAlongDepth: number | undefined;
	nonAptusBarsAlongWidth: number | undefined;
}

/**
 * A modal dialog that can display any content inside of it
 */
export default class BarArrangementModal extends React.Component<IBarArrangementModalProps> {
	
	// Return an array of strings of ordered bars
	private renderBars(aptusBars: number, nonAptusBars: number) {
		
		// Final bar arrangement in order
		let barsArranged: string[] = [];
		
		// Sections of Aptus and non Aptus bar
		let barSections = {};
		
		// Number of sections between aptus bars
		let numOfSectionsForNonAptusBar = aptusBars - 1;
		
		// Initialise number of bars left
		let numOfAptusBarLeft = aptusBars;
		let numOfNonAptusBarLeft = nonAptusBars;
		
		if (aptusBars >= 2 && nonAptusBars >= 0) {
			// Arranging aptus bars and prefill non-aptus bar section 
			for (let i = 0; i < aptusBars + numOfSectionsForNonAptusBar; i++) {
				if (numOfAptusBarLeft <= 0) continue;
				if (i % 2 === 0) {
					barSections[i] = ["aptus"];
					numOfAptusBarLeft--;
				} else {
					barSections[i] = [];
				}
			}
			
			// Arranging non aptus bars
			if (numOfSectionsForNonAptusBar === 1) {
				// If there is only 1 section of non Aptus bar, arrange all non aptus bars inside that section
				let temp: string[] = [];

				for (let i = 0; i < nonAptusBars; i++) {
					temp.push("non-aptus");
					numOfNonAptusBarLeft--;
				}

				barSections[1] = temp;
			} else if (numOfSectionsForNonAptusBar % 2 === 0) {
				// if there are even number of sections of non Aptus bar
				let middleSectionIndex = Math.floor((aptusBars + numOfSectionsForNonAptusBar) / 2) - 1;

				let counter = 0;
				
				for (let i = 0; i < nonAptusBars; i++) {
					if (counter === 0) {
						if (numOfNonAptusBarLeft > 0) {
							barSections[middleSectionIndex].push("non-aptus");
							numOfNonAptusBarLeft--;
						}
						if (numOfNonAptusBarLeft > 0) {
							barSections[middleSectionIndex + 2].push("non-aptus");
							numOfNonAptusBarLeft--;
						}
						counter += 2;
					} else {
						if ((counter + middleSectionIndex) < (aptusBars + numOfSectionsForNonAptusBar) && middleSectionIndex - counter > 0) {
							if (numOfNonAptusBarLeft > 0) {
								barSections[middleSectionIndex - counter].push("non-aptus")
								numOfNonAptusBarLeft--;
							}
							if (numOfNonAptusBarLeft > 0) {
								barSections[middleSectionIndex + counter + 2].push("non-aptus");
								numOfNonAptusBarLeft--;
							}
							counter += 2;
						} else {
							counter = 0;
						}
					}
				}
				
			} else {
				// if there are odd number of sections of non Aptus bar
				let middleSectionIndex = Math.floor((aptusBars + numOfSectionsForNonAptusBar) / 2);
				
				let counter = 0;
				
				for (let i = 0; i < nonAptusBars; i++) {
					if (counter === 0) {
						if (numOfNonAptusBarLeft > 0) {
							barSections[middleSectionIndex].push("non-aptus");
							numOfNonAptusBarLeft--;
						}
						counter += 2;
					} else {
						if ((counter + middleSectionIndex) < (aptusBars + numOfSectionsForNonAptusBar) && middleSectionIndex - counter > 0) {
							if (numOfNonAptusBarLeft > 0) {
								barSections[middleSectionIndex - counter].push("non-aptus")
								numOfNonAptusBarLeft--;
							}
							if (numOfNonAptusBarLeft > 0) {
								barSections[middleSectionIndex + counter].push("non-aptus");
								numOfNonAptusBarLeft--;
							}
							counter += 2;
						} else {
							counter = 0;
						}
					}
				}
			}

			// Combine all sections
			for (let i = 0; i < aptusBars + numOfSectionsForNonAptusBar; i++) {
				barsArranged.push(...barSections[i]);
			}
		}
		
		return barsArranged.map(bar => {
			if (bar === "aptus") {
				return <div className="bar-aptus"/>
			} else {
				return <div className="bar-non-aptus"/>
			}
		})
	}
	
	public render() {
		if (!modalElement) {
			console.warn(`Could not find the #${rootId} element in the html. Could not create modal`);
		}

		const {elementHeight, elementWidth, aptusBarsAlongDepth, aptusBarsAlongWidth, nonAptusBarsAlongDepth, nonAptusBarsAlongWidth} = this.props;

		return (
			<ReactModal
				className={classNames('modal-content bar-arrangement-modal-content', this.props.className)}
				overlayClassName={'modal-container bar-arrangement-modal-container'}
				portalClassName={classNames('portal', store.appLocation)}
				appElement={modalElement || undefined}
				isOpen={this.props.isOpen}
				onAfterOpen={this.props.onAfterOpen}
				onRequestClose={this.props.closeModal}
				contentLabel={this.props.label}
				shouldCloseOnEsc={true}
				shouldCloseOnOverlayClick={true}
				aria={{
					// @ts-ignore
					live: 'assertive'
				}}
				
				{...this.props.modalProps}>
				<div className={"preview-modal-header"}>
					<h4 key="header" className="modal__header">Preview bar layout</h4>
					<Button onClick={this.props.closeModal} aria-label="Close">Close</Button>
				</div>
				<div className={"element-container"}>
					<div className={"element-wall-outter-layer"}>
						<div className={"element-wall"}
							 style={{width: `${elementWidth}px`, height: `${elementHeight}px`}}>
							<p className={"width-label"}>Width {`: ${this.props.width ?? this.props.width}mm`}</p>
							<p className={"depth-label"}
							   style={{transform: `rotate(90deg) translate(${(elementHeight / 2) - 11}px, -70px)`}}>Depth {`: ${this.props.depth ?? this.props.depth}mm`}</p>
							<div className={"element-wall-top"} style={{width: elementWidth}}>
								{this.renderBars(aptusBarsAlongWidth || 0, nonAptusBarsAlongWidth || 0)}
							</div>

							<div className={"element-wall-left"} style={{width: elementHeight}}>
								{this.renderBars(aptusBarsAlongDepth || 0, nonAptusBarsAlongDepth || 0)}
							</div>
							<div className={"element-wall-bottom"} style={{width: elementWidth}}>
								{this.renderBars(aptusBarsAlongWidth || 0, nonAptusBarsAlongWidth || 0)}
							</div>
							<div className={"element-wall-right"} style={{width: elementHeight}}>
								{this.renderBars(aptusBarsAlongDepth || 0, nonAptusBarsAlongDepth || 0)}
							</div>
						</div>
					</div>
				</div>


				<div className="modal__actions">
					<div>
						<div className="bar-aptus"/>
						<p>Aptus bar</p>
					</div>
					
					<div>
						<div className="bar-non-aptus"/>
						<p>Non-Aptus bar</p>
					</div>
				</div>
			</ReactModal>
		);
	}
}
