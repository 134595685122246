import ProjectEntity from "../../Models/Entities/ProjectEntity";


// Moved out of ProjectTile.tsx so it can be mocked easier
export function projectEntityFetch(projectId: string): Promise<ProjectEntity[]> {
	return ProjectEntity.fetch<ProjectEntity>({
		args: [[{
			path: 'id',
			comparison: "equal",
			value: projectId
		}]]
	})
}
