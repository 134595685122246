import { Component } from 'react';
import { action, IReactionDisposer, observable, reaction, computed } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { store } from 'Models/Store';

import CellEditView from 'Views/Components/ElementGrid/CellEditView';
import CellView from 'Views/Components/ElementGrid/CellView';
import LevelEditView from 'Views/Components/ElementGrid/LevelEditView';
import ColumnEditView from 'Views/Components/ElementGrid/ColumnEditView';
import ColumnView from 'Views/Components/ElementGrid/ColumnView';
import LevelView from 'Views/Components/ElementGrid/LevelView';
import BarArrangementModal from 'Views/Components/Modal/BarArrangementModal';
import { CellContextMenu, showCellContextMenu } from 'Views/Components/ElementGridContextMenus/CellContextMenu';
import { ColumnContextMenu, showColumnContextMenu } from 'Views/Components/ElementGridContextMenus/ColumnContextMenu';
import PasteSpecialView from 'Views/Components/ElementGrid/PasteSpecial';
import LoadLine from 'Views/Components/ElementGrid/LoadLine';

import type { Cell, ElementStructure } from 'Util/ElementStructureUtils';
import { ElementStructureUtils } from 'Util/ElementStructureUtils';
import { SelectionItemType, SelectionUtils } from 'Util/SelectionUtils';
import alertToast from 'Util/ToastifyUtils';
import type { IPasteBoolean, IPasteDisabler, PasteType } from 'Util/CopyPasteUtils';
import { CopyPasteUtils } from 'Util/CopyPasteUtils';

export enum ElementGridFilter {
	Default = "filter-default",
	ConcreteStrength = "filter-concrete-strength",
	BarConfiguration = "filter-bar-config",
	LoadTransfer = "load-transfer",
}

export interface IElementGridProps {
	elementStructure: ElementStructure;
	readonly?: boolean;
	simplified?: boolean;
	temporaryWorks?: boolean;
	afterChange?: (markEdited: boolean) => void;
	filter?: ElementGridFilter;
	changedCellIds?: string[];
	ignoreWarnings?: boolean;
	projectCanBeEdited?: boolean
	countCells?: () => void;
	sideBarWidth: number;
	setSideBarWidth: (number: number) => void;
}

@observer
export default class ElementGrid extends Component<IElementGridProps> {
	
	@observable private isBarArrangementModalOpen = false;
	
	private reactionDisposal?: IReactionDisposer;

	public selectionUtils: SelectionUtils = new SelectionUtils(
		this.props.elementStructure,
		this.props.temporaryWorks ? this.props.temporaryWorks : false,
		this.props.afterChange);
	
	@action
	private openBarArrangementModal = () => {
		this.isBarArrangementModalOpen = true;
	}
	
	@action
	private closeBarArrangementModal = () => {
		this.isBarArrangementModalOpen = false;
	}
	
	private renderBarArrangementModal = () => {
		let standardLength = 400;
		let elementWidth: number = standardLength;
		let elementDepth: number = standardLength;
		
		const {width, depth, aptusBarsAlongDepth, aptusBarsAlongWidth, nonAptusBarsAlongDepth, nonAptusBarsAlongWidth} = this.selectionUtils.editCell.model;

		if (width && depth){
			if (width >= depth) {
				elementWidth = standardLength;
				elementDepth = elementWidth * depth / width;
			}
			else {
				elementDepth = standardLength;
				elementWidth = elementDepth * width / depth;
			}

			elementWidth = (elementWidth < standardLength / 8) ? (standardLength / 8) : elementWidth;
			elementDepth = (elementDepth < standardLength / 8) ? (standardLength / 8) : elementDepth;
			
		}
		
		return ( 
			<BarArrangementModal 
				isOpen={this.isBarArrangementModalOpen} 
				label={"Bar arrangement representation"}
				width={width}
				depth={depth}
				elementWidth={elementWidth}
				elementHeight={elementDepth}
				closeModal={this.closeBarArrangementModal}
				aptusBarsAlongDepth={aptusBarsAlongDepth}
				aptusBarsAlongWidth={aptusBarsAlongWidth}
				nonAptusBarsAlongDepth={nonAptusBarsAlongDepth}
				nonAptusBarsAlongWidth={nonAptusBarsAlongWidth}
			/>
		)
	}
	
	
	
	@observable
	private pasteType?: PasteType = undefined;
	
	@observable
	public pasteBoolean: IPasteBoolean = {
		all: false,
		geometry: false,
		slabThicknessAtBase: false,
		reinforcement: false,
		concreteStr: false,
		reoRates: false,
	}
	
	@observable
	public pasteDisabler: IPasteDisabler = {
		all: {disabled: false},
		geometry: {disabled: false},
		slabThicknessAtBase: {disabled: false},
		reinforcement: {disabled: false},
		concreteStr: {disabled: false},
		reoRates: {disabled: false},
	}
	
	@action 
	public pasteCleanup(){
		this.pasteType = undefined;
	}

	@computed
	public get selectedCellIds() {
		return this.selectionUtils.selectedCells.map(e => e.model.id);
	}

	public componentWillUnmount() {
		this.reactionDisposal?.();
		this.props.setSideBarWidth(300);
	}

	public componentDidMount() {
		this.reactionDisposal = reaction(
			() => this.selectionUtils.currentEditType,
			value => {
				if (value === SelectionItemType.Column || value === SelectionItemType.Level) {
					this.props.setSideBarWidth(300);
				}
			},
		)
	}

	onCellClick = (event: React.MouseEvent, cell: Cell) => {
		this.selectionUtils.clickCell(event, cell);
		if (this.props.countCells) this.props.countCells();
	}

	public render() {
		let { temporaryWorks, readonly, simplified, elementStructure, afterChange, filter, changedCellIds } = this.props;
		return (
			<>
				<CellContextMenu
					onCopy={action(() => {
						CopyPasteUtils.executeCopy(elementStructure, this.selectionUtils);
					})}
					onPaste={action(async () => {
						const pasteCheck = CopyPasteUtils.pasteCheck(elementStructure, this.selectionUtils, store.selectionMatrix, store.copiedCells);
						switch (pasteCheck) {
							case "PasteEmptyUnderLoadTransfer":
								alertToast("Cannot paste here, some above cells are transferring load", "error")
								break;
							case "PasteEmptyIntoLoadTransfer":
								alertToast("Cannot paste empty/deleted cells into those transferring load", "error")
								break;
							case "MatrixNotMatching":
								alertToast("Selection Matrix Does not match, please select the same format as the cells on your clipboard", "error")
								break;
							case "NoElementsCopied":
								alertToast("No Elements Copied from this project.", "warning")
								break;
							case "MoreThanOneUniqueColTypes":
								alertToast("More than one Unique Column Type Selected", "error")
								break;
							case undefined:
								this.pasteType = pasteCheck;
								break;
							case "Multi":
							case "One":
								// Perform Validation Here, as in the other cases we are not pasting any information
								CopyPasteUtils.validatePasteCells(elementStructure, this.selectionUtils, store.selectionMatrix, store.copiedCells, this.pasteBoolean, this.pasteDisabler, pasteCheck);
								this.pasteType = pasteCheck;
								break;
						}
					})}
					disabled={this.selectionUtils.selectedCells && this.selectionUtils.selectedCells.length === 0}
				/>
				<ColumnContextMenu 
					onMoveRight={()=> {
						const colArray = this.selectionUtils.selectedColumns.map(x => x)
						this.selectionUtils.moveColumnBulk(colArray, true);
					}} 
					onMoveLeft={()=> {
						const colArray = this.selectionUtils.selectedColumns.map(x => x)
						this.selectionUtils.moveColumnBulk(colArray, false);
					}} 
					disabled={this.selectionUtils.selectedColumns && this.selectionUtils.selectedColumns.length === 0}
					/>
				{!temporaryWorks ? 
					<>
						<div className={classNames("edit-view", "element-grid",
							this.props.sideBarWidth === 300? 
								{ 'visible': this.selectionUtils.currentlyEditing} : 
								{'visible-expanded': this.selectionUtils.currentlyEditing}
						)}>
							<div className="edit-view-inner" style={{width: `${this.props.sideBarWidth}px`}}>
								{this.pasteType !== undefined ? 
									<PasteSpecialView 
										pasteType={this.pasteType} 
										elementStructure={elementStructure} 
										selectionUtils={this.selectionUtils} 
										pasteBoolean={this.pasteBoolean}
										pasteDisabler={this.pasteDisabler}
										pasteCleanup={() => this.pasteCleanup()}
									/> :
									<>
										{this.selectionUtils.currentEditType === SelectionItemType.Cell 
											? (
												<>
													{this.selectionUtils.currentlyEditing ? (
														<button 
															className="change-sidebar-width-button" 
															onClick={() => {
																this.props.setSideBarWidth(this.props.sideBarWidth === 300 ? 600 : 300);
															}}>
															{this.props.sideBarWidth === 300 ? '>' : '<'}
														</button>
													) : null}
													{this.renderBarArrangementModal()}
													<CellEditView 
														editCell={this.selectionUtils.editCell}
														selectionUtils={this.selectionUtils}
														cellIDs={this.selectedCellIds}
														mergeCells={() => {
															this.selectionUtils.mergeCells()
															if (this.props.countCells) this.props.countCells()
														}}
														splitCells={() => {
															this.selectionUtils.splitCells()
															if (this.props.countCells) this.props.countCells()
														}}
														openBarArrangementModal={this.openBarArrangementModal}
														closeBarArrangementModal={this.closeBarArrangementModal}
														saveChanges={() => {
															this.selectionUtils.saveItemChanges();
															if (this.props.countCells) this.props.countCells();
														}}
														canMergeCells={this.selectionUtils.canMergeCells()}
														canSplitCells={this.selectionUtils.canSplitCells()}
														deleteCells={this.selectionUtils.deleteCells}
														restoreDeletedCells={this.selectionUtils.restoreDeletedCells}
														approveCells={this.selectionUtils.approveCells}
														disapproveCells={this.selectionUtils.disapproveCells}
														cancelSelection={() => {
															this.selectionUtils.cancelSelection();
															this.props.setSideBarWidth(300);
															if (this.props.countCells) this.props.countCells();
														}
														}
														readonly={readonly}
														simplified={simplified}
														getCellHeight={(editCell) => this.selectionUtils.getValidCellHeight(this.selectionUtils.selectedCells, editCell)}
														elementStructure={elementStructure}
														getCellsBelow={()=> this.selectionUtils.getCellsBelowGivenCells(this.selectionUtils.selectedCells)}
														isConfigurationDisabled={(editCell, minWidth, minDepth, minSlab) => this.selectionUtils.isConfigurationDisabled(editCell, this.selectionUtils.selectedCells, minWidth, minDepth,minSlab)}
													/>
												</>)
											: null
										}
										{this.selectionUtils.currentEditType === SelectionItemType.Column 
											? (<ColumnEditView 
													editColumn={this.selectionUtils.editColumn} 
													editColumnErrors={this.selectionUtils.editColumnErrors} 
													multipleSelected={this.selectionUtils.selectedColumns.length > 1} 
													saveChanges={this.selectionUtils.saveItemChanges} 
													deleteColumns={this.selectionUtils.deleteSelectedColumns} 
													cancelSelection={()=> {
														this.selectionUtils.cancelSelection();
														this.props.setSideBarWidth(300);
														if (this.props.countCells) this.props.countCells();
													}} 
													readonly={readonly} 
													simplified={simplified}
													elementStructure={elementStructure}
												/>) 
											: null}
										{this.selectionUtils.currentEditType === SelectionItemType.Level 
											? (<LevelEditView 
													editLevel={this.selectionUtils.editLevel} 
													editLevelErrors={this.selectionUtils.editLevelErrors} 
													multipleSelected={this.selectionUtils.selectedLevels.length > 1} 
													saveChanges={this.selectionUtils.saveItemChanges} 
													deleteLevels={this.selectionUtils.deleteSelectedLevels} 
													cancelSelection={()=> {
														this.selectionUtils.cancelSelection();
														this.props.setSideBarWidth(300);
														if (this.props.countCells) this.props.countCells()
													}} 
													readonly={readonly} 
													simplified={simplified} 
													getLevelHeight={(editLevel) => this.selectionUtils.getValidLevelHeight(editLevel, this.selectionUtils.selectedLevels)} 
													getLevelHeightBelow={(editLevel) => this.selectionUtils.getValidLevelBelowHeight(editLevel, this.selectionUtils.selectedLevels)}
													selectedLevels={[...this.selectionUtils.selectedLevels]}
												/>) 
											: null
										}
									</>
								}
							</div>
						</div>
					</> : null}
				<div id="elements-grid-wrap" className="elements-grid-wrap">
					<div className={classNames("elements-grid", filter, {'simple': simplified})}>
						{/* Left Headers */}
						<div className="grid-view-column level-headers">
							<div className="corner"/>
							{elementStructure.levels.map((level, levelIndex) => {
								return (
									<LevelView
										level={level}
										levelHighlighted={this.selectionUtils.isLevelHighlighted(level)}
										onClick={(event: React.MouseEvent) => {
											this.selectionUtils.clickLevel(event, level);
											if(this.props.countCells) this.props.countCells();
										}}
										clickAddLevel={(index: number) => this.selectionUtils.addLevel(index)}
										levelIndex={levelIndex}
										readonly={readonly}
										key={level.id}
										temporaryWorks={temporaryWorks}
									/>
								);
							})}
						</div>

						{/* Top headers and table body */}
						{elementStructure.columnTypes.map((columnType, columnTypeIndex) => {
							if(columnType.columns.length === 0) {
								return null;
							}
							
							return (
								<span className="grid-view-column-type" key={columnType.id}>
									{columnType.columns.map((column, columnIndex) => {
										return (
											<div className="grid-view-column" key={columnType.id + ":" + column.id}>
												{/* Left header */}
												<ColumnView
													column={column}
													columnTypeName={columnType.code}
													columnHighlighted={this.selectionUtils.isColumnHighlighted(column)}
													onClick={(event: React.MouseEvent) => {
														this.selectionUtils.clickColumn(event, column, columnType);
														if(this.props.countCells) this.props.countCells();
													}}
													clickAddColumn={(index: number) => this.selectionUtils.addColumn(columnType, index)}
													columnIndex={columnIndex}
													readonly={readonly}
													temporaryWorks={temporaryWorks}
													onRightClick={(e: React.MouseEvent) => {
														showColumnContextMenu(e);
													}}
												/>

												{/* Table body */}
												{elementStructure.levels.map((level, levelIndex) => {
													const cell = elementStructure.cells[column.id][level.id];
													return (
														<>
															{
																filter === ElementGridFilter.LoadTransfer && cell && cell.loadTransfers && cell?.loadTransfers?.length > 0
																		? <LoadLine
																			columnTypeIndex={columnTypeIndex}
																			columnIndex={columnIndex}
																			levelIndex={levelIndex}
																			elementStructure={elementStructure}
																			loadTransfers={cell.loadTransfers}
																			cell={cell}
																			onLineClick={this.onCellClick}
																		/>
																: null
															}
															<CellView
																cell={cell}
																column={column}
																level={level}
																columnTypeName={columnType.name}
																cellSelected={this.selectionUtils.selectedCellsIncludesCell(cell)}
																onClick={this.onCellClick}
																onRightClick={showCellContextMenu}
																key={ElementStructureUtils.formatCellName(columnType, column, level)}
																simplified={simplified}
																temporaryWorks={temporaryWorks}
																afterChange={afterChange}
																cellChanged={changedCellIds !== undefined && changedCellIds.includes(cell.id)}
																ignoreWarnings={this.props.ignoreWarnings}
															/>
														</>
													);
												})}
											</div>
										);
									})}
									<span className="grid-view-table-gap"/>
								</span>
							);
						})}
					</div>
				</div>
			</>
		);
	};
}