import * as React from 'react';
import { observer } from 'mobx-react';
import {
	Cell, Column,
	ColumnType,
	ElementStructure,
	ElementStructureUtils,
	Level,
	PricingPartList
} from "Util/ElementStructureUtils";
import { Checkbox } from "Views/Components/Checkbox/Checkbox";
import classNames from "classnames";

import { observable } from 'mobx';
import { TextField } from "Views/Components/TextBox/TextBox";
import {mapReverse} from "Util/CodeUtils";
import {SelectionUtils} from "Util/SelectionUtils";
import CellPartsEditView from "Views/Components/ElementGrid/CellPartsEditView";

export interface IScheduleOfRatesProps {
	elementStructure: ElementStructure;
	afterChange?: () => void;
	tile: string;
	readonly?: boolean;
}

@observer
export default class ScheduleOfRates extends React.Component<IScheduleOfRatesProps> {

	@observable searchTerm: string = "";

	public selectionUtils: SelectionUtils = new SelectionUtils(this.props.elementStructure, !!this.props.readonly, this.props.afterChange);

	public render() {
		const { elementStructure, readonly, tile } = this.props;

		return (
			<div className="schedule-of-rates-outer-wrap">

				<div className="project-page-buttons quote-active" id="search-container" style={tile === "ProjectRevisionTile" ? { display: "none" } : {}}>
					<TextField
						className="search-key-input"
						model={this}
						modelProperty="searchTerm"
						placeholder="Search" />
					
				</div>

				<div className="schedule-of-rates-inner-wrap">
					<table className="schedule-of-rates">
						<thead>
							<tr>
								<th rowSpan={2}>Element Name</th>
								<th rowSpan={2}>Total Aptus Cost</th>
								<th rowSpan={2}>Element Number</th>
								<th rowSpan={2}>Element Location</th>
								<th rowSpan={2}>Element Dimensions</th>
								<th rowSpan={2}>Element Height (mm)</th>
								<th rowSpan={2}>Element Mass (t)</th>
								<th rowSpan={2}>Concrete Volume (m³)</th>
								<th rowSpan={2}>Concrete Strength (MPa)</th>
								<th colSpan={2}>Rebar Configuration</th>
								<th colSpan={12}>In-situ Starter Bars &amp; Inner Couplers + Extension Bars (if req'd)</th>
								<th colSpan={3}>Outer Couplers (at base)</th>
								<th colSpan={4}>Aptus Bars (within element)</th>
								<th colSpan={4}>Aptus Starter Bars (within element)</th>
								<th colSpan={7}>Inner Couplers (at top) + Extension Bars (if req'd)</th>
								<th colSpan={3}>Aptus Threaded Anchors</th>
								<th colSpan={3} className="text-tooltip">
									<div className="input-group--tooltip input-group input-group-block">
										<span>Estimated Rebar Mass</span>
										<div className="tooltip icon-information icon-right">
											<span className="tooltip__content">Estimated rebar mass includes a 6.6% rolling margin</span>
										</div>
									</div>
								</th>
							</tr>
							<tr>
								<th className="long-column">Aptus Bars + Conv. Bars</th>
								<th className="long-column">Horizontal Rebar</th>

								<th>Remove Starters</th>
								<th>Qty</th>
								<th>Length (mm)</th>
								<th className="long-column">Aptus Bar Type</th>
								<th>Rate</th>
								<th>Qty</th>
								<th className="long-column">Inner Coupler</th>
								<th>Rate</th>
								<th>Qty</th>
								<th>Length (mm)</th>
								<th className="long-column">Extension Bar</th>
								<th>Rate</th>

								<th>Qty</th>
								<th>Outer Coupler</th>
								<th>Rate</th>

								<th>Qty</th>
								<th>Length (mm)</th>
								<th className="long-column">Aptus Bar</th>
								<th>Rate</th>

								<th>Qty</th>
								<th>Length (mm)</th>
								<th className="long-column">Aptus Bar</th>
								<th>Rate</th>

								<th>Qty</th>
								<th className="long-column">Inner Coupler</th>
								<th>Rate</th>
								<th>Qty</th>
								<th>Length (mm)</th>
								<th className="long-column">Extension Bar</th>
								<th>Rate</th>

								<th>Qty</th>
								<th className="long-column">Anchor Assembly</th>
								<th>Rate</th>

								<th>Aptus Bars (kg)</th>
								<th>Non-Aptus Rebar (kg)</th>
							</tr>
						</thead>
						<tbody id="schedule-rate-table-body">
							{mapReverse(elementStructure.levels, (level: Level) => {
								return elementStructure.columnTypes.map(columnType => {
									return columnType.columns.map(column => {
										const cell = elementStructure.cells[column.id][level.id];
										// Filter out merged and deleted cells
										if(cell.deleted || cell.merged) {
											return null;
										}
										
										// If the user has entered a search term, we filter on that
										if(!this.searchFilter(cell, level, column, columnType)) {
											return null;
										}

										let partData: PricingPartList = {};
										if(cell.parts && cell.aptusDesignConfiguration !== 'nonaptus') {
											partData = cell.parts;
										}

										return (
											<tr key={columnType.id + column.id + level.id} 
												className={classNames("detailed-schedule-cell", {'selected': this.selectionUtils.selectedCellsIncludesCell(cell), "editable": !readonly})}
												onClick={(event: React.MouseEvent) => this.selectionUtils.clickCellList(event, cell)}>
												
												<td className="element-name">{level.code + columnType.code + column.name}</td>
												<td className={classNames("total-cost", elementStructure.info.editedSinceLastBuild ? "edited" : null)}>{ElementStructureUtils.cellPrices(cell).total.toLocaleString("en-AU", { style: "currency", currency: "AUD" })}</td>

												<td className="column-name">{columnType.code + column.name}</td>
												<td className="level-name">{level.name}</td>
												<td className="cell-dimensions">{ElementStructureUtils.cellDimensionString(cell)}</td>
												<td className="cell-height">{cell.height ? cell.height.toLocaleString("en-AU", { style: "decimal", maximumFractionDigits: 0 }) : "0"}</td>
												<td className="cell-mass">{cell.unitMass ? cell.unitMass.toLocaleString("en-AU", { style: "decimal", maximumFractionDigits: 2 }) : "0"}</td>
												<td className="concrete-volume">{cell.unitMass ? (cell.unitMass / 2.55).toLocaleString("en-AU", { style: "decimal", maximumFractionDigits: 2 }) : "0"}</td>
												<td className="concrete-strength">{ElementStructureUtils.concreteStrengthString(cell)}</td>

												<td className="cell-bar-design">{ElementStructureUtils.cellAptusDesignString(cell)}</td>
												<td className="cell-lig-design">{ElementStructureUtils.cellLigatureDesignString(cell)}</td>

												{/* Insitu Starter bars, with associated inner couplers and socket extensions */}
												<td className="insitu-starter-bars-disabled">
													{partData && partData.insituStarterBars
														? <Checkbox
															className="override-ssl"
															model={cell}
															modelProperty={"disableInsituStarters"}
															onAfterChecked={this.callAfterChange}
															isDisabled={readonly} />
														: null}
												</td>
												<td className={classNames("insitu-starter-bars-qty", cell.disableInsituStarters ? 'disabled' : '')}>
													{partData && partData.insituStarterBars ? partData && partData.insituStarterBars.quantity : null}
												</td>
												<td className={classNames("insitu-starter-bars-length", cell.disableInsituStarters ? 'disabled' : '')}>
													{partData && partData.insituStarterBars ? (partData && partData.insituStarterBars.length * 1000).toLocaleString("en-AU", { style: "decimal", maximumFractionDigits: 3 }) : null}
												</td>
												<td className={classNames("insitu-starter-bars-name", cell.disableInsituStarters ? 'disabled' : '')}>
													{partData && partData.insituStarterBars ? partData && partData.insituStarterBars.partName : null}
												</td>
												<td className={classNames("insitu-starter-bars-price", cell.disableInsituStarters ? 'disabled' : '')}>
													{partData && partData.insituStarterBars ? partData && partData.insituStarterBars.price.toLocaleString("en-AU", { style: "currency", currency: "AUD" }) : null}
												</td>
												<td className={classNames("insitu-inner-couplers-qty", cell.disableInsituStarters ? 'disabled' : '')}>
													{partData && partData.insituStarterInnerCouplers ? partData && partData.insituStarterInnerCouplers.quantity : null}
												</td>
												<td className={classNames("insitu-inner-couplers-name", cell.disableInsituStarters ? 'disabled' : '')}>
													{partData && partData.insituStarterInnerCouplers ? partData && partData.insituStarterInnerCouplers.partName : null}
												</td>
												<td className={classNames("insitu-inner-couplers-price", cell.disableInsituStarters ? 'disabled' : '')}>
													{partData && partData.insituStarterInnerCouplers ? partData && partData.insituStarterInnerCouplers.price.toLocaleString("en-AU", { style: "currency", currency: "AUD" }) : null}
												</td>
												<td className={classNames("insitu-extensions-qty", cell.disableInsituStarters ? 'disabled' : '')}>
													{partData && partData.insituStarterExtensions ? partData && partData.insituStarterExtensions.quantity : null}
												</td>
												<td className={classNames("insitu-extensions-length", cell.disableInsituStarters ? 'disabled' : '')}>
													{partData?.insituStarterExtensions?.length ?? null}
												</td>
												<td className={classNames("insitu-extensions-name", cell.disableInsituStarters ? 'disabled' : '')}>
													{partData && partData.insituStarterExtensions ? partData && partData.insituStarterExtensions.partName : null}
												</td>
												<td className={classNames("insitu-extensions-price", cell.disableInsituStarters ? 'disabled' : '')}>
													{partData && partData.insituStarterExtensions ? partData && partData.insituStarterExtensions.price.toLocaleString("en-AU", { style: "currency", currency: "AUD" }) : null}
												</td>

												{/* Outer Couplers */}
												<td className={classNames("outer-couplers-qty", partData && partData.insituStarterBars && cell.disableInsituStarters ? 'disabled' : '')}>
													{partData && partData.outerCouplers ? partData && partData.outerCouplers.quantity : null}
												</td>
												<td className={classNames("outer-couplers-name", partData && partData.insituStarterBars && cell.disableInsituStarters ? 'disabled' : '')}>
													{partData && partData.outerCouplers ? partData && partData.outerCouplers.partName : null}
												</td>
												<td className={classNames("outer-couplers-price", partData && partData.insituStarterBars && cell.disableInsituStarters ? 'disabled' : '')}>
													{partData && partData.outerCouplers ? partData && partData.outerCouplers.price.toLocaleString("en-AU", { style: "currency", currency: "AUD" }) : null}
												</td>

												{/* Aptus bars */}
												<td className="bars-qty">{partData && partData.bars ? partData && partData.bars.quantity : null}</td>
												<td className="bars-length">{partData && partData.bars ? (partData && partData.bars.length * 1000).toLocaleString("en-AU", { style: "decimal", maximumFractionDigits: 0 }) : null}</td>
												<td className="bars-name">{partData && partData.bars ? partData && partData.bars.barName : null}</td>
												<td className="bars-price">{partData && partData.bars ? partData && partData.bars.combinedPrice.toLocaleString("en-AU", { style: "currency", currency: "AUD" }) : null}</td>

												{/* Starter Bars within the element, to support the next higher element */}
												<td className="starter-bars-qty">{partData && partData.starterBars ? partData && partData.starterBars.quantity : null}</td>
												<td className="starter-bars-length">{partData && partData.starterBars ? (partData && partData.starterBars.length * 1000).toLocaleString("en-AU", { style: "decimal", maximumFractionDigits: 3 }) : null}</td>
												<td className="starter-bars-name">{partData && partData.starterBars ? partData && partData.starterBars.partName : null}</td>
												<td className="starter-bars-price">{partData && partData.starterBars ? partData && partData.starterBars.price.toLocaleString("en-AU", { style: "currency", currency: "AUD" }) : null}</td>

												{/* inner couplers and extensions, at top of element */}
												<td className="inner-couplers-qty">{partData && partData.innerCouplers ? partData && partData.innerCouplers.quantity : null}</td>
												<td className="inner-couplers-name">{partData && partData.innerCouplers ? partData && partData.innerCouplers.partName : null}</td>
												<td className="inner-couplers-price">{partData && partData.innerCouplers ? partData && partData.innerCouplers.price.toLocaleString("en-AU", { style: "currency", currency: "AUD" }) : null}</td>
												<td className="extensions-qty">{partData && partData.extensions ? partData && partData.extensions.quantity : null}</td>
												<td className="extensions-length">{partData.extensions?.length ?? null}</td>
												<td className="inner-couplers-name">{partData && partData.extensions ? partData && partData.extensions.partName : null}</td>
												<td className="inner-couplers-price">{partData && partData.extensions ? partData && partData.extensions.price.toLocaleString("en-AU", { style: "currency", currency: "AUD" }) : null}</td>

												{/* Anchor heads */}
												<td className="anchor-heads-qty">{partData && partData.anchorHeads ? partData && partData.anchorHeads.quantity : null}</td>
												<td className="anchor-heads-name">{partData && partData.anchorHeads ? partData && partData.anchorHeads.partName : null}</td>
												<td className="anchor-heads-price">{partData && partData.anchorHeads ? partData && partData.anchorHeads.price.toLocaleString("en-AU", { style: "currency", currency: "AUD" }) : null}</td>

												{/* Rebar Mass */}
												<td className={classNames("aptus-bar-mass", elementStructure.info.editedSinceLastBuild ? "edited" : null)}>
													{cell.aptusDesignConfiguration === 'nonaptus' && cell.insituElement
															? 0.0
															: ElementStructureUtils
																.calculateAptusRebarMass(cell)
																.toLocaleString("en-AU", { style: "decimal", maximumFractionDigits: 2 })
													}
												</td>
												<td className={classNames("nonaptus-bar-mass", elementStructure.info.editedSinceLastBuild ? "edited" : null)}>
													{
														cell.aptusDesignConfiguration === 'nonaptus' && cell.insituElement
															? 0.0
															: ElementStructureUtils.shouldUseReoRate(cell)
																? ElementStructureUtils
																	.calculateRebarMassFromReoRate(elementStructure, cell)
																	.toLocaleString("en-AU", { style: "decimal", maximumFractionDigits: 2 })
																: ElementStructureUtils
																	.calculateNonAptusRebarMass(elementStructure, cell)
																	.toLocaleString("en-AU", { style: "decimal", maximumFractionDigits: 2 })
														
													}
												</td>
											</tr>
										);
									});
								});
							})}
						</tbody>
					</table>
				</div>

				<div className={classNames("edit-view", {'visible': this.selectionUtils.currentlyEditing})}>
					<div className="edit-view-inner">
						<CellPartsEditView
							editCell={this.selectionUtils.editCell}
							cellIDs={this.selectionUtils.selectedCells.map(e => e.model.id)}
							saveChanges={this.selectionUtils.saveItemChanges}
							cancelSelection={this.selectionUtils.cancelSelection}
							readonly={readonly} />
					</div>
				</div>
			</div>
		);
	};

	private callAfterChange = () => {
		if (this.props.afterChange) {
			this.props.afterChange();
		}
	};
	
	// Returns false if the given cell doesn't match with the current search term
	private searchFilter(cell: Cell, level: Level, column: Column, columnType: ColumnType): boolean {

		// Filter results
		if(this.searchTerm) {
			// Get the values we want to search against
			const searchValues = [
				level.name,
				level.code + columnType.code + column.name,
				ElementStructureUtils.cellDimensionString(cell),
				ElementStructureUtils.cellAptusDesignString(cell),
			].map(x => ' ' + x + ' ');


			// Check if any of them match our search term
			let foundMatch = false;
			for(let i = 0; i < searchValues.length; i++) {
				if(searchValues[i].toLowerCase().includes(this.searchTerm.toLowerCase())) {
					foundMatch = true;
					break;
				}
			}

			// If nothing matches, we don't render anything for this cell
			if(!foundMatch) {
				return false;
			}
		}
		
		return true;
	}
}