import { action } from 'mobx';
import { store } from 'Models/Store';
import type { Cell, Column, ColumnType, ElementStructure, Level } from 'Util/ElementStructureUtils';
import { ElementStructureUtils } from 'Util/ElementStructureUtils';
import type { SelectedCell, EditCell } from 'Util/SelectionUtils';
import { SelectionItemType, SelectionUtils } from 'Util/SelectionUtils';
import alertToast from 'Util/ToastifyUtils';
import CellEditView, { CellErrorTypes } from 'Views/Components/ElementGrid/CellEditView';
import { prebuildValConsts } from 'Models/Entities/ProjectEntity';

export interface IPasteBoolean {
	all: boolean,
	geometry: boolean,
	slabThicknessAtBase: boolean,
	reinforcement: boolean,
	concreteStr: boolean,
	reoRates: boolean,
}
export interface IPasteDisabler {
	all: {disabled: boolean, msg?: string},
	geometry: {disabled: boolean, msg?: string},
	slabThicknessAtBase: {disabled: boolean, msg?: string},
	reinforcement: {disabled: boolean, msg?: string},
	concreteStr: {disabled: boolean, msg?: string},
	reoRates: {disabled: boolean, msg?: string},
}

export type PasteType =
	"One" | "Multi" | "MatrixNotMatching" | "MoreThanOneUniqueColTypes" | "NoElementsCopied" 
	| "PasteEmptyUnderLoadTransfer" | "PasteEmptyIntoLoadTransfer";

/**
 * SelectionMatrix order:
 * [
 * 	[topLeft, bottomLeft],
 * 	[topRight, bottomRight],
 * ]
 * */
export type SelectionMatrixEntry = { cellId: string, merged: boolean, levelHeight: number } | undefined;

export type SelectionMatrix = SelectionMatrixEntry[][];

type SelectedColumn = { column: Column, columnNumber: number };

type SelectedLevel = { level: Level, levelNumber: number };

const findSelectedLevel = (id: string, levels: SelectedLevel[]) => levels.find(level => id === level.level.id);

/**
 * Generate additional SelectedLevels for merged cells
 * 
 * Because the selected cells only include the cell at the base of a merged cell,
 * when we detect a selected cell is merging other cells, we generate SelectedLevels
 * so we can determine the actual dimensions and bounds of our selection matrix
 * @param elementStructure The entire element structure
 * @param currentCell current cell being inspected. this is the cell at the base of the group of merged cells
 * @param levelIndex this is the index of the level in the element structure levels array for the currentCell
 * @param currentSelectedLevels levels already selected, we use this to avoid adding duplicates
 */
const generateLevelsAbove = (
	elementStructure: ElementStructure, 
	currentCell: SelectedCell, 
	levelIndex: number, 
	currentSelectedLevels: SelectedLevel[]
) : SelectedLevel[] => {
	// calculate the level indices for each merged cell
	// the reason we subtract 1 from the length, and add 1 to the level index, is because
	// we don't want to generate a SelectedLevel for the base cell in the merged cells
	// (that's done in getSelectionMatrix() before this functions is optionally called)
	const mergedLevelIndices = Array.from(
		{ length: currentCell.model.levelHeight - 1 },
		(v, i) => (levelIndex - 1) - i,
	);

	const levelsToReturn = mergedLevelIndices.reduce((levels: SelectedLevel[], currentLevelIndex: number) => {
		const mergedLevel = elementStructure.levels[currentLevelIndex];

		return findSelectedLevel(mergedLevel.id, currentSelectedLevels)
			? levels // don't add new level if it's already been selected
			: [...levels, { level: mergedLevel, levelNumber: currentLevelIndex }];
	}, []);

	return levelsToReturn;
}

export class CopyPasteUtils {
	// MATRIX CALCULATIONS
	// prepare to have your mind blown
	@action
	public static getSelectionMatrix(elementStructure: ElementStructure, selectedCells: SelectedCell[]): SelectionMatrix {

		/**
		 * Create a matrix of selected cells, currently does not work for multiple Column Types
		 * To Define the dimensions of the matrix we need the width & height
		 * To Define the width of the matrix we need:
		 * - the Left most Cell/Column
		 * - the Right most Cell/Column
		 */
		const selectedColumns: SelectedColumn[] = [];

		for (const t of elementStructure.columnTypes) {
			let columnNumber = 0;
			for (const c of t.columns) {
				if (
					!selectedColumns.some(column => column.column === c)
					&& selectedCells.find(cell => cell.model.columnId === c.id)
				) {
					selectedColumns.push({ column: c, columnNumber: columnNumber })
				}
				columnNumber += 1;
			}
		}

		/**
		 *  To Define the width of the matrix we need:
		 * - the Top most row/cell + height of cells that expand over this level
		 * - the Bottom most row/cell
		 */
		const selectedLevels: SelectedLevel[] = [];
		
		for (let levelIndex = 0; levelIndex < elementStructure.levels.length; levelIndex++) {
			const currentLevel = elementStructure.levels[levelIndex];
			
			// If we haven't already got this level and the selected cells list contains a cell in this level
			if (!findSelectedLevel(currentLevel.id, selectedLevels)) {
				const cellsInLevel = selectedCells.filter(cell => cell.model.levelId === currentLevel.id);

				if (cellsInLevel.length) { // if there's any selected cells in this level
					// add to selected levels and iterate cells so we can generate virtual cells in matrix representing merged cells
					selectedLevels.push({ level: currentLevel, levelNumber: levelIndex });

					for (const cell of cellsInLevel) {
						// if a selected cell in this level has other cells merged with it
						if (cell.model.levelHeight > 1 && !cell.model.merged) {
							const additionalLevels = generateLevelsAbove(elementStructure, cell, levelIndex, selectedLevels);
							selectedLevels.push(...additionalLevels);
						}
					}
				}
			}
		}

		const leftMostIndex = Math.min(...selectedColumns.map(c => c.columnNumber));
		const rightMostIndex = Math.max(...selectedColumns.map(c => c.columnNumber));
		const topMostIndex = Math.min(...selectedLevels.map(l => l.levelNumber));
		const bottomMostIndex = Math.max(...selectedLevels.map(l => l.levelNumber));
		
		const height = (bottomMostIndex - topMostIndex) + 1;
		const width = (rightMostIndex - leftMostIndex) + 1;

		// Generate an empty two dimensional array using the width and height calculated above
		/**
		 * SelectionMatrix order:
		 * [
		 * 	[topLeft, bottomLeft],
		 * 	[topRight, bottomRight],
		 * ]
		 * */
		const array = Array.from(
			{ length: width },
			() => new Array(height).fill(undefined),
		);

		const topLeft = {left: leftMostIndex, top: topMostIndex};

		for (const column of selectedColumns) {
			for (const level of selectedLevels) {
				const selectedCell =  selectedCells.find(cell => cell.model.levelId === level.level.id && cell.model.columnId === column.column.id);
				if (selectedCell) {
					array[column.columnNumber - topLeft.left][level.levelNumber - topLeft.top] = {
						cellId: selectedCell.model.id,
						merged: selectedCell.model.merged,
						levelHeight: selectedCell.model.levelHeight
					};
					for (let i = 1; i < selectedCell.model.levelHeight; i++) {
						array[column.columnNumber - topLeft.left][level.levelNumber - topLeft.top - i] = {
							cellId: selectedCell.model.id,
							merged: true,
							levelHeight: 1
						}
					}
				}
			}
		}
		return array;
	}

	// COPY FUNCTION
	@action
	public static executeCopy(elementStructure: ElementStructure, selectionUtils: SelectionUtils) {
		// If the selected elements contain multiple types we show an alert.
		const columnIds = selectionUtils.selectedCells.map(cell => cell.model.columnId)
		const uniqueColTypeIds = this.getUniqueColTypeIds(elementStructure, columnIds);

		if (uniqueColTypeIds.length > 1) {
			alertToast("Cannot Copy across multiple Column Types", "error");
		} else {
			// copy cells and selection matrix to global store
			store.copiedCells = JSON.stringify(selectionUtils.selectedCells.map(sel => sel.model));
			store.selectionMatrix = CopyPasteUtils.getSelectionMatrix(elementStructure, selectionUtils.selectedCells);

			const serialiseCell = (sel: SelectedCell) => {
				const level = elementStructure.levels.find(lvl => lvl.id === sel.model.levelId);
			
				let columnType: ColumnType | undefined;
				let column: Column | undefined;

				for(const colType of elementStructure.columnTypes) {
					const foundCol = colType.columns.find(col => col.id === sel.model.columnId);
					if (foundCol) {
						columnType = colType;
						column = foundCol;
					}
				}

				return {
					elementName: `${level?.code}${columnType?.code}${column?.name}`,
					aptusDesignConfiguration: sel.model.aptusDesignConfiguration,
					insituElement: sel.model.insituElement,
					shape: sel.model.shape,
					width: sel.model.width,
					depth: sel.model.depth,
					height: sel.model.height,
					slabThicknessAtBase: sel.model.slabThicknessAtBase,
					aptusBarsAlongWidth: sel.model.aptusBarsAlongWidth,
					aptusBarsAlongDepth: sel.model.aptusBarsAlongDepth,
					aptusBarType: sel.model.aptusBarType,
					nonAptusBarsAlongWidth: sel.model.nonAptusBarsAlongWidth,
					nonAptusBarsAlongDepth: sel.model.nonAptusBarsAlongDepth,
					nonAptusBarType: sel.model.nonAptusBarType,
					ligDesign: sel.model.ligDesign,
					aptusBarLigSpacing: sel.model.aptusBarLigSpacing,
					aptusBarLigType: sel.model.aptusBarLigType,
					nonAptusBarLigSpacing: sel.model.nonAptusBarLigSpacing,
					nonAptusBarLigType: sel.model.nonAptusBarLigType,
					reoRate: sel.model.reoRate || undefined,
					concreteStrength: sel.model.concreteStrength,
				};
			};
			
			// Store some data on the clipboard so it can still be pasted elsewhere for quick-reference
			navigator.clipboard.writeText(JSON.stringify(selectionUtils.selectedCells.map(serialiseCell), undefined, 2));
		}
	}
	
	// PASTE CHECKS
	@action
	public static pasteCheck(
		elementStructure: ElementStructure,
		selectionUtils: SelectionUtils,
		selectionMatrix?: SelectionMatrix,
		copiedCells?: string,
	) : PasteType {
		let selectedCells = selectionUtils.selectedCells;
		
		let emptyUnderLoadTransfer = false;
		const loadTransferIndex = ElementStructureUtils.generateTransferIndex(elementStructure);

		// If we are pasting into an empty cell (Anywhere in the selection) we need to check that it is not
		// Below a cell with loadTransfers. If it is, we throw an error.
		for (const sel of selectionUtils.selectedCells) {
			const cellAbove = ElementStructureUtils.findCellAboveGivenCell(elementStructure, sel.model);
			if (cellAbove && ElementStructureUtils.hasOutgoingAssociatedLoads(cellAbove, undefined, loadTransferIndex) && sel.model.deleted){
				emptyUnderLoadTransfer = true;
				break;
			}
		}
		
		if (emptyUnderLoadTransfer) return "PasteEmptyUnderLoadTransfer"
				
		if (selectionMatrix && copiedCells) {
			const storeCopied: Cell[] = JSON.parse(copiedCells);
			if (storeCopied.length === 1 && !storeCopied.some(x => x.merged || x.levelHeight > 1)) {
				if (storeCopied[0].deleted && selectionUtils.selectedCells.some(sel => ElementStructureUtils.hasAssociatedLoads(sel.model, loadTransferIndex))){
					return "PasteEmptyIntoLoadTransfer";
				}
				return "One"
			} else {
				// Matrix Matching
				// Check that the stored matrix, and the current selected matrix have selected elements in the same locations
				// If the selected elements contain multiple types we show an alert.
				const columnIds = selectionUtils.selectedCells.map(cell => cell.model.columnId)
				const uniqueColTypeIds = this.getUniqueColTypeIds(elementStructure, columnIds);
				if (uniqueColTypeIds.length === 1) {
					const currentSelectionMatrix = CopyPasteUtils.getSelectionMatrix(elementStructure, selectedCells);
					const storeBoolMatrix = selectionMatrix.map(matrix => matrix.map(innerMatrix => !!innerMatrix));
					const currentBoolMatrix = currentSelectionMatrix.map(matrix => matrix.map(innerMatrix => !!innerMatrix));
					const equivalent = JSON.stringify(storeBoolMatrix) === JSON.stringify(currentBoolMatrix);
					if (equivalent) {
						let pasteIntoEmpty = false;
						CopyPasteUtils.matrixMatcher(selectionUtils, elementStructure, selectionMatrix, storeCopied, (replacement:Cell, sel:SelectedCell)=>{
							if(replacement.deleted && ElementStructureUtils.hasAssociatedLoads(sel.model, loadTransferIndex)){
								pasteIntoEmpty = true;
							}
						})
						if (pasteIntoEmpty) return "PasteEmptyIntoLoadTransfer";
						return "Multi"
					} else {
						return "MatrixNotMatching"
					}
				} else {
					return "MoreThanOneUniqueColTypes"
				}
			}
		} else {
			return "NoElementsCopied"
		}
	}

	public static getUniqueColTypeIds(elementStructure: ElementStructure, columnIds: string[]) {
		const uniqueColumns: string[] = []
		elementStructure.columnTypes.forEach(t => {
			columnIds.forEach(id => {
				if (!uniqueColumns.includes(t.id) && t.columns.map(c => c.id).includes(id)) {
					uniqueColumns.push(t.id)
				}
			})
		});
		return uniqueColumns;
	}

	@action
	public static executePaste (
		pasteType: PasteType, 
		elementStructure: ElementStructure, 
		selectionUtils: SelectionUtils, 
		pasteBoolean: IPasteBoolean,
		copiedCells?: string,
		) {
		if (copiedCells) {
			const storeCopiedParsed = JSON.parse(copiedCells) as Cell[];
			switch (pasteType)
			{
				case "One":
					try {
						this.pasteOneToMany(elementStructure, selectionUtils, storeCopiedParsed, pasteBoolean);
						alertToast("Paste (Replace Cells) Success", "success")
					} catch (e) {
						console.error(e);
						alertToast("Paste (Replace Cells) Failed", "error")
					}
					break;
				case "Multi":
					try {
						if (!store.selectionMatrix) {
							alertToast("No Elements Copied from this project.", "warning");
							break;
						}

						this.pasteManyToMany(selectionUtils, elementStructure, storeCopiedParsed, pasteBoolean, store.selectionMatrix);
						alertToast("Paste (Multiple Cells) Successful", "success");
					} catch (e) {
						console.error(e);
						alertToast("Paste (Multiple Cells) Failed", "error");
					}
					break;
				default:
					break;
			}
		} else {
			alertToast("No Elements Copied from this project.", "warning");
		}
	}
	
	// PASTE VALIDATION
	/*
	Due to time constraints while working on APT-720, I was unable to figure out a way to use the following functions from
	CellEditView.tsx and instead copied the code over into these files.
	
	I recommend moving the code from CellEditView.tsx to a more accessible location and feeding in the variables required
	for the validation functions.
	
	The following functions have similar implementations (or exact copies) in CellEditView
	prebuildErrorConsts
	validCellSlabThickness
	validCurrentCellHeight
	validBelowCellHeight
	 */

	@action
	private static prebuildErrorConsts(editCell: EditCell): Record<CellErrorTypes, string> {
		return {
			minWidthError: `Width must be at least ${editCell.model.aptusDesignConfiguration !== "nonaptus" ? prebuildValConsts.minWidthAptus : prebuildValConsts.minWidthNonAptus} mm`,
			minDepthError: `Depth must be at least ${editCell.model.aptusDesignConfiguration !== "nonaptus" ? prebuildValConsts.minDepthAptus : prebuildValConsts.minDepthNonAptus} mm`,
			minSlabBaseError: `Slab Depth at Base must be at least ${editCell.model.aptusDesignConfiguration !== "nonaptus" ? prebuildValConsts.slabMinAptus : prebuildValConsts.slabMinNonAptus} mm`,
			minHeightErrorCurrent: `Height must at least ${prebuildValConsts.minHeight} mm`,
			maxHeightErrorCurrent: `Height must be less than ${prebuildValConsts.maxHeight} mm`,
			minHeightErrorBelow: `Height of cell below must at least ${prebuildValConsts.minHeight} mm`,
			maxHeightErrorBelow: `Height of cell below must be less than ${prebuildValConsts.maxHeight} mm`,
			maxWidthError: `Width must be less than ${prebuildValConsts.maxWidth} mm`,
			maxDepthError: `Depth must be less than ${prebuildValConsts.maxDepth} mm`,
			maxSlabBaseError: `Slab Depth at Base must be less than ${prebuildValConsts.slabMax} mm`,
			invalid: 'Invalid Function: Contact Support',
			minCorbelVoidWidthError: 'The element width cannot be less than the width of the corbel/void',
			minCorbelVoidDepthError: 'The element depth cannot be less than the depth of the corbel/void',
			minSlabTopError: `Slab Depth at Top must be at least ${editCell.model.aptusDesignConfiguration !== "nonaptus" ? prebuildValConsts.slabMinAptus : prebuildValConsts.slabMinNonAptus} mm`,
			maxSlabTopError: `Slab Depth at Top must be less than ${prebuildValConsts.slabMax} mm`,
			maxPercentError: 'The sum of the percentages of the loads being transferred must equal 100%',
			percentWithoutElement: 'A load percentage cannot be transferred to nothing. Set an element to transfer to.',
			elementToReceiving4: 'Cannot transfer more than 4 loads to the same cell. Check your Load Transfers.',
			nonAptusRequiresVertReo: 'You are missing conventional reinforcing, please specify a reo rate or vertical bars with ties.',
			nonAptusRequiresHorReo: 'You are missing conventional reinforcing, please specify a reo rate or vertical bars with ties.',
		}
	}
	
	public static matrixMatcher(
		selectionUtils: SelectionUtils, 
		elementStructure: ElementStructure,
		selectionMatrix: SelectionMatrix | undefined,
		replacingCells: Cell[],
		func: (replacement:Cell, sel: SelectedCell)=>any,		
	) {
		const currentSelectionMatrix = CopyPasteUtils.getSelectionMatrix(elementStructure, selectionUtils.selectedCells);
		let selectedCellsTemp = selectionUtils.selectedCells;
		for (const sel of selectedCellsTemp) {
			selectionUtils.selectedCells = selectedCellsTemp.filter(cell => cell === sel);
			let x = 0;
			for (const curr of currentSelectionMatrix) {
				let y = 0;
				for (const m of curr) {
					const replacement = replacingCells
						// eslint-disable-next-line no-loop-func
						.find((s: Cell) => selectionMatrix?.[x][y]?.cellId === s.id);

					if (m?.cellId === sel.model.id && replacement && selectionMatrix?.[x][y] !== undefined) {
						func(replacement, sel);
					}
					y++;
				}
				x++;
			}
			selectionUtils.selectedCells = selectedCellsTemp;
		}
	}
	
	
	@action
	public static validatePasteCells(
		elementStructure: ElementStructure,
		selectionUtils: SelectionUtils,
		selectionMatrix: SelectionMatrix | undefined,
		copiedCells: string | undefined, 
		pasteBoolean: IPasteBoolean,
		pasteDisabler: IPasteDisabler,
		pasteCheck:  PasteType, )
	{
		if(copiedCells){
			const replacingCells: Cell[] = JSON.parse(copiedCells);
			let dimensionErrors: string[] = [];
			let slabErrors: string[] = [];

			if(pasteCheck === "Multi") {
				// Paste Multiple Validation
				CopyPasteUtils.matrixMatcher(selectionUtils,elementStructure,selectionMatrix,replacingCells, (replacement:Cell, sel:SelectedCell)=>{
					CopyPasteUtils.performValidation(selectionUtils, replacement, elementStructure, sel, dimensionErrors, slabErrors);
				})
					
				CopyPasteUtils.pasteErrorLogic(dimensionErrors, slabErrors, pasteBoolean, pasteDisabler);
			} else if (pasteCheck === "One") {
				// Copied From pasteOne
				const replacement: Cell = JSON.parse(copiedCells)[0];
				for (const t of elementStructure.columnTypes) {
					for (const c of t.columns) {
						for (const l of elementStructure.levels) {
							let currentCell = elementStructure.cells[c.id][l.id];
							for (const sel of selectionUtils.selectedCells) {
								if (currentCell.id === sel.model.id) {
									CopyPasteUtils.performValidation(selectionUtils, replacement, elementStructure, sel, dimensionErrors, slabErrors);
								}
							}
						}
					}
				}
				CopyPasteUtils.pasteErrorLogic(dimensionErrors, slabErrors, pasteBoolean, pasteDisabler);
			}
		}
	}

	private static performValidation(selectionUtils: SelectionUtils, replacement: Cell, elementStructure: ElementStructure, sel: SelectedCell, dimensionErrors: string[], slabErrors: string[]) {
		const allValuesPasteBoolean: IPasteBoolean = {
			all: true,
			geometry: true,
			slabThicknessAtBase: true,
			reinforcement: true,
			concreteStr: true,
			reoRates: true,
		}
		const editCellTemp = CopyPasteUtils.pasteIntoEditCell(selectionUtils, replacement, allValuesPasteBoolean);
		const errorMessages = CopyPasteUtils.prebuildErrorConsts(editCellTemp);
		const dimensionErrorTemp = CellEditView.validCellDimensions(editCellTemp);
		const slabDepthErrorTemp = CopyPasteUtils.validCellSlabThickness(elementStructure, editCellTemp, selectionUtils, sel);
		
		if (dimensionErrorTemp !== "valid") {
			dimensionErrors.push(errorMessages[dimensionErrorTemp]);
		}
		if (slabDepthErrorTemp !== "valid") {
			slabErrors.push(errorMessages[slabDepthErrorTemp]);
		}
	}

	private static pasteErrorLogic(dimensionErrors: string[], slabErrors: string[], pasteBoolean: IPasteBoolean, pasteDisabler: IPasteDisabler) {
		const dimensionError = dimensionErrors.find(x => x !== "valid")
		const slabError = slabErrors.find(x => x !== "valid")
		if (dimensionError) {
			pasteBoolean.geometry = false;
			pasteDisabler.geometry.disabled = true;
			pasteDisabler.geometry.msg = dimensionError;
		} else {
			pasteDisabler.geometry.disabled = false;
			pasteDisabler.geometry.msg = undefined;
		}
		if (slabError) {
			pasteBoolean.slabThicknessAtBase = false;
			pasteDisabler.slabThicknessAtBase.disabled = true;
			pasteDisabler.slabThicknessAtBase.msg = slabError;
		} else {
			pasteDisabler.slabThicknessAtBase.disabled = false;
			pasteDisabler.slabThicknessAtBase.msg = undefined;
		}
	}
	
	@action
	private static validCellSlabThickness(elStruct: ElementStructure, editCell: EditCell, selectionUtils: SelectionUtils, selectedCell: SelectedCell, type?: "Base" | "Top") {
		if (!selectionUtils.getCellHeight(selectedCell, editCell)) return "invalid"
		// If the Type is not set then we do both checks
		if (
			(type === "Base" || type === undefined)
			&& editCell.model.slabThicknessAtBase !== undefined
		) {
			const preBuildMinSlab = editCell.model.aptusDesignConfiguration !== "nonaptus"
				? prebuildValConsts.slabMinAptus
				: prebuildValConsts.slabMinNonAptus;
			
			if ((editCell.model.slabThicknessAtBase) < preBuildMinSlab) {
				return "minSlabBaseError";
			}
			if (editCell.model.slabThicknessAtBase > prebuildValConsts.slabMax) {
				return  "maxSlabBaseError";
			}
		}
		if (
			(type === "Top" || type === undefined)
			&& (editCell.model.slabThicknessAtTop !== null && editCell.model.slabThicknessAtTop !== undefined)
			&& (editCell.info.atTopOfStack || editCell.info.atTopOfBuilding)
		) {
			const preBuildMinSlab = editCell.model.aptusDesignConfiguration !== "nonaptus"
				? prebuildValConsts.slabMinAptus
				: prebuildValConsts.slabMinNonAptus;
			
			if ((editCell.model.slabThicknessAtTop) < preBuildMinSlab) {
				return "minSlabTopError";
			}
			if (editCell.model.slabThicknessAtTop > prebuildValConsts.slabMax) {
				return "maxSlabTopError";
			}
		}

		const belowCellValid = this.validBelowCellHeight(editCell, selectionUtils, elStruct);
		if (belowCellValid !== "valid") return belowCellValid;

		const currentCellValid = this.validCurrentCellHeight(editCell, selectionUtils, selectedCell);
		if (currentCellValid !== "valid") return currentCellValid;

		return "valid";
	}
	
	@action
	public static validCurrentCellHeight(editCell: EditCell, selectionUtils: SelectionUtils, selectedCell: SelectedCell ){
		// Check the height of the current cell is valid
		const heightAbove = selectionUtils.getCellHeight(selectedCell, editCell);

		if (heightAbove < prebuildValConsts.minHeight) return "minHeightErrorCurrent";

		if (heightAbove > prebuildValConsts.maxHeight) return "maxHeightErrorCurrent";
		return "valid";
	}

	@action
	public static validBelowCellHeight(editCell: EditCell, selectionUtils: SelectionUtils, elStruct: ElementStructure){
		// Check the height of the cell below is valid
		const heightsBelow = selectionUtils.getValidCellBelowHeight(editCell, selectionUtils.selectedCells, elStruct);
		if (heightsBelow !== "No Cell Below") {
			const [minHeightBelow, maxHeightBelow] = heightsBelow;
			if (minHeightBelow < prebuildValConsts.minHeight) return "minHeightErrorBelow";
			if (maxHeightBelow > prebuildValConsts.maxHeight) return "maxHeightErrorBelow";
		}
		return "valid";
	}
	
	
	// PASTE FUNCTIONS
	@action
	static pasteOneToMany(
		elementStructure: ElementStructure,
		selectionUtils: SelectionUtils,
		storeCopiedParsed: Cell[],
		pasteBoolean: IPasteBoolean,
	) {
		const replacement = storeCopiedParsed[0];

		elementStructure.columnTypes.forEach(t => {
			t.columns.forEach(c => {
				elementStructure.levels.forEach(l => {
					let currentCell = elementStructure.cells[c.id][l.id];
					selectionUtils.selectedCells.forEach(sel => {
						if (currentCell.id === sel.model.id) {
							this.pasteValues(selectionUtils, replacement, pasteBoolean);
						}
					})
				})
			})
		})
	}

	@action
	private static pasteMerges(
		selectionUtils: SelectionUtils,
		elementStructure: ElementStructure,
		selectedCells: SelectedCell[],
		selectionMatrix: SelectionMatrix,
		currentSelectionMatrix: SelectionMatrix,
	) {
		for (let i = 0; i < selectionMatrix.length; i++){
			// This is the selection matrix column ordered bottom to top
			const column = [...selectionMatrix[i]].reverse();
			const currentColumn = [...currentSelectionMatrix[i]].reverse();
			for (let j = 0; j < column.length; j++){
				const replacementCell = column[j];
				const currentCell = currentColumn[j];

				// We have a multi level cell, need to merge it with the ones above
				if (replacementCell && currentCell && replacementCell.levelHeight > 1) {
					const currentSelectedCell = selectedCells.find(x => x.model.id === currentCell.cellId);

					if (!currentSelectedCell) {
						continue;
					}

					// Create a list of cells to be merged
					const cellsToMerge: SelectedCell[] = [currentSelectedCell];
					for (let levelHeight = 1; levelHeight <= replacementCell.levelHeight - 1; levelHeight++) {
						// Find the last thing in the array each iteration and then find the cell above it. Call this
						// for each increment of level height.
						const cellAbove = ElementStructureUtils.findCellAboveGivenCell(elementStructure, cellsToMerge[cellsToMerge.length - 1].model);

						if (cellAbove) {
							cellsToMerge.push({ model: cellAbove });
						}
					}
					selectionUtils.selectedCells = cellsToMerge;
					selectionUtils.mergeCells();
					j += currentCell.levelHeight - 1;
				}
			}
		}
	}
	
	@action
	private static pasteManyToMany(
		selectionUtils: SelectionUtils,
		elementStructure: ElementStructure,
		storeCopiedParsed: Cell[],
		pasteBoolean: IPasteBoolean,
		selectionMatrix: SelectionMatrix
	) {
		// Split the cells that are selected, this will ensure that all cells are selected to have values pasted and are
		// also ready to be merged in the future
		selectionUtils.splitCells(true);

		const currentSelectionMatrix = CopyPasteUtils.getSelectionMatrix(elementStructure, selectionUtils.selectedCells);
		let selectedCellsTemp = selectionUtils.selectedCells;
		for (const sel of selectedCellsTemp) {
			selectionUtils.selectedCells = selectedCellsTemp.filter(cell => cell === sel);
			for (let i = 0; i < currentSelectionMatrix.length; i++){
				const selectedColumn = currentSelectionMatrix[i];
				for (let j = 0; j < selectedColumn.length; j++) {
					const selectedCell = selectedColumn[j];
					const replacementCell = storeCopiedParsed.find(cell => cell.id === selectionMatrix?.[i][j]?.cellId);

					if (selectedCell?.cellId === sel.model.id && replacementCell && selectionMatrix?.[i][j] !== undefined) {
						this.pasteValues(selectionUtils, replacementCell, pasteBoolean);
					}
				}
			}
		}

		this.pasteMerges(selectionUtils, elementStructure, selectedCellsTemp, selectionMatrix, currentSelectionMatrix);

		// Change back to the original selection, removing any merged cells from the selected values.
		selectionUtils.selectedCells = selectedCellsTemp.filter(x => !x.model.merged);

		selectionUtils.postSelectionSetup(SelectionItemType.Cell);
		selectionUtils.recalculateUnitMassForSelectedCells(false);
		selectionUtils.afterChange();
	}

	@action
	private static pasteValues(selectionUtils: SelectionUtils, replacement: Cell, pasteBoolean: IPasteBoolean) {
		if(replacement.deleted) {
			selectionUtils.deleteCells();
		} else {
			selectionUtils.restoreDeletedCells();
		}
		selectionUtils.editCell = this.pasteIntoEditCell(selectionUtils, replacement, pasteBoolean);
		selectionUtils.saveItemChanges();
	}
	
	@action
	private static pasteIntoEditCell(selectionUtils: SelectionUtils, replacement: Cell, pasteBoolean: IPasteBoolean){
		const editCellTemp: EditCell = JSON.parse(JSON.stringify(selectionUtils.editCell));
		// If We are pasting anything
		if ( pasteBoolean.all || pasteBoolean.geometry || pasteBoolean.slabThicknessAtBase || pasteBoolean.reoRates || pasteBoolean.concreteStr || pasteBoolean.reinforcement) {
			// Design
			editCellTemp.model.aptusDesignConfiguration = replacement.aptusDesignConfiguration === "aptus"
				? "custom"
				: replacement.aptusDesignConfiguration;

			if (editCellTemp.model.aptusDesignConfiguration === 'nonaptus')
				editCellTemp.model.insituElement = replacement.insituElement;

			// Remove any Corbels and Voids
			editCellTemp.model.corbel = null;
			editCellTemp.model.elementVoid = null;
			// Override Deleted Cell
			editCellTemp.info.deleted = replacement.deleted;
			// Lig Design
			editCellTemp.model.ligDesign = replacement.ligDesign;
		} else {
			return editCellTemp;
		}

		// Geometry
		if (pasteBoolean.geometry) {
			editCellTemp.model.width = replacement.width;
			editCellTemp.model.depth = replacement.depth;
		}

		// Slab Thickness
		if (pasteBoolean.slabThicknessAtBase)
			editCellTemp.model.slabThicknessAtBase = replacement.slabThicknessAtBase;

		// Bars & Ligs
		if (pasteBoolean.reinforcement) {
			// Aptus Bars
			editCellTemp.model.aptusBarsAlongDepth = replacement.aptusBarsAlongDepth;
			editCellTemp.model.aptusBarsAlongWidth = replacement.aptusBarsAlongWidth;
			editCellTemp.model.aptusBarType = replacement.aptusBarType;
			// Non-Aptus Bars
			editCellTemp.model.nonAptusBarsAlongDepth = replacement.nonAptusBarsAlongDepth;
			editCellTemp.model.nonAptusBarsAlongWidth = replacement.nonAptusBarsAlongWidth;
			editCellTemp.model.nonAptusBarType = replacement.nonAptusBarType;
			// Aptus bar ligs / ties
			editCellTemp.model.aptusBarLigType = replacement.aptusBarLigType;
			editCellTemp.model.aptusBarLigSpacing = replacement.aptusBarLigSpacing;
			// Non-Aptus bar ligs / ties
			editCellTemp.model.nonAptusBarLigType = replacement.nonAptusBarLigType;
			editCellTemp.model.nonAptusBarLigSpacing = replacement.nonAptusBarLigSpacing;
		}
		
		// Concrete Strength
		if (pasteBoolean.concreteStr){
			editCellTemp.model.concreteStrength = replacement.concreteStrength;
		}

		// Reo Rate (only paste if replacement reo rate is not falsey and therefore valid)
		if (pasteBoolean.reoRates && replacement.reoRate) {
			editCellTemp.model.reoRate = replacement.reoRate;
			editCellTemp.model.useReoRate = replacement.useReoRate;
		}
		return editCellTemp;
	}
}