import * as React from 'react';
import { observer } from 'mobx-react';
import aptusLogo from "Assets/aptus-logo-full-colour.png";

@observer
export default class LoginWrap extends React.Component {

	public render() {
		return (
			<div className="login-registration-content">
				<div className="info-section">
					<div className="info-header">
						<img className="header-icon" alt="Aptus logo" src={aptusLogo}/>
						<div className="header-separator"/>
						<div className="header-description">
							Design tool
						</div>
					</div>
					<div className="info-body">
						<h1 className="info-body-title">Design + calculate your Aptus projects.</h1>
						<div className="info-body-subtitle">Get a preliminary Aptus design, a quote, and all the material you need to get your next tender over the line.</div>
					</div>
					<div className="spacing-div"/>
					<div className="background-wrap"/>
				</div>
				<div className="form-section">
					{this.props.children}
				</div>
			</div>
		);
	}
}