/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';

// % protected region % [Add any extra imports here] on begin
import { store } from 'Models/Store';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
// % protected region % [Add any extra imports here] end

export interface TermsOfUsePageProps extends RouteComponentProps {
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

@observer
// % protected region % [Add any customisations to default class definition here] off begin
class TermsOfUsePage extends React.Component<TermsOfUsePageProps> {
// % protected region % [Add any customisations to default class definition here] end

	// % protected region % [Add class properties here] on begin
	private returnToLogin = () => {
		store.routerHistory.push('/dashboard');
	}
	// % protected region % [Add class properties here] end

	render() {
		// % protected region % [Add logic before rendering contents here] off begin
		// % protected region % [Add logic before rendering contents here] end

		let contents = (
			<SecuredPage>
				{
				// % protected region % [Alter navigation here] off begin
				}
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
				/>
				{
				// % protected region % [Alter navigation here] end
				}
				<div className="body-content">
				</div>
			</SecuredPage>
		);

		// % protected region % [Override contents here] on begin
		contents = (
			<div className="terms-of-use-wrap">
				<div className="terms-of-use">

					<h2>TERMS OF USE OF THE DESIGN TOOL</h2>

					<h4>Definitions</h4>

					<p>In this Agreement, unless the context otherwise requires:</p>
					<p><b>Account </b>means an online account created and registered by the Customer (or for and on behalf of the Customer) to access and use the Design Tool through the Website.</p>
					<p><b>Admin User </b>means a representative of the Customer who has the responsibility of creating and managing the Account for the Customer, and inviting, removing and managing access of other representatives of the Customer as Users of the Account.</p>
					<p><b>Agreement</b> means this agreement as updated from time to time by Aptus.</p>
					<p><b>App</b> means a mobile application for the Website.</p>
					<p><b>Aptus </b>(<b>we, our, us</b>) means Aptus Construction Systems Pty Ltd (ABN 78 613 974 084)<b>.</b></p>
					<p><b>Aptus Components</b> means concrete connection elements, devices and components, such as outer and inner couplers, produced by Aptus.</p>
					<p><b>Customer</b> (<b>you, your</b>) means an entity which registers an Account (or on behalf of which an Account is registered).</p>
					<p><b>Data </b>means all input information (such as location, wind region, terrain category, floor levels, slab thicknesses, column or wall sizes and loads on each element) entered by the Customer through the Design Tool to generate the Design and request the Quote in relation to the Project.</p>
					<p><b>Design</b> means a preliminary design only to relevant Australian Standards to satisfy the permanent in-service case and the temporary works case, generated by the Design Tool for a Project based on the Data supplied and various assumptions, which shows an indicative preliminary design using Aptus Components, and provides concrete strength and reinforcement required.</p>
					<p><b>Design Tool </b>means a software which uses the Data to generate the Design and provide the Quote to the Customer in relation to the Project. </p>
					<p><b>Intellectual Property </b>means all forms of intellectual property throughout the world including patents, petty patents, innovation patents, patentable inventions, know-how, trade marks (whether registered or unregistered), copyright, registered and registrable designs, circuit layout rights, applications for registration of any of the foregoing and rights to apply for registration of any of the foregoing.</p>
					<p><b>M3S IP </b>means<b></b>M3S IP Pty Ltd as trustee for M3S IP Trust (ABN 54 701 904 568)</p>
					<p><b>Privacy Policy </b>means Aptus’s privacy policy available through this link: <a href="https://aptus.systems/privacy-policy.html" target="_blank" rel="noreferrer">aptus.systems/privacy-policy.html</a></p>
					<p><b>Project</b> means a building project created by the Customer in the Design Tool whereas the Customer inputs the Data to request a Quote in relation to that project from Aptus.</p>
					<p><b>Terms of Use </b>means the terms and conditions contained in this Agreement</p>
					<p><b>Training </b>means a physical, video or online training in relation to the use and features of the Design Tool. </p>
					<p><b>Quote </b>means an estimate of the price for Aptus Components required for the Project based on the Design with a complete breakdown of individual Aptus Components.</p>
					<p><b>User </b>means a representative of the Customer which is added by the Admin User to use and access the Design Tool.</p>
					<p><b>Website</b> means the website aptus.systems accessed through the URL <a href="https://aptus.systems/" target="_blank" rel="noreferrer">https://aptus.systems/</a></p>


					<h4>1. AGREEMENT</h4>

					<h5>1.1 PARTIES</h5>
					<p>This Agreement is a contract between the Customer and Aptus. It sets out the Terms of Use that apply to your use of the Design Tool. You agree that your acceptance of the Terms of Use, and ongoing use of the Design Tool, constitute acceptance of this Agreement. </p>

					<h5>1.2 APPLICATION</h5>
					<p>(a) This Agreement will apply to each and every use of and access to the Design Tool by the Customer or by any person accessing and using the Design Tool for and on behalf of the Customer (including the Admin User and any User). </p>
					<p>(b) If you do not agree to be bound by this Agreement, you must not use and access the Design Tool, nor must you allow any person to access and use the Design Tool on your behalf.</p>
					<p>(c) The Agreement commences upon the Customer accepting the Terms of Use and, subject to earlier termination under clause 10, continues until such time that the Customer closes or de-registers its Account.</p>
					<p>(d) The Agreement applies in its entirety and constitutes the whole agreement, subject to clause 1.3(a). The Customer may either accept or reject the Terms of Use. If the Customer rejects the Terms of Use, it may not create an Account, and may not access and use the Design Tool. </p>
					<p>(e) No other terms, purchase order or other document will apply excepting those terms implied by statute which cannot be excluded or modified.</p>
					<p>(f) Every phrase, sentence, paragraph and clause in this Agreement is severable the one from the other despite the manner in which they may be linked together or grouped grammatically and if any phrase, sentence, paragraph or clause is found to be defective or unenforceable for any reason whatsoever the remaining phrases, sentences, paragraphs or clauses as the case may be, are of full force and effect.</p>
					<p>(g) Aptus may assign its rights, duties, titles, interests, and obligations under this Agreement or in relation to the Design Tool to any third party at any time. However, your rights and obligations under this Agreement shall remain non-transferable unless consented by Aptus in writing.</p>
					<p>(h) Reference to a $ or dollars in the Agreement are references to Australian dollars.</p>

					<h5>1.3 DOCUMENTS INCORPORATED INTO THIS AGREEMENT</h5>
					<p>(a)The following documents form part of this Agreement: </p>
					<ul>
						<li>the Privacy Policy: <a href="https://aptus.systems/privacy-policy.html" target="_blank" rel="noreferrer">aptus.systems/privacy-policy.html</a><b>;</b></li>
						<li>terms of use of the Website: <a href="https://design.aptus.systems/termsofuse" target="_blank" rel="noreferrer">aptus.systems/terms-and-conditions.html</a></li>
						<li>the Data;</li>
						<li>any other policy or legal document found on the Website;</li>
						<li>any document explicitly incorporated into this Agreement by reference. </li>
					</ul>
					<p>(b)To the extent of any inconsistency between this Agreement and any incorporated policy or document, the incorporated policy or document will prevail. </p>
					<p>(c)You need to print a copy of and store this Agreement and all policies and documents incorporated by reference for your records. </p>

					<h5>1.4 AMENDMENTS </h5>
					<p>(a)We may amend this Agreement at any time for any reason. This Agreement, as amended from time to time, is published by us and can be accessed by you on the Website. You should read this Agreement carefully each time when creating a new Project. </p>
					<p>(b)If you do not agree to accept the changes to the Agreement, you will need to close or de-register your Account, and otherwise should not access or use the Design Tool. If you continue using the Design Tool and having your account with us after we publish or send a notice about our changes to the Terms of Use, you consent to be bound by the updated Terms of Use and the changes to the Agreement as of their effective date.</p>
					<p>(c)The Customer cannot amend the terms of this Agreement unless such amendments are agreed by Aptus in writing. </p>


					<h4>2. DESIGN TOOL</h4>

					<h5>2.1 ACCESS AND TRAINING</h5>
					<p>(a) The Customer can only access and use the Design Tool after it has accepted this Agreement, following which it can register an Account.</p>
					<p>(b) The Design Tool can be accessed through the Website and the App from an internet browser.</p>
					<p>(c) Aptus makes no representation and gives no warranty about the quality of, access to and use of the Design Tool, including any representation that the Design Tool and access to it will be available, secure, uninterrupted or error-free.</p>
					<p>(d) Aptus will provide a Training to the Customer at the commencement of this Agreement on the terms determined by Aptus.</p>
					<p>(e) Aptus may provide support services to the Customer in relation to the use of the Design Tool on the terms determined by Aptus.</p>
					<p>(f) Aptus may make changes to the Design Tool or any features in or functionality of the Design Tool at any time. Aptus reserves the right to add, modify, impose conditions, suspend, discontinue, or permanently remove any feature or aspect of the Design Tool at its sole discretion and without any obligations whatsoever. We do not guarantee that any templates, tools, graphics, or other features will be continuingly available.</p>
					<p>(g) Access to or features of the Design Tool may differ from country to country. We may create an entirely different level of access to the Design Tool through different pricing packages, of which we will notify you accordingly and the provisions of clause 1.4 will apply to such changes.</p>

					<h5>2.2 DESIGN</h5>
					<p>(a) The Design Tool will use the Data submitted by the Customer to create a Design and provide a Quote.</p>
					<p>(b) The Design is created solely for the purpose of providing a Quote by Aptus to the Customer for the Project using Aptus Components.</p>
					<p>(c) Aptus does not provide any engineering certification of the Design. It is the Customer’s responsibility to ensure that design certification for in-service design and temporary works design is provided by a competent structural engineer who has independently reviewed and confirmed that all aspects of the Project in relation to the use of Aptus Components complies with the relevant legislation and building standards. </p>
					<p>(d) Aptus provides no warranties and accepts no responsibility whatsoever in relation to the accuracy, quality and correctness of the Design and/or the Project or any changes to the Design and/or the Project. </p>

					<h5>2.3 QUOTE</h5>
					<p>(a) In order to request a Quote for a Project, the Customer must follow the steps as required by the Design Tool and explained in the Training. </p>
					<p>(b) Once Aptus has received a request for a Quote from the Customer, Aptus will review and provide the Quote to the Customer based on the Data submitted by the Customer. </p>
					<p>(c) Aptus may provide a temporary Quote pending finalisation of the Project by the Customer.</p>
					<p>(d) Once the Customer informs Aptus that the Project is finalised, Aptus will issue a final Quote.</p>
					<p>(e) The Customer can use the Quote to decide whether it wishes to procced to purchase Aptus Components for the Project. </p>
					<p>(f) Aptus is not obliged to supply Aptus Components to the Customer based on the Quote or otherwise unless the parties enter into a separate agreement for supply of the Aptus Components.</p>
					<p>(g) If the Customer accepts the Quote and wishes to purchase Aptus Components for the Project from Aptus, and Aptus agrees to supply the Aptus Components to the Customers, such supply of the Aptus Components to the Customer by Aptus will be subject to a separate supply agreement on the terms agreed between Aptus and the Customer. </p>
					<p>(h) References to $ or dollars in the Quote are references to Australian dollars unless otherwise specified in the Quote.</p>
					<p>(i) The Quote does not include GST or any other applicable taxes and charges, unless otherwise specified in the Quote.</p>


					<h4>3. ACCOUNT </h4>

					<h5>3.1 REGISTRATION </h5>
					<p>(a) In order to create an Account, the Customer needs to provide the following information:</p>
					<ul>
						<li>Customer’s details, including the full entity name, ABN, business address and contact details; </li>
						<li>Email, full name, position and contact details of the Admin User; </li>
						<li>Any other information which may be required. </li>
					</ul>
					<p>(b) The Customer agrees, warrants and acknowledges that only an authorised representative of the Customer will be allowed to be the Admin User;</p>
					<p>(c) The Admin User will have the responsibly for administering and managing the Account on behalf of the Customer;</p>
					<p>(d) The Admin User may invite other Users, who must be employees or representatives of the Customer, to use the Account; </p>
					<p>(e) Any activity, action, act or omission by the Admin User or any User are deemed to be authorised by the Customer;</p>
					<p>(f) The Customer warrants and represents that any information submitted or used on its Account is true, accurate, reliable, complete and current. Creating a fake Account or submitting inaccurate, incomplete or fabricated information is a material breach of this Agreement.</p>
					<p>(g) The registration of an Account and the use of the Design Tool is subject to approval and satisfaction of Aptus. Aptus reserves the right to refuse, restrict, suspend, cancel or terminate the Account at any time and without any obligations or liability to the Customer whatsoever.</p>
					<p>(h) The Customer is responsible and liable to Aptus for any activity, action, act or omission of the Admin User and any User.</p>

					<h5>3.2 ACCESS</h5>
					<p>(a) Aptus will have access to the Customer’s Account and will store the details of and information relating to the Customer’s Account. </p>
					<p>(b) Collection, storage, use and handling of personal information provided by the Customer to Aptus while creating an account is subject to the Privacy Policy.</p>
					<p>(c) When the Customer creates an Account, it shall be the Customer’s obligation to maintain the security of all devices from which it accesses its Account and confidentiality of the login information. </p>
					<p>(d) The Customer is liable for all activities on its Account. If the Customer suspects any unauthorised access or activities on its Account, it must immediately review the security of devices and the Account and inform Aptus about such unauthorised access to the Customer’s Account.</p>


					<h4>4. AUTHORITY </h4>

					<h5>4.1 The Customer warrants and acknowledges that it:</h5>
					<p>(a) has authorised the creation of its Account and any activity of the Admin User, any User or any other person on the Account;</p>
					<p>(b) has authorised access to and use of the Design Tool by the Admin User and any User, including any request for a Quote; and</p>
					<p>(c) bears full liability and responsibility for any activity on its Account;</p>
					<p>4.2 A person accepting the terms and conditions of this Agreement for and on behalf of a Customer, registering and creating an Account for a Customer, and accessing and using the Design Tool, represents, acknowledges and warrants that he/she has a full authority to enter into this Agreement for and on behalf of the Customer and bind the Customer by these Terms of Use. If the person lacks sufficient authority, he/she takes personal responsibility to discharge all liabilities and obligations that may arise from or in relation to the use of and access to the Design Tool and indemnifies Aptus against any claim and action by a third party or a Customer arising out of or in connection with this Agreement, and access to and use of the Design Tool.</p>

					<h4>5. PRICING AND PAYMENT</h4>
					<p>5.1 Aptus may charge the Customer a service fee for assistance provided in relation to the Customer’s Account and/or the Training.</p>
					<p>5.2 The service fee (if applicable) will be charged at $180.00 (plus GST if applicable) per hour with a minimum of $180.00 (plus GST if applicable) payable in every instance. </p>
					<p>5.3 Aptus will advise the Customer in advance of its intention to charge the service fee and the estimated amount. </p>
					<p>5.4 Aptus may decide to waive the service fee at its discretion.</p>
					<p>5.5 The service fee will be payable by the Customer within 21 days of Aptus issuing a relevant invoice to the Customer. </p>


					<h4>6. INTELLECTUAL PROPERTY RIGHTS </h4>

					<p>6.1 Aptus is the exclusive licensee of the Website, the App and the Design Tool. Aptus has the exclusive right to operate, manage and sub-license the use of the Design Tool. </p>
					<p>6.2 All Intellectual Property used in connection with the Website and the Design Tool are the property of M3S IP. The Website, the App and the Design Tool are copyright of M3S IP.</p>
					<p>6.3 The Customer agrees and warrants that it:</p>
					<p>(a) will not copy, reproduce or seek to register Intellectual Property in the Website, the App and the Design Tool;</p>
					<p>(b) will not reverse-engineer any source-code used in the Website, the App and/or the Design Tool;</p>
					<p>(c) will not procure any other person or third party to do things stipulated in clauses 6.3(a) and (b);</p>
					<p>(d) will only use the Design Tool to request a Quote from Aptus and not for any other purposes; and</p>
					<p>(e) will ensure that its representatives and employees (including the Admin User or any User) will comply with clauses 6.3(a), (b), (c) and (d). </p>
					<p>6.4 The Customer retains the ownership in its Data only. All content, documentation, material, information, the Design and the Quote created by the Design Tool using the Customer’s Data belong to and remain the property of Aptus.</p>
					<p>6.5 Access to the Design Tool should not be taken as a grant or transfer of ownership rights, Intellectual Property rights, a licence or any other rights to the Customer. </p>


					<h4>7. WARRANTIES AND OBLIGATIONS OF THE CUSTOMER</h4>

					<h5>7.1 A Customer represents, acknowledges and warrants to Aptus that:</h5>
					<p>(a) it has read and accepted the Terms of Use;</p>
					<p>(b) it has full power and legal capacity to enter into this Agreement;</p>
					<p>(c) a representative who entered into this Agreement for and on behalf of the Customer has a full authority to enter into the Agreement and bind the Customer by the Terms of Use;</p>
					<p>(d) Aptus may collect, store, handle, process and use personal information and data in accordance with our Privacy Policy and that the Customer made our Privacy Policy available to the Admin User, any User and Customer’s representatives whose personal information may be collected by Aptus; </p>
					<p>(e) the information the Customer has provided is true, accurate and correct. Creating an Account with false information is material breach of this Agreement;</p>
					<p>(f) it will not rely on the content or accessibility of the Design Tool and that the content of the Design Tool is of a general nature;</p>
					<p>(g) it will not distribute copies of the Design Tool or its content to third parties without the prior written consent of Aptus;</p>
					<p>(h) it will not to make any permanent or enduring copy of the Design Tool, its content or its computer code, or reverse engineer or compile the Design Tool;</p>
					<p>(i) all terms, conditions and warranties other than those expressly contained in this Agreement are excluded;</p>
					<p>(j) the limitation of liability and disclaimer of liability apply as stated in clause 8 below; and</p>
					<p>(k) it will use the Design Tool in a lawful manner and will follow applicable rules and laws when accessing and using the Design Tool.</p>

					<h5>7.2 In addition to the promises and acknowledgements stated above, the Customer agrees:</h5>
					<p>(a) to use the Design Tool at its own risk;</p>
					<p>(b) to keep its Account password and credentials secret;</p>
					<p>(c) not to share an Account with any third party;</p>
					<p>(d) not to transfer any part of its Account or give access to it to any third party which is not engaged by the Customer in relation to the Project;</p>
					<p>(e) that it is responsible for anything that happens through its Account unless it closes it or reports misuse;</p>
					<p>(f) to keep all Account information up to date;</p>
					<p>(g) that Aptus may send you notices and messages through the Design Tool, email and contact information.</p>


					<h4>8. LIMITATION OF LIABILITY AND DISCLAIMER</h4>

					<h5>8.1 LIMITATION OF LIABILITY </h5>
					<p>(a) Some legal obligations imposed by law cannot be excluded or limited. Aptus does not purport to exclude or limit such liability. </p>
					<p>(b) To the fullest extent permitted by law, and except as otherwise expressly provided in terms and conditions applicable to specific transactions that may be facilitated by the Design Tool or by a separate written agreement that explicitly overrides this Agreement, Aptus is not and will not be liable to the Customer in connection with this Agreement for:</p>
					<ul>
						<li>loss of profit or business opportunity, loss of data (including any down time of the Design Tool, or loss, use of, or changes to, the Data), damage, loss, liability, claim or expense arising from the Customer’s use of the Design Tool, the Design and/or the Quote and whether such claim arises from breach of contract, negligence or other tort or breach of statute, wilful act or otherwise, or for any indirect, incidental, consequential, special or punitive damages;</li>
						<li>any damage, loss, liability, claim or expense caused to or brought by a third party against the Customer in connection with the use of and in relation to the Design Tool, the Design and/or the Quote;</li>
						<li>any unauthorised access to the Customer’s Account or misuse of the Customer’s Data;</li>
						<li>any interruption or disruption of the services provided by the Design Tool;</li>
						<li>any other matter related to the Customer’s use of the Design Tool, irrespective of the fact that such liabilities result from any non-performance, negligence of duty and statutory responsibilities, errors and omissions, loss of data, misrepresentation, torts, interruptions, unauthorised access, or breach of contract by Aptus, and the possibility of such liabilities was already conveyed to it;</li>
						<li>any amount that exceeds:</li>
						<li>the total fees paid or payable by you to Aptus as services fees under this Agreement; or </li>
						<li>$500.00 (five hundred dollars), </li>
						<li>whichever is lesser.</li>
					</ul>

					<h5>8.2DISCLAIMER</h5>
					<p>THIS IS A DISCLAIMER OF LEGAL LIABILITY OF APTUS FOR THE QUALITY, SAFETY, OR RELIABILITY OF THE DESIGN TOOL, THE DESIGN AND/OR THE QUOTE.</p>
					<p>Aptus (and its affiliates) makes no representation and gives no warranty about the quality of, access to and use of the Design Tool, the Design and the Quote, including any representation that the Design Tool and access to it will be secure, uninterrupted or error-free. Use and access to the Design Tool is provided on "as is" and "when available" basis. To the fullest extent permitted by applicable law, and subject to clause 8.1, Aptus disclaims any implied or statutory warranty (which can be excluded), including any implied warranty of title, accuracy of data, non-infringement, merchantability or fitness for a particular purpose.</p>

					<h5>8.3INDEMNITY </h5>
					<p>The Customer agrees to indemnify and keep indemnified Aptus against any loss or damage caused by the Customer to Aptus (or anyone accessing and using the Design Tool for and on behalf of the Customer, including the Admin User and any other User) or any claim, complaint or action brought against Aptus by any third party in relation to the use of the Design, Quote or any other information and material created by the Design Tool.</p>


					<h4>9.COMMUNICATION AND NOTICES</h4>

					<p>9.1 All notices, disclosures, communications, and information related to this Agreement or use of the Design Tool shall preferably take place through electronic delivery. The Customer consents to receiving all such communications and notices to its Admin User’s email address registered on the Account. Such notices shall be deemed as received on their confirmed dispatch.</p>
					<p>9.2 When you create an Account, you consent to subscribe to our notification and newsletter services. We may send useful information to your email address to improve your experience with Aptus and keep you informed about new products and services offered by Aptus. </p>
					<p>9.3 We will continue sending you communication and notices in relation to and in connection with this Agreement by email at the Admin User’s email address registered on the Account.</p>

					<h4>10. TERMINATION</h4>
					<p>10.1 Either party may terminate this Agreement at convenience at any time.</p>
					<p>10.2 Aptus may termination this Agreement if the Customer:</p>
					<ol className="alphabetic-list">
						<li>commits material breach of this Agreement; or </li>
						<li>fails to remedy a breach of this Agreement within 14 days of Aptus notifying the Customer of such breach.</li>
					</ol>
					<p>10.3 If you wish to terminate this Agreement, you can do so at any time by ceasing accessing or using the Design Tool and de-registering your Account.</p>
					<p>10.4 If the Agreement is terminated, the Customer’s Account and all content on it will be permanently deleted unless we are required to retain any content in compliance with any business or legal obligations. </p>

					<h4>11. JURISDICTION</h4>
					<p>This Agreement shall be governed and construed in accordance with the laws of the State of Queensland, Australia. The parties to this Agreement will submit to the non-exclusive jurisdiction of the courts of that place. </p>

					<Button onClick={this.returnToLogin} className="return-login-btn" display={Display.Solid} colors={Colors.Primary} icon={{ icon: "arrow-left", iconPos: 'icon-left' }}>Return</Button>
				</div>
			</div>
		);
		// % protected region % [Override contents here] end

		return contents;
	}
}

// % protected region % [Override export here] off begin
export default TermsOfUsePage;
// % protected region % [Override export here] end
