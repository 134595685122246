/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import {IOrderByCondition} from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsOrganisationEntity } from 'Models/Security/Acl/VisitorsOrganisationEntity';
import { UserOrganisationEntity } from 'Models/Security/Acl/UserOrganisationEntity';
import { AdminOrganisationEntity } from 'Models/Security/Acl/AdminOrganisationEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import {store} from "../Store";
import { gql } from '@apollo/client';
// % protected region % [Add any further imports here] end

export interface IOrganisationEntityAttributes extends IModelAttributes {
	name: string;
	phone: string;
	address1: string;
	address2: string;
	suburb: string;
	state: string;
	postcode: string;
	pipedriveOrgID: number;
	imagePath: string;
	organisationtype: Enums.organisationType;

	invitedUserss: Array<Models.InvitedUserEntity | Models.IInvitedUserEntityAttributes>;
	projectss: Array<Models.ProjectEntity | Models.IProjectEntityAttributes>;
	userss: Array<Models.UserEntity | Models.IUserEntityAttributes>;
	sharedProjectss: Array<Models.OrganisationsSharedProjects | Models.IOrganisationsSharedProjectsAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('OrganisationEntity', 'Organisation')
// % protected region % [Customise your entity metadata here] end
export default class OrganisationEntity extends Model implements IOrganisationEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsOrganisationEntity(),
		new UserOrganisationEntity(),
		new AdminOrganisationEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'name',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	/**
	 * Name
	 */
	@Validators.Required()
	@Validators.Unique()
	@observable
	@attribute()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Phone'] off begin
	/**
	 * Phone
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Phone',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public phone: string;
	// % protected region % [Modify props to the crud options here for attribute 'Phone'] end

	// % protected region % [Modify props to the crud options here for attribute 'Address 1'] off begin
	/**
	 * Address 1
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Address 1',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public address1: string;
	// % protected region % [Modify props to the crud options here for attribute 'Address 1'] end

	// % protected region % [Modify props to the crud options here for attribute 'Address 2'] off begin
	/**
	 * Address 2
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Address 2',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public address2: string;
	// % protected region % [Modify props to the crud options here for attribute 'Address 2'] end

	// % protected region % [Modify props to the crud options here for attribute 'Suburb'] off begin
	/**
	 * Suburb
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Suburb',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public suburb: string;
	// % protected region % [Modify props to the crud options here for attribute 'Suburb'] end

	// % protected region % [Modify props to the crud options here for attribute 'State'] off begin
	/**
	 * State
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'State',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public state: string;
	// % protected region % [Modify props to the crud options here for attribute 'State'] end

	// % protected region % [Modify props to the crud options here for attribute 'Postcode'] off begin
	/**
	 * Postcode
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Postcode',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public postcode: string;
	// % protected region % [Modify props to the crud options here for attribute 'Postcode'] end

	// % protected region % [Modify props to the crud options here for attribute 'Pipedrive Org ID'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Pipedrive Org ID',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public pipedriveOrgID: number;
	// % protected region % [Modify props to the crud options here for attribute 'Pipedrive Org ID'] end

	// % protected region % [Modify props to the crud options here for attribute 'Image Path'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Image Path',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public imagePath: string;
	// % protected region % [Modify props to the crud options here for attribute 'Image Path'] end

	// % protected region % [Modify props to the crud options here for attribute 'OrganisationType'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'OrganisationType',
		displayType: 'enum-combobox',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.organisationTypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.organisationTypeOptions),
		displayFunction: (attribute: Enums.organisationType) => Enums.organisationTypeOptions[attribute],
	})
	public organisationtype: Enums.organisationType;
	// % protected region % [Modify props to the crud options here for attribute 'OrganisationType'] end

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Invited Users'] off begin
		name: "Invited Userss",
		displayType: 'reference-multicombobox',
		order: 110,
		referenceTypeFunc: () => Models.InvitedUserEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'invitedUserss',
			oppositeEntity: () => Models.InvitedUserEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Invited Users'] end
	})
	public invitedUserss: Models.InvitedUserEntity[] = [];

	/**
	 * Projects
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Projects'] off begin
		name: "Projectss",
		displayType: 'reference-multicombobox',
		order: 120,
		referenceTypeFunc: () => Models.ProjectEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'projectss',
			oppositeEntity: () => Models.ProjectEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Projects'] end
	})
	public projectss: Models.ProjectEntity[] = [];

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Users'] off begin
		name: "Userss",
		displayType: 'reference-multicombobox',
		order: 130,
		referenceTypeFunc: () => Models.UserEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'userss',
			oppositeEntity: () => Models.UserEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Users'] end
	})
	public userss: Models.UserEntity[] = [];

	/**
	 * Shared Projects
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Shared Projects'] off begin
		name: 'Shared Projects',
		displayType: 'reference-multicombobox',
		order: 140,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.OrganisationsSharedProjects,
		optionEqualFunc: makeJoinEqualsFunc('sharedProjectsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'organisationEntity',
			oppositeEntityName: 'projectEntity',
			relationName: 'organisations',
			relationOppositeName: 'sharedProjects',
			entity: () => Models.OrganisationEntity,
			joinEntity: () => Models.OrganisationsSharedProjects,
			oppositeEntity: () => Models.ProjectEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Shared Projects'] end
	})
	public sharedProjectss: Models.OrganisationsSharedProjects[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IOrganisationEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IOrganisationEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.phone !== undefined) {
				this.phone = attributes.phone;
			}
			if (attributes.address1 !== undefined) {
				this.address1 = attributes.address1;
			}
			if (attributes.address2 !== undefined) {
				this.address2 = attributes.address2;
			}
			if (attributes.suburb !== undefined) {
				this.suburb = attributes.suburb;
			}
			if (attributes.state !== undefined) {
				this.state = attributes.state;
			}
			if (attributes.postcode !== undefined) {
				this.postcode = attributes.postcode;
			}
			if (attributes.pipedriveOrgID !== undefined) {
				this.pipedriveOrgID = attributes.pipedriveOrgID;
			}
			if (attributes.imagePath !== undefined) {
				this.imagePath = attributes.imagePath;
			}
			if (attributes.organisationtype !== undefined) {
				this.organisationtype = attributes.organisationtype;
			}
			if (attributes.invitedUserss !== undefined && Array.isArray(attributes.invitedUserss)) {
				for (const model of attributes.invitedUserss) {
					if (model instanceof Models.InvitedUserEntity) {
						this.invitedUserss.push(model);
					} else {
						this.invitedUserss.push(new Models.InvitedUserEntity(model));
					}
				}
			}
			if (attributes.projectss !== undefined && Array.isArray(attributes.projectss)) {
				for (const model of attributes.projectss) {
					if (model instanceof Models.ProjectEntity) {
						this.projectss.push(model);
					} else {
						this.projectss.push(new Models.ProjectEntity(model));
					}
				}
			}
			if (attributes.userss !== undefined && Array.isArray(attributes.userss)) {
				for (const model of attributes.userss) {
					if (model instanceof Models.UserEntity) {
						this.userss.push(model);
					} else {
						this.userss.push(new Models.UserEntity(model));
					}
				}
			}
			if (attributes.sharedProjectss !== undefined && Array.isArray(attributes.sharedProjectss)) {
				for (const model of attributes.sharedProjectss) {
					if (model instanceof Models.OrganisationsSharedProjects) {
						this.sharedProjectss.push(model);
					} else {
						this.sharedProjectss.push(new Models.OrganisationsSharedProjects(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	// [service-desk#52] Exclude `elementStructure` from default expands to imporve performance
	public defaultExpands = `
		sharedProjectss {
			${Models.OrganisationsSharedProjects.getAttributes().join('\n')}
			sharedProjects {
				${Models.ProjectEntity.getAttributes().filter(x => x !== 'elementStructure').join('\n')}
			}
		}
		invitedUserss {
			${Models.InvitedUserEntity.getAttributes().join('\n')}
		}
		projectss {
			${Models.ProjectEntity.getAttributes().filter(x => x !== 'elementStructure').join('\n')}
		}
		userss {
			${Models.UserEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			sharedProjectss: {},
			invitedUserss: {},
			projectss: {},
			userss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'invitedUserss',
							'projectss',
							'userss',
							'sharedProjectss',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] on begin
	// Fetch only organisations
	public static async fetchOrganisations() {
		const results = await store.apolloClient
			.query({
				query: gql`
					{
						organisationEntitys {
							id
							name
						}
					}`,
				fetchPolicy: 'network-only',
			});
		return results.data['organisationEntitys'].map((r: any) => new this(r));
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(OrganisationEntity.prototype, 'created');
CRUD(modifiedAttr)(OrganisationEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
