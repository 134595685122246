import {observer} from "mobx-react";
import * as React from "react";
import {AdditionalPart, ElementStructure, ElementStructureUtils} from "../../../Util/ElementStructureUtils";
import PartEntity from "Models/Entities/PartEntity";
import {action, observable, runInAction} from "mobx";
import {NumberTextField} from "Views/Components/NumberTextBox/NumberTextBox";
import {Button, Colors, Display} from "Views/Components/Button/Button";
import {Combobox} from "Views/Components/Combobox/Combobox";
import classNames from "classnames";

export interface ICostSummaryProps {
	elementStructure: ElementStructure;
	onChangeExtraParts?: () => void;
	readonly?: boolean;
}

interface AddingItemState {
	visible: boolean;
	part?: PartEntity;
	quantity: number;
	length: number;
}

@observer
export default class AdditionalParts extends React.Component<ICostSummaryProps> {
	
	public partIsBarRegex = /SB-(N[0-9]{2})-(SF|CF|HF|UF|VF|SM|AF|AM|SX)-[0-9]{4}/;
	
	@observable
	private requestState: 'loading' | 'error' | 'done' = 'loading';
	
	@observable addingItemState: AddingItemState = {
		visible: false,
		part: undefined,
		quantity: 0,
		length: 0.0,
	};

	@observable
	public partsList: PartEntity[];
	public rebarList: PartEntity[];
	
	public componentDidMount(): void {
		PartEntity.fetch<PartEntity>()
			.then(partsList => {
				this.changeRequestState('done', partsList);
			})
			.catch(e => {
				this.changeRequestState('error');
			})
	}

	@action
	private changeRequestState = (state: 'loading' | 'error' | 'done', partsList?: PartEntity[]) => {
		if (partsList) {
			this.partsList = partsList.filter(part => part.description && !part.description.includes("Rebar Length"));
			this.rebarList = partsList.filter(part => part.description && part.description.includes("Rebar Length"));
		}
		this.requestState = state;
	};

	public render() {
		const { elementStructure } = this.props;
		return (
			<>
				<div className="additional-parts-header">
					<div className="header-left">
						<h4>Additional extras</h4>
						<p className="summary-text">Select any additional extras to add to your quote.</p>
					</div>
					<div className="header-right">
						{!this.props.readonly 
							? <Button 
								onClick={this.addAdditionalPart} 
								className={classNames("add-part-btn", this.addingItemState.visible ? "disabled" : "")} 
								display={Display.Solid} 
								colors={Colors.Primary} 
								icon={{ icon: "plus", iconPos: 'icon-left' }}>
								Add extra
							</Button>
							: null }
					</div>
				</div>
				
				{this.addingItemState.visible || (elementStructure.additionalParts && elementStructure.additionalParts.length > 0)
					? <div className="cost-summary-inner-wrap additional-parts">
						<table className="cost-summary">
							<thead>
								<tr>
									<th>Additional extra</th>
									<th>Qty</th>
									<th>Length (mm)</th>
									<th>Rate</th>
									<th>Total (AUD)</th>
									{!this.props.readonly
										? <th className="hidden-placeholder"/>
										: null}
								</tr>
							</thead>
							<tbody>
								{elementStructure.additionalParts
									? elementStructure.additionalParts.map(part => {
										const combinedPrice = ElementStructureUtils.getPartCombinedPrice(part);
										return (
											<tr key={part.partName}>
												<td className="parts-name">{part.partName}</td>
												<td className="parts-qty input-wrap">
													{!this.props.readonly
														? <NumberTextField
															className="parts-qty-input"
															model={part}
															modelProperty="quantity"
															onAfterChange={() => runInAction(() => ElementStructureUtils.cleanInt(part, "quantity"))}
															onChangeAndBlur={this.savePartChanges} />
														: part.length.toLocaleString("en-AU", {style: "decimal"})}
												</td>
												<td className="parts-length input-wrap">
													{part.isBar 
														? (!this.props.readonly 
															? <NumberTextField
																className="parts-length-input"
																model={part}
																modelProperty="length"
																onAfterChange={() => runInAction(() => ElementStructureUtils.cleanInt(part, "quantity"))}
																onChangeAndBlur={this.savePartChanges} />
															: part.length.toLocaleString("en-AU", {style: "decimal"}))
														: "N/A"}
												</td>
												<td className="parts-ave-rate">{combinedPrice.toLocaleString("en-AU", {style: "currency", currency: "AUD"})}</td>
												<td className="parts-total">{(combinedPrice * (part.quantity ? part.quantity : 0)).toLocaleString("en-AU", {style: "currency", currency: "AUD"})}</td>
												{!this.props.readonly
													? <td className="remove-additional-part">
														<Button onClick={() => this.deleteAdditionalPart(part)} className="remove-part-btn" display={Display.Text} icon={{ icon: "bin-delete", iconPos: 'icon-left' }}>Remove</Button>
													</td>
													: null }
											</tr>
										);
									})
									: null}
								{this.addingItemState.visible
									? <tr>
										<td className="parts-name input-wrap">
											<Combobox
												model={this.addingItemState}
												modelProperty="part"
												label=""
												isClearable={true}
												placeholder="No Part Selected"
												getOptionValue={part => part ? part.id : "empty"}
												options={this.buildPartsComboboxList()}
												loading={this.requestState === 'loading'}
											/>
										</td>
										<td className="parts-qty input-wrap">
											<NumberTextField
												className="parts-qty-input"
												model={this.addingItemState}
												modelProperty="quantity"
												onAfterChange={() => runInAction(() => ElementStructureUtils.cleanInt(this.addingItemState, "quantity"))}
											/>
										</td>
										<td className="parts-length input-wrap">{this.addingItemState.part && this.addingItemState.part.name.match(this.partIsBarRegex)
											? <NumberTextField
												className="parts-length-input"
												model={this.addingItemState}
												modelProperty="length"
												onAfterChange={() => runInAction(() => ElementStructureUtils.cleanInt(this.addingItemState, "quantity"))}
											/>
											: "N/A"}
										</td>
										<td colSpan={2} className="submit-additional-part button-wrap">
											<Button onClick={this.submitAdditionalPart} className="submit-part-btn" display={Display.Solid} colors={Colors.Primary} icon={{ icon: "plus", iconPos: 'icon-left' }}>Save Part</Button>
										</td>
										<td className="remove-additional-part">
											<Button onClick={this.cancelAdditionalPart} className="remove-part-btn" display={Display.Text} icon={{ icon: "bin-delete", iconPos: 'icon-left' }}>Cancel</Button>
										</td>
									</tr>
									: null}
							</tbody>
						</table>
					</div>
					: null}
			</>
		);
	};

	private buildPartsComboboxList = () =>
		this.partsList
			? this.partsList.slice().sort((a, b) => {
				if (a.name < b.name) return -1;
				if (a.name === b.name) return 0;
				return 1;
			}).map(part => {
				const partOption: {display: string, value: PartEntity|undefined} = { display: part.name, value: part };
				return partOption;
			})
			: [];
	
	@action private addAdditionalPart = () => {
		this.addingItemState = {
			visible: true,
			part: undefined,
			quantity: 0,
			length: 0.0,
		}
	};

	@action private submitAdditionalPart = () => {
		const { part, length, quantity } = this.addingItemState;
		if(part === undefined) {
			// No selected part, so we can't submit it
			return;
		}
		
		// Calculate part pricing
		let basePrice = part.price;
		let reoPrice = 0.0;
		let isBar = false;

		// If this is a bar, we need to calculate the pricing differently
		const barRegexMatch = part.name.match(this.partIsBarRegex);
		if (barRegexMatch) {
			// Find the price per m for rebar of this type
			const rebarLength = this.rebarList.find(rebar => rebar.name === barRegexMatch[1]);
			
			// If we couldn't find a matching bar length, we'll use 0 as the price per m
			isBar = true;
			basePrice = part.price;
			reoPrice = rebarLength ? rebarLength.price : 0;
		}
		
		// Add the part to our additional parts list
		if(!this.props.elementStructure.additionalParts) {
			this.props.elementStructure.additionalParts = [];
		}
		this.props.elementStructure.additionalParts.push({
			isBar: isBar,
			partName: part.name,
			length: length,
			quantity: quantity,
			basePrice: basePrice,
			reoPrice: reoPrice,
		});
		
		// Hide the add part input again
		this.addingItemState = {
			visible: false,
			part: undefined,
			quantity: 0,
			length: 0,
		};
		
		this.savePartChanges();
	};

	@action private cancelAdditionalPart = () => {
		// Hide the add part input again
		this.addingItemState = {
			visible: false,
			part: undefined,
			quantity: 0,
			length: 0.0,
		}
	};

	@action private deleteAdditionalPart = (part: AdditionalPart) => {
		const { additionalParts } = this.props.elementStructure;
		if(additionalParts) {
			additionalParts.splice( additionalParts.indexOf(part), 1 );

			this.savePartChanges();
		}
	};
	
	private savePartChanges = () => {
		if(this.props.onChangeExtraParts) {
			this.props.onChangeExtraParts();
		}
	};
}